import styled from 'styled-components'
import { rem } from 'polished'
import { color } from 'core/theme'

export const StyledRadio = styled.input.attrs({
  type: 'radio',
})`
  -webkit-appearance: none;

  border: 2px solid ${color.primary.default};
  border-radius: 50%;
  width: ${rem(18)};
  height: ${rem(18)};
  margin: 0.5em;

  position: relative;

  &:checked {
    &::after {
      content: '';
      background-color: ${color.primary.default};
      border-radius: 50%;
      width: ${rem(10)};
      height: ${rem(10)};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`
