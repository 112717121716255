import styled from 'styled-components'
import { breakpoint } from 'core/layout'

export const StyledArticleGrid = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.tablet`
    margin-left: ${(1 / 12) * 100}%;
    margin-right: ${(1 / 12) * 100}%;
  `};

  ${breakpoint.laptop`
    margin-right: ${(4 / 12) * 100}%;
  `};

  @supports (display: grid) {
    display: grid;
    grid-column-gap: 1.5rem;
    grid-template-columns: 1fr;

    margin-left: 0;
    margin-right: 0;

    ${breakpoint.tablet`
      grid-template-columns: 1fr 7fr 1fr;
    `};

    ${breakpoint.laptop`
      grid-template-columns: 1fr 7fr 4fr;
    `};
  }
`
