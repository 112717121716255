import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { toValuesArray, toObjectsArray } from 'utils/tags'

const TAGS_QUERY = gql`
	query TagsQuery($lang: String!, $taxonomy: String!) {
		tags(lang: $lang, taxonomy: $taxonomy) {
			id
			count
			name
			slug
		}
	}
`

const EVENTS_QUERY = gql`
	query EventsQuery(
		$lang: String!
		$date: String!
		$tags: TagsInput
		$limit: Int
	) {
		eventsByDateRange(lang: $lang, date: $date, tags: $tags, limit: $limit) {
			id
			slug
			url
			date
			title
			acf
			... on Post {
				tagIds
			}
		}
	}
`

const EventsQuery = props => {
	const { activeLang, queryParams, date, variables, children } = props

	const filterTags = []

	return (
		<div>
			<Query
				query={TAGS_QUERY}
				variables={{ lang: activeLang, taxonomy: queryParams.taxonomy }}
			>
				{({ loading: tagsLoading, error: tagsError, data: tagsData }) => {
					const { tags } = tagsData
					const filterTagsObjArr = toObjectsArray(filterTags, tags, 'slug')
					const tagsQueryVar = toValuesArray(filterTagsObjArr, 'id')

					if (tagsLoading) {
						return children({
							loading: tagsLoading,
							error: tagsError,
							data: {
								events: [],
							},
						})
					}

					return (
						<Query
							query={EVENTS_QUERY}
							variables={{
								lang: activeLang,
								date,
								tags: {
									taxonomy: queryParams.taxonomy,
									ids: [...tagsQueryVar],
								},
								limit: variables.limit || 50,
							}}
						>
							{({
								loading: eventsLoading,
								error: eventsError,
								data: eventsData,
							}) => {
								const { eventsByDateRange = [] } = eventsData
								const eventsWithTags = eventsByDateRange.map(event => ({
									...event,
									tags: event.tagIds.map(id => tags.find(tag => tag.id === id)),
								}))

								return children({
									loading: tagsLoading && eventsLoading,
									error: tagsError && eventsError,
									data: {
										events: [...eventsWithTags],
									},
								})
							}}
						</Query>
					)
				}}
			</Query>
		</div>
	)
}

export default EventsQuery
