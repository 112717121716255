import styled from 'styled-components'
import { color, plainList, isPrintMedia } from 'core/theme'

export const StyledDrawer = styled.div`
  ${isPrintMedia`
    margin-bottom: 1.5rem;
  `};
`

export const ChipsList = styled.ul`
  ${plainList};

  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`

export const Label = styled.small`
  color: ${color.text.subtle};
  display: block;
  margin-bottom: 1rem;

  ${isPrintMedia`
    color: ${color.printGrey};
    font-size: 1.2em;
  `};
`
