import styled from 'styled-components'

export const Nav = styled.nav`
  font-weight: 600;
`

export const Group = styled.div`
  display: flex;
  align-items: center;
`

export const FontSizeIcon = styled.span`
  font-size: 1.2em;
  transform: translate(1px, 1px);

  & > small {
    font-size: 0.6em;
    margin: 0.2em;
  }
`
