import React from 'react'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import { TranslationsContext } from 'core/translations'

const TRANSLATIONS_QUERY = gql`
	query TranslationsQuery($lang: String!) {
		translations(lang: $lang)
	}
`

class TranslationsProvider extends React.Component {
	static contextType = TranslationsContext

	static propTypes = {
		lang: PropTypes.string,
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			...context,
		}

		this._requestTranslations()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.lang !== this.props.lang) {
			this._requestTranslations()
		}
	}

	_requestTranslations = async () => {
		const { lang, client } = this.props

		const { translations } = this.state
		if (!translations.hasOwnProperty(lang)) {
			const { data } = await client.query({
				query: TRANSLATIONS_QUERY,
				variables: { lang },
			})

			const newState = {
				...this.state,
				activeLang: lang,
				translations: {
					...this.state.translations,
					[lang]: {
						...data.translations,
					},
				},
			}
			this.setState(newState)
		}
	}

	render() {
		return (
			<TranslationsContext.Provider value={this.state}>
				{this.props.children}
			</TranslationsContext.Provider>
		)
	}
}

export default TranslationsProvider
