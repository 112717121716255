import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { breakpoint } from 'core/layout'
import { color, isPrintMedia } from 'core/theme'

const getLevelStyle = ({ level }) => {
  switch (level) {
    case 2:
      return css`
        font-size: ${rem(24)};

        ${breakpoint.tablet`
          font-size: ${rem(28)};
        `};
      `

    case 3:
      return css`
        font-size: ${rem(18)};

        ${breakpoint.tablet`
          font-size: ${rem(20)};
        `};
      `
    default:
      return css`
        font-size: ${rem(28)};

        ${breakpoint.tablet`
          font-size: ${rem(36)};
        `};
      `
  }
}
export const StyledHeadline = styled.h1`
  ${getLevelStyle};

  color: ${color.text.default};
  font-weight: 700;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.2em;

  > span {
    color: ${color.text.subtle};
    font-weight: 600;
    font-size: 0.8em;
  }

  ${isPrintMedia`
    color: ${color.printBlack};
  `};
`
