import React from 'react'
import { Transl } from 'core/translations'
import { VideoIcon, CloseIcon } from 'core/icons'
import { StyledBar, ToggleButton, CloseButton } from './styled'

const ToggleBar = ({ isExpanded, onExpand }) => {
	return (
		<StyledBar isExpanded={isExpanded}>
			<ToggleButton
				secondary
				rotateIcon={isExpanded}
				onClick={() => {
					onExpand && onExpand(!isExpanded)
				}}
			>
				<Transl>Filtrs</Transl>
				<VideoIcon />
			</ToggleButton>

			{isExpanded && (
				<CloseButton
					secondary
					onClick={() => {
						onExpand && onExpand(false)
					}}
				>
					<CloseIcon medium />
				</CloseButton>
			)}
		</StyledBar>
	)
}

export default ToggleBar
