const fileSizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

export const getReadableFileSize = (bytes, decimals = 2) => {
  if (bytes) {
    if (bytes === 0) return ', 0 Bytes'
    const type = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    const size = (bytes / Math.pow(1024, type)).toFixed(decimals)
    const label = fileSizes[type]
    return `${size} ${label}`
  }
  return ''
}

const getFileType = mimeType => {
  const [type, subtype] = mimeType ? mimeType.split('/') : []
  switch (type) {
    case 'text':
    case 'image':
    case 'audio':
    case 'video':
      return type

    case 'application':
      const archivePattern = /^(zip)$/gi
      const [, matchZip] = archivePattern.exec(subtype) || []
      if (matchZip) {
        return 'archive'
      }

      const docPattern = /^(msword|rtf|vnd.openxmlformats-officedocument.wordprocessingml.document)$/gi
      const [, matchDoc] = docPattern.exec(subtype) || []
      if (matchDoc) {
        return 'text'
      }

      const pdfPattern = /^(pdf|x-pdf|x-bzpdf|x-gzpdf)$/gi
      const [, matchPdf] = pdfPattern.exec(subtype) || []
      if (matchPdf) {
        return 'text'
      }

      const excelPattern = /^(xlsx|xlsm|xlsb|xltx|xltm|xls|xlt)$/gi
      const [, matchExcel] = excelPattern.exec(subtype) || []
      if (matchExcel) {
        return 'excel'
      }

      const presentationPattern = /^(pptx|pptm|ppt|potx|potm|pot|ppsx|ppsm|pps|odp)$/gi
      const [, matchPresentation] = presentationPattern.exec(subtype) || []
      if (matchPresentation) {
        return 'presentation'
      }
      break

    default:
      return 'unknown'
  }
}

export const getReadableFileType = mimeType => {
  const type = getFileType(mimeType)
  switch (type) {
    case 'text':
      return 'teksta dokuments'
    case 'image':
      return 'bilde'
    case 'audio':
      return 'audio'
    case 'video':
      return 'video'
    case 'archive':
      return 'arhīvs'
    case 'excel':
      return 'Excel dokuments'
    case 'presentation':
      return 'prezentācija'
    default:
      return 'fails'
  }
}

const extensionPattern = /(\.[^.]+)$/
export const getFileExtension = fileName => {
  const [, extension] = extensionPattern.exec(fileName) || []
  return extension
}
