import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Helmet } from 'react-helmet'
import { matchPath } from 'react-router'
import { NavLink } from 'react-router-dom'
import { CardsGrid, siteWidth, sizes, HiddenBellow } from 'core/layout'
import {
	FacebookIcon,
	TwitterIcon,
	LinkedInIcon,
	AttachmentIcon,
} from 'core/icons'
import { ssrReadyMark } from 'core/context'
import { getFormUrl, mapDefaultPostUrl } from 'utils/router'
import { replaceEntities, stripHTML } from 'utils/entities'
import { toObjectsArray } from 'utils/tags'
import { getHumanDate } from 'utils/date'
import { getMetaTags } from 'utils/seo'
import {
	getReadableFileType,
	getReadableFileSize,
	getFileExtension,
} from 'utils/file'
import { Transl } from 'core/translations'
import { HiddenInPrint } from 'core/theme'
import { ArticleCard, PostsQuery, TagsQuery } from 'modules'
import {
	Tag,
	TagsGroup,
	Headline,
	Metadata,
	Content,
	ExternalLink,
	Button,
	Divider,
	SubsectionHeading,
	Loader,
	Breadcrumbs,
	ErrorMessage,
	OutlinedButton,
	PrintButton,
} from 'elements'
import {
	FallbackFlexLayout,
	FeaturedMedia,
	AsideLists,
	Gallery,
	NewestPostsAside,
} from './components'
import {
	Article,
	Header,
	ShareActions,
	Aside,
	Footer,
	RelatedPostsSection,
	ContentWrapper,
} from './styled'
// import post from './data'

const SINGLE_POST_QUERY = gql`
	query SinglePostQuery(
		$lang: String!
		$base: String!
		$slug: String!
		$tags: TagsInput
	) {
		singlePost(lang: $lang, base: $base, slug: $slug, tags: $tags) {
			id
			url
			title
			date
			excerpt
			content
			featuredMedia {
				altText
				caption
				sizes {
					sizeId
					url
					width
				}
			}
			acf
			... on Post {
				tagIds
				galleries {
					mediaIds
				}
			}
		}
	}
`

const getPostTypeHasAside = postType => {
	switch (postType) {
		case 'inspire':
			return true
		case 'event':
			return true
		default:
			return false
	}
}

const getPostTypeHasRelatedPosts = postType => {
	switch (postType) {
		case 'inspire':
			return true
		case 'posts':
			return true
		default:
			return false
	}
}

const getRelatedTextForPostType = postType => {
	switch (postType) {
		case 'inspire':
			return 'Saistītie iedvesmas stāsti'
		default:
			return 'Saistītās aktualitātes'
	}
}

const getSourceTypeLabel = type => {
	switch (type) {
		case 'source':
			return 'Avots'
		case 'author':
			return 'Autors'
		default:
			return type
	}
}

const openSocialWindow = url => {
	const width = 570
	const height = 570
	const left = (window.screen.width - width) / 2
	const top = (window.screen.height - height) / 2
	const params = `menubar=no,toolbar=no,status=no,width=${width},height=${height},top=${top},left=${left}`
	window.open(url, 'NewWindow', params)
}

const SinglePost = props => {
	const {
		location,
		history,
		activeLang,
		isDefaultLang,
		queryParams,
		pageProps: parentPage,
		meta,
	} = props

	const { params: urlParams } = matchPath(location.pathname, {
		path: '/:lang?/:page/:post',
		exact: true,
		strict: false,
	})

	return (
		<Query
			query={SINGLE_POST_QUERY}
			variables={{
				lang: activeLang,
				base: queryParams.postType,
				slug: urlParams.post,
				tags: {
					taxonomy: queryParams.taxonomy,
					ids: [],
				},
			}}
		>
			{({ loading, error, data }) => {
				if (loading) return <Loader />
				if (error) return <ErrorMessage message={error.message} />

				const { singlePost } = data
				if (!singlePost) {
					return (
						<>
							<Headline level={2}>
								<Transl>Raksts nav atrasts</Transl>
								{'! '}
							</Headline>
							<Button
								secondary
								style={{
									padding: '0.5rem 0.75rem',
									transform: 'translateX(-0.75rem)',
								}}
								onClick={() => {
									history.push({
										pathname: parentPage.url,
									})
								}}
							>
								<Transl>Atgriezties</Transl>
							</Button>
						</>
					)
				}

				const {
					id: postId,
					title: rawTitle,
					url: postUrl,
					date,
					excerpt,
					content,
					galleries,
					featuredMedia,
					/* attachments, */
					tagIds,
					acf,
				} = singlePost
				const title = replaceEntities(rawTitle)

				const hasAside = getPostTypeHasAside(queryParams.postType)
				const { files: attachments, source } = acf

				const contents = content.split(/<!--gallery:\d-->/g)

				const isDefaultPostType =
					queryParams &&
					queryParams.postType &&
					queryParams.postType === 'posts'

				const asidePositionStyle = isDefaultPostType
					? {
							position: 'sticky',
							top: '8rem',
					  }
					: {}

				const supportsGrid = !!(
					window.CSS &&
					window.CSS.supports &&
					CSS.supports('grid-area: actions')
				)

				return (
					<>
						<Helmet
							title={title}
							meta={getMetaTags(meta, singlePost, 'article')}
						/>

						{ssrReadyMark}

						<HiddenInPrint>
							<Breadcrumbs>
								<NavLink exact to={parentPage.url}>
									{parentPage.title}
								</NavLink>
								<NavLink exact to={location.pathname} tabIndex="-1">
									{title}
								</NavLink>
							</Breadcrumbs>
						</HiddenInPrint>

						<Article isDefaultPostType={isDefaultPostType}>
							<Header>
								<TagsQuery lang={activeLang} taxonomy={queryParams.taxonomy}>
									{({ loading, error, data }) => {
										if (loading) return <Loader />
										if (error) {
											console.error(error.message)
											return null
										}
										const { tags } = data
										const tagsObjArr = toObjectsArray(tagIds, tags, 'id')
											/* FIXME: Atkal undefined tagu problēma. Kāds iemesls? Vai citas valodas tagi? */
											.filter(t => t !== undefined)
										return (
											<TagsGroup>
												{tagsObjArr.map(({ name, slug }) => (
													<Tag key={slug} urlParams={urlParams} slug={slug}>
														{name}
													</Tag>
												))}
											</TagsGroup>
										)
									}}
								</TagsQuery>
								<Headline>{title}</Headline>
								<Metadata>
									<time dateTime={date}>{getHumanDate(date, activeLang)}</time>
									{source &&
										source.map((entry, i) => {
											const {
												type,
												type_freehand: typeCustom,
												source_title: label,
												source_url: url,
											} = entry
											return (
												<div key={i}>
													{`${getSourceTypeLabel(typeCustom || type)}: `}
													{url ? (
														<ExternalLink to={url}>{label}</ExternalLink>
													) : (
														label
													)}
												</div>
											)
										})}
								</Metadata>
							</Header>

							<ContentWrapper useGridFallback={!supportsGrid}>
								{contents.map((content, i) => (
									<div key={`content:${i}`}>
										<Content content={content} />
										{galleries && galleries[i] && (
											<Gallery
												data={galleries[i]}
												sizes={`
                          (min-width: ${sizes.tablet}px) ${(10 / 12) * 100}vw,
                          (min-width: ${sizes.laptop}px) ${(7 / 12) * 100}vw,
                          (min-width: ${siteWidth}px) ${(siteWidth / 12) * 7}px,
                          100vw
                        `}
											/>
										)}
									</div>
								))}

								<PrintButton />
							</ContentWrapper>

							<Footer>
								{attachments && (
									<ul>
										{attachments.map(({ name, file }) => {
											if (file) {
												return (
													<li key={file.id}>
														<ExternalLink
															to={file.url}
															download={file.filename}
														>
															<AttachmentIcon />
															{`${name || file.name}, `}
															<Transl>
																{`${getReadableFileType(file.mime_type)}`}
															</Transl>
															{` (${getFileExtension(
																file.filename
															).toUpperCase()}), `}
															{getReadableFileSize(file.filesize)}
														</ExternalLink>
													</li>
												)
											}
											return null
										})}
									</ul>
								)}
							</Footer>

							{/* FIXME: IE11/Edge atšķirības, layout labojums */}
							<FallbackFlexLayout
								style={{
									gridColumnStart: 1,
									gridColumnEnd: 5,
								}}
							>
								<Aside style={asidePositionStyle}>
									{hasAside && (
										<>
											<AsideLists
												postId={postId}
												post={singlePost}
												postType={queryParams.postType}
												lang={activeLang}
											/>
											{queryParams.postType === 'event' && acf.apply_button && (
												<OutlinedButton
													secondary
													onClick={() => {
														history.push({
															pathname: getFormUrl(
																activeLang,
																postUrl,
																'event'
															),
														})
													}}
												>
													<Transl>Pieteikties pasākumam</Transl>
												</OutlinedButton>
											)}
										</>
									)}

									<HiddenInPrint>
										{isDefaultPostType && (
											<HiddenBellow media="laptop">
												<NewestPostsAside
													activeLang={activeLang}
													isDefaultLang={isDefaultLang}
													pageUrl={parentPage.url}
													exclude={postId}
												/>
											</HiddenBellow>
										)}
									</HiddenInPrint>
								</Aside>

								{featuredMedia && featuredMedia.sizes.length > 0 ? (
									<FeaturedMedia featuredMedia={featuredMedia}>
										{featuredMedia.caption && (
											<Metadata as="figcaption">
												<div
													dangerouslySetInnerHTML={{
														__html: featuredMedia.caption,
													}}
												/>
											</Metadata>
										)}
									</FeaturedMedia>
								) : null}

								{/* FIXME: Atšķirīga atkāpe no apakšas figcaption dēļ */}
								<ShareActions>
									<Button
										title="Facebook"
										onClick={() => {
											const url = encodeURIComponent(window.location.href)
											openSocialWindow(
												`https://www.facebook.com/sharer.php?u=${url}`
											)
										}}
									>
										<FacebookIcon medium />
									</Button>
									<Button
										title="Twitter"
										onClick={() => {
											const url = encodeURIComponent(window.location.href)
											const shareText = encodeURIComponent(
												acf.seo_description ||
													stripHTML(excerpt) ||
													meta.description
											)
											openSocialWindow(
												`https://twitter.com/intent/tweet?url=${url}&text=${shareText}`
											)
										}}
									>
										<TwitterIcon medium />
									</Button>
									<Button
										title="LinkedIn"
										onClick={() => {
											const url = encodeURIComponent(window.location.href)
											const shareTitle = encodeURIComponent(
												acf.seo_title || title
											)
											const shareSummary = encodeURIComponent(
												acf.seo_description ||
													stripHTML(excerpt) ||
													meta.description
											)
											openSocialWindow(
												`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${shareTitle}&summary=${shareSummary}&source=${
													meta.name
												}`
											)
										}}
									>
										<LinkedInIcon medium />
									</Button>
								</ShareActions>
							</FallbackFlexLayout>
						</Article>

						{getPostTypeHasRelatedPosts(queryParams.postType) && (
							<HiddenInPrint>
								<Divider />

								<RelatedPostsSection>
									<CardsGrid>
										<div>
											<SubsectionHeading>
												<Transl>
													{getRelatedTextForPostType(queryParams.postType)}
												</Transl>
											</SubsectionHeading>
										</div>
										<span />
										<span />
									</CardsGrid>

									<TagsQuery lang={activeLang} taxonomy={queryParams.taxonomy}>
										{({ loading, error, data }) => {
											if (loading) return <Loader />
											if (error) {
												console.error(error.message)
												return null
											}
											const { tags } = data
											const tagsObjArr = toObjectsArray(tagIds, tags, 'id')
											return (
												<PostsQuery
													activeLang={activeLang}
													queryParams={queryParams}
													filterTags={tagsObjArr
														.filter(tag => !!tag)
														.map(tag => tag.slug)}
													variables={{
														limit: 3,
														exclude: postId,
													}}
												>
													{({ loading, data }) => {
														if (loading) return <Loader />

														const { posts, tags } = data

														const postsWithTags = posts.map(post => ({
															...post,
															tags: post.tagIds.map(id =>
																tags.find(tag => tag.id === id)
															),
															url: mapDefaultPostUrl(
																queryParams && queryParams.postType,
																activeLang,
																isDefaultLang,
																parentPage.url,
																post.url
															),
														}))

														const cards = postsWithTags.map(
															ArticleCard.mapPostToCard
														)
														return (
															<CardsGrid minColWidth={ArticleCard.minWidth}>
																{cards.map(({ slug, ...restProps }) => (
																	<ArticleCard key={slug} {...restProps} />
																))}
															</CardsGrid>
														)
													}}
												</PostsQuery>
											)
										}}
									</TagsQuery>
								</RelatedPostsSection>
							</HiddenInPrint>
						)}
					</>
				)
			}}
		</Query>
	)
}

export default SinglePost
