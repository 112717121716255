export const dayNames = {
  narrow: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
  wide: [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ],
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const controlsTitle = {
  previous: 'Previous month',
  next: 'Next month',
}

export const toMonthTitle = ({ year, month }) => `${monthNames[month]} ${year}`
