import styled, { css } from 'styled-components'
import { rem, em } from 'polished'
import { color, isPrintMedia } from 'core/theme'

export const metadataStyles = css`
  color: ${color.onSurface};
  font-size: ${em(13)};
  line-height: ${20 / 13};
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  a {
    color: inherit;
    padding: 0.5em;
    margin: -0.5em;
    outline-offset: -${rem(4)};

    &:hover {
      color: ${color.text.default};
    }
  }

  p {
    margin: 0;
  }

  p + p {
    margin-top: 1em;
  }

  ${isPrintMedia`
    color: ${color.printGrey};
  `};
`

export const StyledMetadata = styled.small`
  ${metadataStyles};
`
