import React from 'react'
import debounce from 'lodash.debounce'
import { matchPath } from 'react-router'
import { Link } from 'react-router-dom'
import { WindowScrollPosition } from 'core/components'
import { SiteContainer, HiddenBellow } from 'core/layout'
import { Logo, PrimaryNav } from 'elements'
import { ActionItems, SearchContainer } from './components'
import {
	StyledTopAppBar,
	MainContainer,
	BottomContainer,
	BannerContainer,
	BottomBorder,
} from './styled'

class TopAppBar extends React.PureComponent {
	state = {
		isSearchExpanded: false,
	}

	_bannerRef = React.createRef()

	componentDidMount() {
		this._getBannerRect()

		this._handleResize = debounce(this._handleResize, 100)
		window.addEventListener('resize', this._handleResize)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this._handleResize)
	}

	componentDidUpdate(prevProps) {
		const { location: prevLocation } = prevProps
		const { location } = this.props
		if (location.pathname !== prevLocation.pathname) {
			const matchSearchPage = matchPath(location.pathname, {
				path: '/:lang?/meklet',
				exact: true,
				strict: false,
			})

			if (!matchSearchPage || !matchSearchPage.isExact) {
				this.setState(prevState => ({
					...prevState,
					isSearchExpanded: false,
				}))
			}
		}
	}

	_handleResize = event => {
		this._getBannerRect()
	}

	_onToggleFontSizeProxy = (...args) => {
		this._getBannerRect()

		const { onToggleFontSize } = this.props
		onToggleFontSize && onToggleFontSize(args)
	}

	_getBannerRect = () => {
		const banner = this._bannerRef.current
		const appBar = banner.parentNode

		const { height } = banner.getBoundingClientRect()
		const paddingTopStyle = window
			.getComputedStyle(appBar, null)
			.getPropertyValue('padding-top')

		const re = /(\d*\.?\d+)/i
		const [, paddingTop] = re.exec(paddingTopStyle) || []

		this._stickyOffset = parseInt(paddingTop, 10) + height
		appBar.style.top = `-${this._stickyOffset || 0}px`
	}

	_onFocusSearch = () => {
		this.setState(prevState => ({
			...prevState,
			isSearchExpanded: true,
		}))
	}

	_isElevated = ({ y }) => y > this._stickyOffset

	render() {
		const {
			onSwitchLanguage,
			onToggleThemeMode,
			onToggleMenu,
			navItems,
			activeLang,
			isDefaultLang,
			frontPageUrl,
			themeMode,
			location,
			history,
		} = this.props

		const { isSearchExpanded } = this.state
		const banner = require(`./assets/liaa-eraf-banner-${activeLang}.svg`);

		return (
			<WindowScrollPosition throttle={100} test={this._isElevated}>
				{({ test: isElevated }) => (
					<StyledTopAppBar
						isElevated={isElevated}
						isSearchExpanded={isSearchExpanded}
						style={{ top: `-${this._stickyOffset || 0}px` }}
					>
						<BannerContainer ref={this._bannerRef}>
							<img src={banner} alt="" onLoad={this._getBannerRect} />
						</BannerContainer>

						<MainContainer>
							<SiteContainer>
								<Link to={frontPageUrl}>
									<Logo />
								</Link>

								<HiddenBellow media="mainBreak" style={{ flex: 1 }}>
									<PrimaryNav isOnSurface={isElevated} navItems={navItems} />
								</HiddenBellow>
								<ActionItems
									onSwitchLanguage={onSwitchLanguage}
									onToggleThemeMode={onToggleThemeMode}
									onToggleMenu={onToggleMenu}
									onToggleFontSize={this._onToggleFontSizeProxy}
									onFocusSearch={this._onFocusSearch}
								/>
							</SiteContainer>
						</MainContainer>

						{isSearchExpanded && (
							<BottomContainer>
								<BottomBorder />
								<SiteContainer>
									<SearchContainer
										activeLang={activeLang}
										isDefaultLang={isDefaultLang}
										location={location}
										history={history}
										onClose={() => {
											this.setState(prevState => ({
												...prevState,
												isSearchExpanded: false,
											}))
										}}
									/>
								</SiteContainer>
							</BottomContainer>
						)}

						{!isSearchExpanded && <BottomBorder />}
					</StyledTopAppBar>
				)}
			</WindowScrollPosition>
		)
	}
}

export default TopAppBar
