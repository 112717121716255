import React from 'react'
import { Transl } from 'core/translations'
import { VideoIcon } from 'core/icons'
import { Button } from 'elements'

const ToggleArrowButton = ({
	label,
	isActive,
	onClick,
	styleType = 'secondary',
}) => {
	const rotationStyle = {
		transform: `rotate(${isActive ? -90 : 90}deg)`,
	}

	const styleTypeProps = {}
	if (styleType) {
		styleTypeProps[styleType] = true
	}

	return label ? (
		<Transl id={label}>
			{translation => (
				<Button
					{...styleTypeProps}
					onClick={onClick}
					aria-label={translation}
					title={translation}
					style={rotationStyle}
				>
					<VideoIcon />
				</Button>
			)}
		</Transl>
	) : (
		<Transl id='Izvērst'>
			{translation => (
				<Button
					{...styleTypeProps}
					onClick={onClick}
					aria-label={translation}
					style={rotationStyle}
				>
					<VideoIcon />
				</Button>
			)}
		</Transl>
	)
}

export default ToggleArrowButton
