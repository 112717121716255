import styled from 'styled-components'
import { rem } from 'polished'
import { color, plainList } from 'core/theme'
import { breakpoint } from 'core/layout'

export const StyledResultsList = styled.div`
  background-color: ${color.surface};
  border-radius: 0 0 ${rem(2)} ${rem(2)};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);

  position: absolute;
  bottom: 0;
  left: 1.5rem;
  width: calc(100% - 3rem);
  transform: translateY(100%);
  z-index: 100;

  > ul {
    ${plainList};

    padding: 0.5rem 1rem;

    ${breakpoint.tablet`
      padding: 1rem 2rem;
    `};
  }

  a {
    display: block;
    padding: 0.5em;
    text-decoration: none;

    &:hover h6 {
      text-decoration: underline;
    }
  }

  h6 {
    color: ${color.text.variant.labelAlt};
    font-size: 1rem;
    margin: 0;
  }

  small {
    color: ${color.text.subtle};
    font-size: ${rem(13)};
  }
`

export const BottomGroup = styled.div`
  border-top: 1px solid ${color.border};
  display: flex;
  justify-content: flex-end;

  > button {
    display: flex;
    align-items: center;
    outline-offset: -${rem(4)};
    padding: 1em;

    > span {
      margin-left: 0.25rem;
    }

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
`

export const NoResults = styled.div`
  padding: 1.5rem 1rem;
`
