import React from 'react'
import { TranslationsContext } from 'core/translations'

const Transl = ({ id, children }) => {
	const str = children && typeof children === 'string' ? children : id
	return (
		<TranslationsContext.Consumer>
			{({ activeLang, translations }) => {
				if (translations && translations.hasOwnProperty(activeLang)) {
					const activeTranslations = translations[activeLang]
					const stringsObj = activeTranslations[str]
					if (stringsObj) {
						return typeof children === 'function'
							? children(stringsObj)
							: stringsObj
					} else {
						console.warn(`Translation for '${str}' does not exist.`)
					}
				}
				return typeof children === 'function' ? children(str) : str
			}}
		</TranslationsContext.Consumer>
	)
}

export default Transl
