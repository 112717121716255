import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { color } from 'core/theme'
import { breakpoint } from 'core/layout'

export const StyledHeading = styled.h2`
  color: ${color.onBackground};
  font-size: ${rem(32)};
  font-weight: 800;
  margin-top: 0.2em;
  margin-bottom: 0.35em;

  display: flex;
  align-items: center;

  position: relative;
  z-index: 1;

  ${({ reverse }) =>
    reverse &&
    css`
      flex-direction: row-reverse;
      text-align: right;
    `};

  &::after {
    content: '';
    background-color: currentColor;
    width: ${rem(38)};
    height: ${rem(2)};

    ${({ reverse }) =>
      reverse
        ? css`
            margin-right: 0.25em;
          `
        : css`
            margin-left: 0.25em;
          `};
  }

  ${breakpoint.tablet`
    font-size: ${rem(55)};

    &::after {
      content: '';
      background-color: currentColor;
      width: ${rem(76)};
      height: ${rem(3)};

      ${({ reverse }) =>
        reverse
          ? css`
              margin-right: 0.35em;
            `
          : css`
              margin-left: 0.35em;
            `};
    }
  `};
`
