Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.default = buildFormatLongFn
function buildFormatLongFn(args) {
  return function(dirtyOptions) {
    var options = dirtyOptions || {}
    var width = options.width ? String(options.width) : args.defaultWidth
    var format = args.formats[width] || args.formats[args.defaultWidth]
    return format
  }
}
module.exports = exports['default']
