import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'elements'
import { VideoIcon } from 'core/icons'
import { Transl } from 'core/translations'
import { replaceEntities } from 'utils/entities'
import { mapDefaultPostUrl } from 'utils/router'
import { mapPostTypeName } from 'utils/types'
import { StyledResultsList, BottomGroup, NoResults } from './styled'

const ResultsList = ({ activeLang, isDefaultLang, results, onMore }) => {
	const showResults = results.slice(0, 4) /* max 4 rezultāti */

	const postsPageUrl = activeLang === 'lv' ? '/aktuali' : '/en/news'

	return (
		<StyledResultsList>
			{showResults.length ? (
				<>
					<ul>
						{showResults.map(({ id, type, title, url }) => (
							<li key={id}>
								<Link
									to={mapDefaultPostUrl(
										type,
										activeLang,
										isDefaultLang,
										postsPageUrl,
										url
									)}
								>
									<h6>{replaceEntities(title)}</h6>
									<small>
										<Transl>{mapPostTypeName(type)}</Transl>
									</small>
								</Link>
							</li>
						))}
					</ul>
					{results.length > showResults.length && (
						<BottomGroup>
							<Button primary onClick={onMore}>
								<Transl>Skatīt visus rezultātus</Transl>
								<VideoIcon />
							</Button>
						</BottomGroup>
					)}
				</>
			) : (
				<>
					<NoResults>
						<Transl>Nekas netika atrasts</Transl>
					</NoResults>
				</>
			)}
		</StyledResultsList>
	)
}

export default ResultsList
