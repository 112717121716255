import React from 'react'
import { siteWidth } from 'core/layout'
import { ResponsiveImg } from 'elements'
import { toImageSrc } from 'utils/media'
import { StyledBanner } from './styled'

const MediaBanner = ({ title, featuredMedia }) => {
	const imageSrc = featuredMedia && toImageSrc(featuredMedia, 'large')
	return (
		<StyledBanner>
			{imageSrc && (
				<ResponsiveImg
					alt={featuredMedia.altText}
					sizes={`(min-width: ${siteWidth}px) ${siteWidth}px, 100vw`}
					{...imageSrc}
				/>
			)}
			<h1>{title}</h1>
		</StyledBanner>
	)
}

export default MediaBanner
