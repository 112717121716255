export const mapPostTypeName = type => {
  switch (type) {
    case 'post':
      return 'Aktuāli'
    case 'page':
      return 'Lapas'
    case 'event':
      return 'Pasākumi'
    case 'inspire':
      return 'Iedvesmas stāsti'
    case 'person':
      return 'Cilvēki'
    case 'resource':
      return 'Resursi'
    case 'support':
      return 'Atbalsts'
    default:
      return ''
  }
}
