import styled, { css } from 'styled-components'
import { rem, em } from 'polished'
import { Button } from 'elements'
import { color } from 'core/theme'

export const StyledBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;

  margin-bottom: 1rem;
  ${({ isExpanded }) =>
    isExpanded &&
    css`
      margin-bottom: 0.5rem;
    `};
`

export const ToggleButton = styled(Button)`
  display: flex;
  align-items: center;

  border-radius: ${rem(4)};
  color: ${color.text.default};
  font-size: ${em(18)};

  > span {
    color: ${color.secondary.default};
    margin: 0.5em;
    transform: rotate(90deg);

    ${({ rotateIcon }) =>
      rotateIcon &&
      css`
        transform: rotate(-90deg);
      `};
  }
`

export const CloseButton = styled(Button)`
  border-radius: 50%;
  padding: 0.75em;
`
