// NOTE: @font-face iekļauti atsevišķā fonts.css failā, nevis kā daļa no
// createGlobalStyle, jo pēc state izmaiņām fonts tiek pieprasīts atkārtoti.
// Open issue: https://github.com/styled-components/styled-components/issues/1593
import 'core/theme/assets/fonts.css'

import { createGlobalStyle, css } from 'styled-components'
import { themeMode, fontSize, color } from 'core/theme'
import printStyle from './printStyle'

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  *:focus {
    outline-color: ${color.outline};
    outline-offset: 2px;
    outline-style: solid;
    outline-width: 2px;
  }

  /*
    Hide the focus indicator if the element receives focus via
    the mouse, but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  ::selection {
    background-color: ${color.primary.variant.light};
    color: ${color.text.default};
  }

  ::-moz-focus-inner {
    border: none;
  }

  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: ${color.background};
  }

  body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-optical-sizing: auto;
    overflow-x: hidden;
  }

  @media print {
    ${printStyle};
  }

  ${({ mode }) =>
    mode === themeMode.contrast &&
    css`
      ::selection {
        color: ${color.white};
      }
    `};

  ${({ size }) =>
    size === fontSize.large &&
    css`
      html {
        font-size: 125%;

        /* Šo nevaram pārvietot uz printStyle.js,
        jo tas nav dinamisks */
        @media print {
          font-size: 14pt;
        }
      }
    `};
`
