const MATERIAL_STANDARD = 'cubic-bezier(0.4, 0.0, 0.2, 1)'
const MATERIAL_ACCELERATE = 'cubic-bezier(0.4, 0.0, 1, 1)'
const MATERIAL_DECELERATE = 'cubic-bezier(0.0, 0.0, 0.2, 1)'

export const easing = {
  inOut: MATERIAL_STANDARD,
  out: MATERIAL_ACCELERATE,
  in: MATERIAL_DECELERATE,
}

export const duration = {}
