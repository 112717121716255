Object.defineProperty(exports, '__esModule', {
  value: true,
})

var formatDistance = require('./_lib/formatDistance')
var formatLong = require('./_lib/formatLong')
var formatRelative = require('./_lib/formatRelative')
var localize = require('./_lib/localize')
var match = require('./_lib/match')

var locale = {
  formatDistance,
  formatLong,
  formatRelative,
  localize,
  match,
  options: {
    weekStartsOn: 1 /* Monday */,
    firstWeekContainsDate: 1,
  },
}

exports.default = locale
module.exports = exports['default']
