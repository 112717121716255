import styled, { css } from 'styled-components'
import { em, rem } from 'polished'
import { ghostButton, color, easing } from 'core/theme'
import { breakpoint } from 'core/layout'

const baseStyle = css`
  border: ${rem(2)} solid ${color.secondary.default};
  border-radius: 1.5rem;
  color: ${color.secondary.default};
  font-size: ${em(13)};
  font-weight: 600;
  margin: 1em 0.5em;
  padding: ${em(12, 13)} ${em(36, 13)} ${em(11, 13)};
  text-transform: uppercase;

  will-change: background-color, color;
  transition-property: background-color, color;
  transition-duration: 100ms;
  transition-timing-function: ${easing.out};

  &:hover {
    background-color: ${color.secondary.default};
    color: ${color.white};
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.25;
    `};

  ${breakpoint.mobile`
    margin: 2.5em 1em;
  `};

  ${({ appearance }) =>
    appearance &&
    appearance === 'white' &&
    css`
      border: ${rem(2)} solid ${color.whiteOnly};
      color: ${color.whiteOnly};

      &:hover {
        background-color: ${color.white};
        color: ${color.secondary.default};
      }
    `};
`

export const StyledButton = styled.input.attrs({
  type: 'submit',
})`
  ${ghostButton};
  ${baseStyle};
`
