import React from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Breadcrumbs, Headline, Button } from 'elements'
import { MailigenForm } from 'modules'
import { ArticleGrid } from 'core/layout'
import { Transl } from 'core/translations'

const PAGE_QUERY = gql`
	query PageQuery($id: Int!) {
		singlePage(id: $id) {
			content
			acf
		}
	}
`

const ContactForm = ({ parentPage, location, history }) => {
	const buttonStyle = {
		padding: '0.5rem 0.75rem',
		transform: 'translateX(-0.75rem)',
	}

	const returnButton = (
		<Button
			secondary
			style={buttonStyle}
			onClick={() => {
				history.push({
					pathname: parentPage.url,
				})
			}}
		>
			<Transl>Atgriezties</Transl>
		</Button>
	)

	const personForm = location.state && location.state.form
	// TODO: Saistītās personas informācijas atspoguļošana
	// const relatedPerson = location.state && location.state.person
	// console.log(relatedPerson)

	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>

			<Breadcrumbs>
				<NavLink exact to={parentPage.url}>
					{parentPage.title}
				</NavLink>
				<NavLink exact to={location.pathname} tabIndex="-1">
					<Transl>Saziņas forma</Transl>
				</NavLink>
			</Breadcrumbs>

			<ArticleGrid>
				<div />
				<div>
					<Query
						query={PAGE_QUERY}
						variables={{ id: parentPage.id, skip: !!personForm }}
					>
						{({ error, loading, data }) => {
							if (error) throw new Error(error.message)
							if (loading) return null

							if (personForm) {
								return (
									<MailigenForm
										type="contact"
										id={personForm}
										returnUrl={parentPage.url}
									/>
								)
							}

							// Ja skip, tad data tukšs
							if (data) {
								const { singlePage } = data
								const {
									acf: { contact_info },
								} = singlePage

								if (contact_info && contact_info.mailigen_form) {
									return (
										<MailigenForm
											type="contact"
											id={contact_info.mailigen_form}
											returnUrl={parentPage.url}
										/>
									)
								}
							}

							return (
								<>
									<Headline level={2}>
										<Transl>Forma nav pieejama</Transl>
										{'. '}
									</Headline>
									{returnButton}
								</>
							)
						}}
					</Query>
				</div>
				<div />
			</ArticleGrid>
		</>
	)
}

export default ContactForm
