import styled, { css } from 'styled-components'
import { em, transparentize, darken } from 'polished'
import { color } from 'core/theme'

export const Container = styled.div`
  border: 1px solid ${color.border};
  padding: 1.25em 2em 1.5em;
  user-select: none;
`

export const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.5em;
`

export const Title = styled.p`
  color: ${color.text.default};
  font-size: ${em(20)};
  font-weight: 600;
  line-height: ${24 / 20};
  margin: 0;
`

export const Controls = styled.div`
  display: flex;

  > * {
    margin-left: 0.5em;
  }
`

export const Button = styled.button.attrs({
  type: 'button',
})`
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  font: inherit;
  padding: 0;
  width: 2.5em;
  height: 2.5em;

  display: flex;

  > svg {
    display: block;
    width: 2em;
    height: 2em;
    margin: auto;
    pointer-events: none;

    > path:not([fill='none']) {
      fill: ${color.secondary.default};
    }
  }

  &:hover {
    background-color: ${props =>
      transparentize(0.92, color.secondary.default(props))};
  }
`

const gridStyles = css`
  /* IE11 fallback */
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;

    /* IE11 fallback */
    width: ${(1 / 7) * 100}%;
  }

  @supports (grid-template-columns: repeat(7, 1fr)) {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    > * {
      width: auto;
    }
  }
`

export const WeekDays = styled.div`
  ${gridStyles};

  color: ${color.text.default};
  font-size: ${em(16)};
  font-weight: 600;
  line-height: ${20 / 16};

  > abbr {
    text-decoration: none;
  }
`

export const Grid = styled.div`
  ${gridStyles};

  color: ${color.text.default};
  font-size: ${em(16)};
  font-weight: 400;
  line-height: ${20 / 16};
`

export const Cell = styled.span`
  padding: 0.75em 0.5em;

  &:hover {
    background-color: ${props =>
      transparentize(0.9, color.primary.default(props))};
  }

  ${({ isToday }) =>
    isToday &&
    css`
      font-weight: 700;
    `};

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${color.primary.default};
      color: ${color.white};

      &:hover {
        background-color: ${props => darken(0.1, color.primary.default(props))};
      }
    `};

  ${({ hasEvent, isSelected }) =>
    hasEvent &&
    css`
      position: relative;

      &::after {
        content: '';
        width: ${em(6)};
        height: ${em(6)};
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        background-color: ${color.secondary.default};
      }

      ${isSelected &&
        css`
          &::after {
            background-color: ${color.white};
          }
        `};
    `};

  ${({ notSameMonth }) =>
    notSameMonth &&
    css`
      opacity: 0.35;
    `};
`
