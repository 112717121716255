import React from 'react'
import { replaceEntities } from 'utils/entities'
import { StyledFilterChip } from './styled'

class FilterChip extends React.Component {
	render() {
		const { tag, isActive, onClick } = this.props
		return (
			<StyledFilterChip
				isActive={isActive}
				onClick={() => {
					onClick && onClick(tag, isActive)
				}}
			>
				{replaceEntities(tag.name)}
			</StyledFilterChip>
		)
	}
}

export default FilterChip
