import styled from 'styled-components'
import { rem, em } from 'polished'
import { color, isPrintMedia } from 'core/theme'
import { breakpoint } from 'core/layout'
import { metadataStyles } from 'elements/Metadata'

export const StyledContent = styled.div`
  color: ${color.text.default};
  font-size: 1rem;
  line-height: ${28 / 18};

  ${breakpoint.tablet`
    font-size: ${rem(18)};
  `};

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  a {
    color: ${color.link};
    margin: -0.5em;
    padding: 0.5em;
    text-decoration: none;

    &:hover {
      color: ${color.link};
      text-decoration: underline;
    }

    i {
      display: inline-block;
      font-style: normal;
      margin-left: 0.2rem;
      pointer-events: none;
      width: ${rem(14)};
      height: ${rem(14)};
      transform: translateY(-0.2rem);

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  /* TODO: Tipogrāfijas izmēru refaktēšana */
  h2,
  h3,
  h4 {
    margin-top: 1.5em;
    margin-bottom: 0.9em;
  }

  h2,
  h3,
  h4,
  strong {
    font-weight: 600;
  }

  h2 {
    font-size: ${em(28, 26)};
    line-height: ${24 / 20};
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;

    &.align {
      &left {
       float: left;
        margin: 0.5em 1em 0.5em 0;
      }

      &right {
        float: right;
        margin: 0.5em 0 0.5em 1em;
      }

      &center {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  ol,
  ul {
    padding-left: 1em;
  }

  blockquote {
    font-weight: 600;
    margin: 1em 0 1em 5rem;
    padding: 0.5rem 0;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -2rem;
      width: 0.5rem;
      height: 100%;
      background: ${color.gradient.orange};
    }

    p {
      margin: 0;
    }

    p + p {
      margin-top: 2rem;
    }
  }

  iframe {
    width: 100%;
    min-height: 396px; /* 704px (max-width); 16:9 */
  }

  iframe[style*="position"] {
    min-height: initial;
  }

  /* wp inline style */
  p > span {
    color: inherit !important;
  }

  .wp-caption {
    font-size: 1rem;
    width: 100% !important;
  }

  .wp-caption-text {
    ${metadataStyles};
  }

  .intro > p:first-child {
    margin-top: 0;
  }

  ${isPrintMedia`
    color: ${color.printBlack};

    a {
      color: ${color.printPrimary};
    }

    blockquote {
      border-left: ${rem(4)} solid ${color.printGrey};
      margin-left: 2rem;
      padding-left: 1rem;

      &::before {
        display: none;
      }
    }

    img {
      max-width: 480pt;
    }
  `};
`
