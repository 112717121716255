Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.default = formatRelative
var formatRelativeLocale = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: 'P',
}

function formatRelative(token, date, baseDate, options) {
  return formatRelativeLocale[token]
}
module.exports = exports['default']
