import React, { Component } from 'react'
import { Transl } from 'core/translations'
import { CloseIcon } from 'core/icons'
import {
	Drawer,
	PrimaryNav,
	ToggleButton,
	LanguageSwitch,
	AccessibilityMenus,
} from 'elements'
import { Nav, Group, TopRightPosition } from './styled'

class MobileNavDrawer extends Component {
	state = {
		isOpen: false,
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.setState(prevState => ({
				...prevState,
				isOpen: false,
			}))
		}
	}

	_onClose = () => {
		this.setState(prevState => ({
			...prevState,
			isOpen: false,
		}))
	}

	render() {
		const {
			onSwitchLanguage,
			onToggleFontSize,
			onToggleThemeMode,
			navItems,
		} = this.props
		const { isOpen } = this.state
		return (
			<Drawer isOpen={isOpen}>
				<PrimaryNav asColumn navItems={navItems} inDrawer />

				<Nav aria-labelledby="action-items-mobile">
					{/* <VisuallyHidden>
						<h2 id="action-items-mobile">
							<Transl>Darbības elementi</Transl>
						</h2>
					</VisuallyHidden> */}

					<Group>
						<LanguageSwitch onSwitchLanguage={onSwitchLanguage} />
						<AccessibilityMenus
							onToggleFontSize={onToggleFontSize}
							onToggleThemeMode={onToggleThemeMode}
						/>
					</Group>
				</Nav>

				<TopRightPosition>
					<Transl id="Aizvērt">
						{translation => (
							<ToggleButton title={translation} onClick={this._onClose}>
								<CloseIcon medium />
							</ToggleButton>
						)}
					</Transl>
				</TopRightPosition>
			</Drawer>
		)
	}
}

export default MobileNavDrawer
