import React from 'react'
import { StyledTagsGroup } from './styled'

const TagsGroup = ({ children }) => (
	<StyledTagsGroup>
		{React.Children.map(children, child => (
			<li key={child.key}>{child}</li>
		))}
	</StyledTagsGroup>
)

export default TagsGroup
