import React, {useContext} from 'react'
import ReactDOM from 'react-dom'
import { StyledContent } from './styled'
import { ReactComponent as IconExternal } from './assets/baseline-open_in_new-24px.svg'
import CookieContext from "../../core/context/CookieContext";

const replaceExternalLinks = contentEl => {
	const anchors = contentEl.querySelectorAll('a')
	Array.from(anchors).forEach(a => {
		const newAnchor = a.cloneNode(true)
		const href = a.getAttribute('href')
		if (href.startsWith('http')) {
			newAnchor.setAttribute('target', '_blank')
			newAnchor.setAttribute('rel', 'noopener')

			const icon = document.createElement('i')
			ReactDOM.render(<IconExternal />, icon)
			newAnchor.appendChild(icon)
		}
		setTimeout(function() {
			a.replaceWith(newAnchor)
		});
	})
}

const replaceYoutubeLinks = contentEl => {
	const youtubeIframes = contentEl.querySelectorAll('iframe[src*="youtube.com"]')
	Array.from(youtubeIframes).forEach(iframe => {
		const newIframe = iframe.cloneNode(true)
		const link = new URL(iframe.getAttribute('src'))
		link.hostname = 'www.youtube-nocookie.com'
		newIframe.setAttribute('src', link)
		iframe.replaceWith(newIframe)
	})
}

const Content = ({ content }) => {
	const {cookieValue} = useContext(CookieContext)

	const processContentReplacement = contentEl => {
		if (contentEl) {
			replaceExternalLinks(contentEl)
			if (cookieValue === null ||
				(Array.isArray(cookieValue) && !cookieValue.includes("analytical")) ||
				(Array.isArray(cookieValue) && !cookieValue.includes("marketing"))) {
				replaceYoutubeLinks(contentEl)
			}
		}
	}

	return (
		<StyledContent
			dangerouslySetInnerHTML={{ __html: content }}
			ref={processContentReplacement}
		/>
	)
}

export default Content
