import React from 'react'
import { VisuallyHidden } from 'core/components'
import { Transl } from 'core/translations'
import { Nav, List, Item, NavLink, Label } from './styled'

const PrimaryNav = ({ isOnSurface, asColumn, navItems, inDrawer }) => {
	const id = `primary-navigation${inDrawer ? '-mobile' : ''}`
	return (
		<Nav aria-labelledby={id}>
			<VisuallyHidden>
				<p id={id}>
					<Transl>Galvenā izvēlne</Transl>
				</p>
			</VisuallyHidden>
			<List asColumn={asColumn}>
				{navItems &&
					navItems.map(({ title, url }, i) => (
						<Item key={i}>
							<NavLink exact={url === '/'} to={url}>
								<Label isOnSurface={isOnSurface}>{title}</Label>
							</NavLink>
						</Item>
					))}
			</List>
		</Nav>
	)
}

export default PrimaryNav
