import styled, { css } from 'styled-components'
import { siteWidth, breakpoint } from 'core/layout'
import { color, easing, isPrintMedia } from 'core/theme'

export const BottomBorder = styled.div`
  border-bottom: 1px solid ${color.border};
  max-width: ${siteWidth}px;
  margin: 0 auto;

  will-change: opacity;
  transition: opacity 400ms ${easing.inOut};
`

export const StyledTopAppBar = styled.div`
  margin-bottom: 1rem;
  padding: 0 1rem;

  ${breakpoint.mainBreak`
    margin-bottom: 1.5rem;
    padding: 1rem 2rem 0;
  `};

  @supports (position: sticky) {
    position: sticky;
    top: 0;
    z-index: 100;

    background-color: ${color.background};
    box-shadow: 0 0 0 0 rgba(60, 64, 67, 0), 0 0 0 0 rgba(60, 64, 67, 0);
    will-change: background-color, box-shadow;
    transition-property: background-color, box-shadow;
    transition-duration: 400ms;
    transition-timing-function: ${easing.inOut};

    ${({ isElevated, isSearchExpanded }) =>
      isElevated &&
      css`
        background-color: ${color.surface};
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
          0 1px 3px 1px rgba(60, 64, 67, 0.15);

        ${!isSearchExpanded &&
          css`
            ${BottomBorder} {
              opacity: 0;
            }
          `}
      `};
  }

  ${isPrintMedia`
    display: none;
  `};
`

export const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 33px;

  & > img {
    max-width: 200px;
  }

  ${breakpoint.mobile`
    & > img {
      max-width: 250px;
    }
  `};

  ${breakpoint.mainBreak`
    & > img {
      max-width: 400px;
    }
  `};
`

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  /* core/layout/SiteContainer */
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    margin: 0; /* IE11 fix */
    padding: 0;

    /* elements/Logo */
    > div:first-child {
      flex: 0 0 auto;
    }

    /* Safari fix */
    > a {
      text-decoration: none;
    }
  }
`

export const BottomContainer = styled.div`
  /* core/layout/SiteContainer */
  > div {
    padding: 0;
  }
`
