import styled, { css } from 'styled-components'
import { rem, em, transparentize } from 'polished'
import { color, easing, ghostButton, isPrintMedia } from 'core/theme'

export const StyledFilterChip = styled.button.attrs({
  type: 'button',
})`
  ${ghostButton};

  border: ${rem(1)} solid ${color.border};
  border-radius: 1.5rem;
  color: ${color.text.variant.label};
  font-size: ${em(12)};
  margin: ${em(4, 12)};
  padding: ${em(10, 12)} ${em(16, 12)};
  text-transform: uppercase;

  will-change: background-color, color;
  transition-property: background-color, color;
  transition-duration: 100ms;
  transition-timing-function: ${easing.out};

  &:hover {
    background-color: ${props =>
      transparentize(0.8, color.secondary.default(props))};
    border-color: ${props =>
      transparentize(0.8, color.secondary.default(props))};
    color: ${color.secondary.default};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${color.secondary.default};
      border: ${rem(1)} solid ${color.secondary.default};
      color: ${color.white};

      &:hover {
        background-color: ${color.whiteOnly};
        border-color: ${color.border};
        color: ${color.blackOnly};
      }
    `};

  ${isPrintMedia`
    background-color: transparent;
    border: ${rem(1)} solid ${color.printGrey};
    color: ${color.printGrey};
  `};
`
