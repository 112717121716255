import React from 'react'
import { StyledLink, StyledButton } from './styled'

const OutlinedButton = ({
	link,
	children,
	onClick,
	appearance,
	isDisabled = false,
}) => {
	if (link) {
		return (
			<StyledLink to={link} appearance={appearance}>
				{children}
			</StyledLink>
		)
	}
	return (
		<StyledButton
			onClick={onClick}
			appearance={appearance}
			isDisabled={isDisabled}
		>
			{children}
		</StyledButton>
	)
}

export default OutlinedButton
