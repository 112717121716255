import { css } from 'styled-components'
import { siteWidth } from './measurements'

// Material Design Breakpoints:
// https://material.io/design/layout/responsive-layout-grid.html#breakpoints

export const sizes = {
  site: siteWidth,
  laptop: 1024,
  mainBreak: 900,
  tablet: 768,
  mobile: 425,
}

const breakpoint = Object.keys(sizes).reduce((map, key) => {
  map[key] = (...args) => css`
    @media (min-width: ${sizes[key] / 16}em) {
      ${css(...args)}
    }
  `
  return map
}, {})

export default breakpoint
