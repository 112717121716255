import styled, { css } from 'styled-components'
import { isPrintMedia } from 'core/theme'
import { siteWidth, breakpoint } from 'core/layout'

export const SiteContainer = styled.div`
  box-sizing: content-box;
  max-width: ${siteWidth}px;
  margin: 0 auto;
  padding: 0 1rem;

  ${breakpoint.tablet`
    padding: 0 2rem;
  `};

  ${isPrintMedia`
    max-width: none;
    padding: 0;
  `};
`

export const ExpandContainer = styled.div`
  margin-left: -1rem;
  margin-right: -1rem;

  ${breakpoint.tablet`
    margin-left: -2rem;
    margin-right: -2rem;
  `};
`

const center = ({ column }) =>
  column
    ? css`
        align-items: center;
      `
    : css`
        justify-content: center;
      `

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ column }) => column && 'column'};
  ${props => props.center && center};
`

export const HiddenBellow = styled.div`
  display: none;

  ${({ media }) => breakpoint[media]`
    display: block;
  `};
`

export const HiddenAbove = styled.div`
  ${({ media }) => breakpoint[media]`
    display: none;
  `};
`
