import React from 'react'
import Hammer from 'hammerjs'
import { toImageSrc } from 'utils/media'
import { NextIcon } from 'core/icons'
import { AspectRatio } from 'core/components'
import { ResponsiveImg, Metadata } from 'elements'
import {
	StyledSwiper,
	NavWrapper,
	NavButton,
	Slide,
	NavIndicator,
	SlideDot,
} from './styled'

class Swiper extends React.Component {
	_slideWrapperRef = React.createRef()

	constructor(props) {
		super(props)
		const { slides } = props
		this.state = {
			currentIndex: 0,
			hasPrev: false,
			hasNext: slides && slides.length > 1,
		}
	}

	componentDidMount() {
		this._hammerManager = new Hammer.Manager(this._slideWrapperRef.current)

		const Swipe = new Hammer.Swipe({
			direction: Hammer.DIRECTION_HORIZONTAL,
		})
		this._hammerManager.add(Swipe)
		this._hammerManager.on('swipe', this._onSwipe)
	}

	componentWillUnmount() {
		if (this._hammerManager) {
			this._hammerManager.destroy()
			this._hammerManager = null
		}
	}

	_onSwipe = event => {
		const { hasNext, hasPrev } = this.state
		if (event.direction === Hammer.DIRECTION_LEFT) {
			if (hasNext) {
				this._onNextSlide()
			}
		} else if (event.direction === Hammer.DIRECTION_RIGHT) {
			if (hasPrev) {
				this._onPrevSlide()
			}
		}
	}

	_onPrevSlide = event => {
		const { slides, onIndexChange } = this.props

		this.setState(
			prevState => {
				const targetIndex = prevState.currentIndex - 1
				return {
					...prevState,
					currentIndex: targetIndex,
					hasPrev: targetIndex > 0,
					hasNext: targetIndex < slides.length - 1,
				}
			},
			() => {
				onIndexChange && onIndexChange(this.state.currentIndex)
			}
		)
	}

	_onNextSlide = event => {
		const { slides, onIndexChange } = this.props

		this.setState(
			prevState => {
				const targetIndex = prevState.currentIndex + 1
				return {
					...prevState,
					currentIndex: targetIndex,
					hasPrev: targetIndex > 0,
					hasNext: targetIndex < slides.length - 1,
				}
			},
			() => {
				onIndexChange && onIndexChange(this.state.currentIndex)
			}
		)
	}

	render() {
		const { slides, sizes } = this.props
		const { currentIndex, hasPrev, hasNext } = this.state
		const currentSlide = slides[currentIndex]
		const hasMultipleSlides = slides.length > 1

		const prevButton = hasMultipleSlides ? (
			<NavButton
				primary
				onClick={this._onPrevSlide}
				reverseIcon
				disabled={!hasPrev}
			>
				<NextIcon />
			</NavButton>
		) : null
		const nextButton = hasMultipleSlides ? (
			<NavButton primary onClick={this._onNextSlide} disabled={!hasNext}>
				<NextIcon />
			</NavButton>
		) : null

		const imageSrc = toImageSrc(currentSlide, 'large')

		const { altText, caption } = currentSlide

		return (
			<StyledSwiper>
				<div ref={this._slideWrapperRef}>
					<Slide>
						<AspectRatio>
							<ResponsiveImg
								sizes={sizes || '100vw'}
								{...imageSrc}
								alt={altText}
							/>
						</AspectRatio>

						{caption && (
							<Metadata as="figcaption">
								<div
									dangerouslySetInnerHTML={{
										__html: caption,
									}}
								/>
							</Metadata>
						)}
					</Slide>
				</div>

				{hasMultipleSlides && (
					<NavWrapper>
						{prevButton}
						<NavIndicator>
							{slides.map((slide, i) => (
								<li key={i}>
									<SlideDot isActive={i === currentIndex} />
								</li>
							))}
						</NavIndicator>
						{nextButton}
					</NavWrapper>
				)}
			</StyledSwiper>
		)
	}
}

export default Swiper
