import React from 'react'
import { StyledDrawer, Scrim, Container } from './styled'

const Drawer = ({ isOpen, children }) => {
	return (
		<StyledDrawer isOpen={isOpen}>
			<Scrim />
			<Container>{children}</Container>
		</StyledDrawer>
	)
}

export default Drawer
