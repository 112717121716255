import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import MediaBanner from './MediaBanner'

const PAGE_QUERY = gql`
	query SinglePageQuery($id: Int!) {
		singlePage(id: $id) {
			featuredMedia {
				altText
				sizes {
					sizeId
					url
					width
				}
			}
		}
	}
`

const MediaBannerWithQuery = ({ pageId, title }) => (
	<Query query={PAGE_QUERY} variables={{ id: pageId }}>
		{({ data: { singlePage = {} } }) => {
			const { featuredMedia } = singlePage
			return <MediaBanner title={title} featuredMedia={featuredMedia} />
		}}
	</Query>
)

export default MediaBannerWithQuery
