import styled, { css } from 'styled-components'
import { darken, transparentize } from 'polished'
import { ghostButton, themeMode, color } from 'core/theme'

const colorStyle = props => {
  if (props.primary) {
    return color.primary.default
  }
  if (props.secondary) {
    return color.secondary.default
  }

  if (props.yellow) {
    return color.category.first
  }
  if (props.blue) {
    return color.category.third
  }

  if (props.emergency) {
    return color.category.emergency
  }

  return color.text.variant.label
}

export const StyledButton = styled.button.attrs({
  type: 'button',
})`
  ${ghostButton};

  ${({ theme, ...variationProps }) =>
    theme.mode === themeMode.contrast
      ? css`
          color: ${color.contrast.selected};

          &:hover {
            /* FIXME: Ja poga ir position: absolute */
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border: 1px solid currentColor;
            }
          }
        `
      : css`
          color: ${colorStyle(variationProps)};

          &:hover {
            ${variationProps.primary ||
            variationProps.secondary ||
            variationProps.yellow ||
            variationProps.blue
              ? css`
                  background-color: ${props =>
                    transparentize(0.9, colorStyle(props)(props))};
                  color: ${props => darken(0.15, colorStyle(props)(props))};
                `
              : css`
                  background-color: ${props =>
                    transparentize(0.92, colorStyle(props)(props))};
                  color: ${color.text.dark};
                `};
          }
        `};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.25;
      pointer-events: none;
    `};
`
