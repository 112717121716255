import styled, { css } from 'styled-components'
import { breakpoint } from 'core/layout'
import { isPrintMedia } from 'core/theme'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em;

  > * {
    margin: 0.5rem;
    width: calc(100% - 1rem);
  }

  ${breakpoint.tablet`
    > * {
      width: calc(${(1 / 2) * 100}% - 1rem);
    }
  `};

  ${breakpoint.laptop`
    margin: -0.75rem;

    > * {
      margin: 0.75rem;
      width: calc(${(1 / 3) * 100}% - 1.5rem);
    }
  `};

  @supports (display: grid) {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1.5rem;
    grid-template-columns: repeat(
      auto-fit,
      minmax(${props => props.minColWidth}px, 1fr)
    );

    margin: 0;

    > * {
      flex: 1;
      margin: 0;
      min-width: auto;
      width: auto;
    }

    ${breakpoint.laptop`
      grid-column-gap: 1.5rem;
      grid-template-columns: repeat(${props => props.maxColCount}, 1fr);

      ${({ type }) =>
        type !== 'compact' &&
        css`
          grid-row-gap: 3rem;
        `};
    `};
  }

  ${isPrintMedia`
    > * {
      break-inside: avoid;
      page-break-inside: avoid;
    }
  `};
`
