import React from 'react'
import { withRouter, matchPath } from 'react-router'
import { Link } from 'react-router-dom'
import { AspectRatio, ErrorBoundary } from 'core/components'
import { Transl } from 'core/translations'
import { siteWidth, sizes } from 'core/layout'
import { NextIcon } from 'core/icons'
import { ResponsiveImg, Tag, TagsGroup, Button } from 'elements'
import { toImageSrc } from 'utils/media'
import { replaceEntities } from 'utils/entities'
import { getHumanDate } from 'utils/date'
import {
	StyledBlock,
	MediaWrapper,
	ContentWrapper,
	TitleLarge,
	TitleMedium,
	Metadata,
	Text,
	Actions,
} from './styled'

const ActionButton = ({ onClick }) => (
	<Button secondary onClick={onClick}>
		<Transl>Lasīt vairāk</Transl>
		<NextIcon />
	</Button>
)

const ArticleBlock = ({
	url,
	title: rawTitle,
	date,
	tags,
	excerpt,
	image,
	lang,
	type,
	history,
}) => {
	const navigateProps = {
		pathname: url,
	}

	const navigateTo = () => {
		history.push(navigateProps)
	}

	const { params: urlParams } = matchPath(url, {
		path: '/:lang?/:page/:post',
		exact: true,
		strict: false,
	})

	const title = replaceEntities(rawTitle)

	const isLargeType = type === 'large'
	let imageWithLargeThumb
	if (image && isLargeType) {
		const largeSrcSet = image.srcSet.find(srcSet => {
			const [, width] = srcSet.split(' ')
			if (width === '1024w') {
				return true
			}
			return false
		})

		const largeSrc = largeSrcSet && largeSrcSet.split(' ')[0]

		imageWithLargeThumb = {
			...image,
			src: largeSrc || image.src,
		}
	}

	return (
		<StyledBlock type={type}>
			{/* NOTE: Pieejamības uzlabošanai satura bloks ir pirmais,
        lai aktualizētu saistošo informāciju. Vizuāli bloki tiek
        mainīti vietām izmantojot Flexbox order. */}
			<ContentWrapper>
				<ErrorBoundary silent>
					<TagsGroup>
						{tags &&
							tags
								.filter(tag => tag)
								.map(({ name, slug }) => (
									<Tag key={slug} urlParams={urlParams} slug={slug}>
										{name}
									</Tag>
								))}
					</TagsGroup>
				</ErrorBoundary>

				{type === 'large' ? (
					<TitleLarge>
						<Link to={navigateProps}>{title}</Link>
					</TitleLarge>
				) :
				(
					<TitleMedium>
						<Link to={navigateProps}>{title}</Link>
					</TitleMedium>
				)}

				<Metadata>
					<time dateTime={date}>{getHumanDate(date, lang)}</time>
				</Metadata>

				{excerpt && <Text dangerouslySetInnerHTML={{ __html: excerpt }} />}

				{isLargeType && (
					<Actions>
						<ActionButton onClick={navigateTo} />
					</Actions>
				)}
			</ContentWrapper>

			<MediaWrapper onClick={navigateTo}>
				<AspectRatio>
					{isLargeType && imageWithLargeThumb ? (
						<ResponsiveImg
							sizes={`(min-width: ${sizes.laptop}px) 800px, 100vw`}
							{...imageWithLargeThumb}
						/>
					) : image ? (
						<ResponsiveImg
							sizes={`(min-width: ${siteWidth}px) 411px, (min-width: 917px) ${(1 /
								3) *
								100}vw, (min-width: 633px) 50vw, 100vw`}
							{...image}
						/>
					) : null}
				</AspectRatio>
			</MediaWrapper>
		</StyledBlock>
	)
}

ArticleBlock.minWidth = 268

ArticleBlock.mapToArticle = ({
	id,
	slug,
	url,
	date,
	lang,
	tags,
	title,
	excerpt,
	featuredMedia,
}) => ({
	id,
	slug,
	url,
	date,
	lang,
	tags,
	title,
	excerpt,
	image: toImageSrc(featuredMedia) || null,
})

export default withRouter(ArticleBlock)
