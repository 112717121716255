import React from 'react'
import { NextIcon } from 'core/icons'
import { StyledList } from './styled'

const Breadcrumbs = ({ children }) => (
	<StyledList>
		{children &&
			children.map((item, i) => (
				<React.Fragment>
					{i > 0 && <NextIcon />}
					<li key={i}>{item}</li>
				</React.Fragment>
			))}
	</StyledList>
)

export default Breadcrumbs
