import React, {Suspense, lazy, useState, useEffect} from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Helmet } from 'react-helmet'
import { Switch, Route, Redirect } from 'react-router-dom'
import { getTemplateName, getFilterParams } from 'utils/router'
import { getMetaTags } from 'utils/seo'
import { sendPageView } from 'utils/analytics'
import { ErrorBoundary } from 'core/components'
import { HiddenInPrint, VisibleOnlyInPrint } from 'core/theme'
import {Loader, ErrorMessage, Headline, Button} from 'elements'
import { CookieConsent } from 'modules'
import {Transl} from "../../core/translations";
import Cookies from "js-cookie";
import CookieContext from "../../core/context/CookieContext";

const COOKIE_NAME = 'cookies-accepted'

const NavAtrasts = lazy(() => import('pages/NavAtrasts'))

const PAGES_QUERY = gql`
	query PagesQuery($lang: String!) {
		pages(lang: $lang) {
			id
			title
			slug
			url
			excerpt
			featuredMedia {
				altText
				caption
				sizes {
					sizeId
					url
					width
				}
			}
			template
			acf
		}
	}
`

const parseCookie = (cookieValue) => {
	if (!cookieValue) {
		return null;
	}

	try {
		return JSON.parse(cookieValue)
	} catch (e) {
		return null;
	}
}

const Router = ({ activeLangObject, meta }) => {
	const {
		slug: activeLang,
		pageOnFront,
		pageForPosts,
		isDefaultLang,
	} = activeLangObject

	// const notFoundPath = '/' + activeLang + '/404';

	const [showCookiesConsent, setShowCookiesConsent] = useState(false)
	const [cookieValue, setCookieValue] = useState(null)

	useEffect(() => {
		const cookie = parseCookie(Cookies.get(COOKIE_NAME))
		setCookieValue(cookie)
		setShowCookiesConsent(!Boolean(cookie))
	}, [])

	useEffect(() => {
		if (null === cookieValue) {
			return;
		}

		if (cookieValue === 1 || (Array.isArray(cookieValue) && cookieValue.includes("analytical"))) {
			window.gtag('consent', 'update', {
				'ad_storage': 'granted',
				'analytics_storage': 'granted'
			});
		} else {
			if (!!Cookies.get('_ga')) {
				Cookies.remove('_ga', { path: '/', domain: '.' + window.location.hostname })
			}
			if (!!Cookies.get('_gid')) {
				Cookies.remove('_gid', { path: '/', domain: '.' + window.location.hostname })
			}
			const gaId = process.env.REACT_APP_TRACKING_ID.split('-')[1];
			if (!!Cookies.get('_gat_gtag_' + gaId)) {
				Cookies.remove('_gat_gtag_' + gaId, { path: '/', domain: '.' + window.location.hostname })
			}

			window.gtag('consent', 'default', {
				'ad_storage': 'denied',
				'analytics_storage': 'denied'
			});
		}

		if (cookieValue === 1 || (Array.isArray(cookieValue) && cookieValue.includes("marketing"))) {
			window.fbq('consent', 'grant');
		} else {
			if (!!Cookies.get('_fbp')) {
				Cookies.remove('_fbp', { path: '/', domain: '.' + window.location.hostname })
			}

			window.fbq('consent', 'revoke');
		}


	}, [cookieValue]);

	useEffect(() => {
	}, [showCookiesConsent]);

	return (
		<Query query={PAGES_QUERY} variables={{ lang: activeLang }}>
			{({ loading, error, data }) => {
				if (loading) return <Loader />
				if (error) return <ErrorMessage message={error.message} />

				const pages = data.pages
					.map((page) => ({
						...page,
						templateName: getTemplateName(page, pageOnFront, pageForPosts),
					}))
					.concat({
						slug: 'meklet',
						url: '/meklet',
						templateName: 'Meklet',
					})

				return (
					<CookieContext.Provider value={{setShowCookiesConsent, cookieValue, setCookieValue}}>
						<ErrorBoundary
							scope="route-loading" /* module fails to load, network error */
						>
							<Suspense fallback={<Loader />}>
									<Switch>
										<Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
										{pages
											.map((page) => {
												const { slug, url, title, templateName } = page
												return (
													<Route
														key={slug}
														path={url}
														exact={url === '/'}
														render={(props) => {
															const { location } = props
															const filterProps = getFilterParams(location)

															const Component = lazy(() => {
																return import(`pages/${templateName}`)
															})

															return (
																<>
																	<Helmet
																		title={title}
																		meta={getMetaTags(meta, page, 'website')}
																	/>

																	{sendPageView({
																		title,
																		pathname: location.pathname,
																	})}

																	<VisibleOnlyInPrint>
																		<div style={{ margin: '2rem 0' }}>
																			<Headline>{title}</Headline>
																		</div>
																	</VisibleOnlyInPrint>

																	<Component
																		{...props}
																		activeLang={activeLang}
																		isDefaultLang={isDefaultLang}
																		filterProps={filterProps}
																		availablePages={pages}
																		pageProps={page}
																		meta={meta}
																	/>
																</>
															)
														}}
													/>
												)
											})
											.concat(
												<Route
													key="nav-atrasts"
													render={(props) => (
														<NavAtrasts
															{...props}
															frontPage={pages.find(
																(page) => page.id === pageOnFront
															)}
															activeLang={activeLang}
															isDefaultLang={isDefaultLang}
														/>
													)}
												/>
												// Stay in same page and render 404 component
												//
												// <Redirect
												// 	key="novirzits"
												// 	to={{
												// 		pathname: notFoundPath,
												// 		state: {
												// 			referrer: window.location.pathname,
												// 		},
												// 	}}
												// />
											)}
									</Switch>
							</Suspense>
						</ErrorBoundary>

						<HiddenInPrint>
							{showCookiesConsent &&
								<CookieConsent
									availablePages={pages}
								/>}
							<Button
								style={{
									position: 'fixed',
									right: 0,
									bottom: 0,
								}}
								onClick={() => {
									setShowCookiesConsent(true)
								}}>
								<Transl>Sīkdatņu iestatījumi</Transl>
							</Button>
						</HiddenInPrint>
					</CookieContext.Provider>
				)
			}}
		</Query>
	)
}

export default Router
