import styled from 'styled-components'
import { rem } from 'polished'
import { Button as ButtonElement } from 'elements'

export const StyledToggleButton = styled(ButtonElement)`
  width: 3em;
  height: 3em;
  padding: 0.5em;
  outline-offset: -${rem(4)};

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${rem(4)};

  & > svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;

    path {
      fill: currentColor;
    }
  }
`
