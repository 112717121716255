import React from 'react'
import { StyledIcon } from '../styled'
import { ReactComponent as Icon } from '../assets/min/mail.svg'

const Mail = ({ medium, large }) => (
	<StyledIcon medium={medium} large={large}>
		<Icon />
	</StyledIcon>
)

export default Mail
