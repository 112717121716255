import React, {useContext, useState} from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Transl } from 'core/translations'
import {Checkbox, OutlinedButton} from 'elements'
import {
	StyledInfoBanner,
	ContentGroup,
	ActionsGroup,
	ContentWrapper, ChoiceGroup,
} from './styled'
import CookieContext from "../../core/context/CookieContext";

const COOKIE_NAME = 'cookies-accepted'
const COOKIE_EXPIRES = 30
const COOKIE_TYPES = [
	'functional',
	'analytical',
	'marketing'
]

const CookieConsent = props => {
	const { availablePages } = props

	const { setShowCookiesConsent, cookieValue, setCookieValue } = useContext(CookieContext);
	const acceptedTypes = cookieValue ? (cookieValue === 1 ? COOKIE_TYPES : cookieValue) : [COOKIE_TYPES[0]];
	const [acceptedCookieTypes, setAcceptedCookieTypes] = useState(acceptedTypes)
	const [showCookieTypes, setShowCookieTypes] = useState(false)

	const targetPage = availablePages.find(
		page => page.templateName === 'PrivatumaPolitika'
	)
	const targetPageUrl = targetPage ? targetPage.url : ''

	const setCookie = (value = null) => {
		const cookieValue = value ? value : acceptedCookieTypes
		Cookies.set(COOKIE_NAME, cookieValue, { expires: COOKIE_EXPIRES })
		setShowCookiesConsent(false)
		setCookieValue(cookieValue)
	}

	return (
		<StyledInfoBanner>
			<ContentWrapper>
				<ContentGroup>
					<strong>
						<Transl>
							Šī tīmekļa vietne izmanto sīkdatnes
						</Transl>
					</strong>

					<p>
						<Transl>Mēs izmantojam savas un trešo pušu sīkdatnes, lai nodrošinātu un uzlabotu tīmekļa vietnes darbību, pielāgotu informāciju un analizētu vietnes apmeklējumu.
						Detalizētāku informāciju par sīkdatnēm Jūs varat iegūt</Transl>{' '}
						<Link to={targetPageUrl}>
							<Transl>Sīkdatņu politika - Labs of Latvia.</Transl>
						</Link>
					</p>
				</ContentGroup>

				{showCookieTypes && <ChoiceGroup>

						<Checkbox
							id="functional"
							disabled="disabled"
							defaultChecked={true}
						/>
						<label htmlFor="functional" style={{opacity: 0.5}}>
							<Transl>Nepieciešamās</Transl>
						</label>

						<Checkbox
							id="analytical"
							defaultChecked={acceptedCookieTypes.includes('analytical')}
							onChange={event => {
								const isChecked = event.target.checked
								const type = event.target.id
								if (isChecked) {
									setAcceptedCookieTypes([...acceptedCookieTypes, type])
								} else {
									setAcceptedCookieTypes(
										acceptedCookieTypes.filter(v => v !== type)
									)
								}
							}}
						/>
						<label htmlFor="analytical">
							<Transl>Analītikas</Transl>
						</label>

						<Checkbox
							id="marketing"
							defaultChecked={acceptedCookieTypes.includes('marketing')}
							onChange={event => {
								const isChecked = event.target.checked
								const type = event.target.id
								if (isChecked) {
									setAcceptedCookieTypes([...acceptedCookieTypes, type])
								} else {
									setAcceptedCookieTypes(
										acceptedCookieTypes.filter(v => v !== type)
									)
								}
							}}
						/>
						<label htmlFor="marketing">
							<Transl>Mārketinga</Transl>
						</label>

				</ChoiceGroup>}

				<ActionsGroup>
					<OutlinedButton appearance="reverse"
						onClick={() => setCookie(COOKIE_TYPES)}
					>
						<Transl>Apstiprināt visas</Transl>
					</OutlinedButton>
					{!showCookieTypes && <OutlinedButton
						onClick={() => setShowCookieTypes(true)}
					>
						<Transl>Pielāgot/Atteikties</Transl>
					</OutlinedButton>}
					{showCookieTypes && <OutlinedButton
						onClick={() => setCookie()}
					>
						<Transl>Apstiprināt atzīmētās</Transl>
					</OutlinedButton>}
				</ActionsGroup>
			</ContentWrapper>
		</StyledInfoBanner>
	)
}

export default CookieConsent
