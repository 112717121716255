import React from 'react'
import { ReactComponent as SVG } from './assets/logo-labs_of_latvia_mission_latvia_v2.svg'
import { Wrapper } from './styled'

const Logo = () => (
	<Wrapper>
		<SVG />
	</Wrapper>
)

export default Logo
