import React from 'react'

const SSRContext = React.createContext({
  hint: false,
  routerProps: {},
})

export const ssrReadyMark = (
  <SSRContext.Consumer>
    {({ hint }) => (hint ? <div data-ssr-ready="true" /> : null)}
  </SSRContext.Consumer>
)

export default SSRContext
