import React from 'react'
import PropTypes from 'prop-types'
import { StyledImg } from './styled'

const ResponsiveImg = ({ sizes, srcSet, src, alt, style }) => {
	const srcSetAttr = Array.isArray(srcSet) ? srcSet.join(', ') : srcSet
	return (
		<StyledImg
			sizes={sizes}
			srcSet={srcSetAttr}
			src={src}
			alt={alt}
			style={style}
		/>
	)
}

ResponsiveImg.propTypes = {
	sizes: PropTypes.string,
	srcSet: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	src: PropTypes.string.isRequired,
	alt: PropTypes.string,
}

ResponsiveImg.defaultProps = {
	alt: '' /* Should it be required? */,
}

export default ResponsiveImg
