import React from 'react'
import { Transl } from 'core/translations'
import { ThemeContext, themeMode, fontSize } from 'core/theme'
import { ToggleButton } from 'elements'
import { ReactComponent as CompareIcon } from './assets/baseline-compare-24px.svg'
import { Group, FontSizeIcon } from './styled'

const AccessibilityMenus = ({ onToggleFontSize, onToggleThemeMode }) => (
	<ThemeContext.Consumer>
		{({ mode, size }) => (
			<Group>
				{size === fontSize.large ? (
					<Transl id='Mainīt teksta izmēru uz "standarta"'>
						{translation => (
							<ToggleButton title={translation} onClick={onToggleFontSize}>
								<FontSizeIcon>
									A<small>A</small>
								</FontSizeIcon>
							</ToggleButton>
						)}
					</Transl>
				) : (
					<Transl id='Mainīt teksta izmēru uz "liels"'>
						{translation => (
							<ToggleButton title={translation} onClick={onToggleFontSize}>
								<FontSizeIcon>
									A<small>A</small>
								</FontSizeIcon>
							</ToggleButton>
						)}
					</Transl>
				)}

				{mode === themeMode.contrast ? (
					<Transl id="Atspējot kontrasta režīmu">
						{translation => (
							<ToggleButton title={translation} onClick={onToggleThemeMode}>
								<CompareIcon />
							</ToggleButton>
						)}
					</Transl>
				) : (
					<Transl id="Iespējot kontrasta režīmu">
						{translation => (
							<ToggleButton title={translation} onClick={onToggleThemeMode}>
								<CompareIcon />
							</ToggleButton>
						)}
					</Transl>
				)}
			</Group>
		)}
	</ThemeContext.Consumer>
)

export default AccessibilityMenus
