import React from 'react'
import { FilterChip } from 'elements'
import { toValuesArray, toObjectsArray } from 'utils/tags'
import { Transl } from 'core/translations'
import { ToggleBar } from './components'
import { StyledDrawer, ChipsList, Label } from './styled'

const FilterDrawer = ({
	filterProps,
	tags,
	onFilterChange,
	onExpand,
	isExpanded,
	printMode,
}) => {
	const { tags: filterTags } = filterProps
	const filterTagsObjArr = toObjectsArray(filterTags, tags, 'slug')

	const _onChange = (tag, isActive) => {
		let newFilterTags
		if (isActive) {
			newFilterTags = filterTagsObjArr.filter(
				filterTag => filterTag.slug !== tag.slug
			)
		} else {
			newFilterTags = [...filterTagsObjArr, { ...tag }]
		}

		const tagsSlugArr = toValuesArray(newFilterTags, 'slug')
		onFilterChange && onFilterChange(tagsSlugArr)
	}

	if (printMode) {
		const activePrintTags =
			tags &&
			tags.filter(tag => filterTags.some(filterTag => filterTag === tag.slug))

		if (activePrintTags && activePrintTags.length > 0) {
			return (
				<StyledDrawer>
					<Label>
						<Transl>Filtrs:</Transl>
					</Label>
					<ChipsList>
						{activePrintTags
							.filter(tag => !!tag)
							.map(tag => {
								return (
									<li key={tag.slug}>
										<FilterChip tag={tag} isActive />
									</li>
								)
							})}
					</ChipsList>
				</StyledDrawer>
			)
		}
		return null
	}

	return (
		<StyledDrawer>
			<ToggleBar
				isExpanded={isExpanded}
				onExpand={() => onExpand && onExpand(!isExpanded)}
			/>
			{isExpanded && (
				<ChipsList>
					{tags &&
						tags
							.filter(tag => !!tag)
							.map(tag => (
								<li key={tag.slug}>
									<FilterChip
										tag={tag}
										onClick={_onChange}
										isActive={filterTags.some(
											filterTag => filterTag === tag.slug
										)}
									/>
								</li>
							))}
				</ChipsList>
			)}
		</StyledDrawer>
	)
}

export default FilterDrawer
