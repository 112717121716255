import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

const TAGS_QUERY = gql`
	query TagsQuery($lang: String!, $taxonomy: String!) {
		tags(lang: $lang, taxonomy: $taxonomy) {
			id
			count
			name
			slug
		}
	}
`

const TagsQuery = ({ lang, taxonomy, children }) => {
	if (!children || typeof children !== 'function') {
		throw new Error('TagsQuery children props must be of type function.')
	}
	return (
		<Query query={TAGS_QUERY} variables={{ lang, taxonomy }}>
			{({ loading, error, data }) => {
				return children({ loading, error, data })
			}}
		</Query>
	)
}

export default TagsQuery
