import React from 'react'
import { NavLink } from 'react-router-dom'
import { Logo } from 'elements'
import { Transl } from 'core/translations'
import { VisuallyHidden } from 'core/components'
import { StyledFooter, LogoWrapper, SecondaryNav, List } from './styled'

const Footer = ({ primaryNav, secondaryNav, frontPageUrl }) => {
	return (
		<StyledFooter>
			<LogoWrapper>
				<Logo />
				<small>
					Labs of Latvia {` ${new Date().getFullYear()}`}
				</small>
			</LogoWrapper>

			<SecondaryNav>
				<VisuallyHidden>
					<p id="secondary-navigation">
						<Transl>Sekundārā izvēlne</Transl>
					</p>
				</VisuallyHidden>
				<List columnCount={2}>
					{primaryNav &&
						primaryNav.items
							.filter(({ url }) => url !== frontPageUrl)
							.map(({ url, title }, i) => (
								<li key={i}>
									<NavLink exact={url === '/'} to={url}>
										{title}
									</NavLink>
								</li>
							))}
				</List>
				<List columnCount={1}>
					{secondaryNav &&
						secondaryNav.items.map(({ url, title }, i) => (
							<li key={i}>
								<NavLink exact={url === '/'} to={url}>
									{title}
								</NavLink>
							</li>
						))}
				</List>
			</SecondaryNav>
		</StyledFooter>
	)
}

export default Footer
