import React from 'react'
import { StyledCheckbox } from './styled'

const Checkbox = ({ id, name, value, defaultChecked, onChange, disabled }) => {
	return (
		<StyledCheckbox
			id={id}
			name={name}
			value={value}
			defaultChecked={defaultChecked}
			onChange={onChange}
			disabled={disabled}
		/>
	)
}

export default Checkbox
