import React from 'react'

const MODE_DEFAULT = 'default'
const MODE_CONTRAST = 'contrast'
const FONT_MEDIUM = 'medium'
const FONT_LARGE = 'large'

export const themeMode = {
  [MODE_DEFAULT]: MODE_DEFAULT,
  [MODE_CONTRAST]: MODE_CONTRAST,
}

export const fontSize = {
  [FONT_MEDIUM]: FONT_MEDIUM,
  [FONT_LARGE]: FONT_LARGE,
}

const ThemeContext = React.createContext({
  mode: MODE_DEFAULT,
  toggleThemeMode: () => {},
  size: FONT_MEDIUM,
  toggleFontSize: () => {},
})

export default ThemeContext
