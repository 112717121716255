import React from 'react'
import { FlexLayout } from './styled'

class FallbackFlexLayout extends React.Component {
	state = {
		supportsGrid: true,
		measurementsTaken: false,
	}

	componentDidMount() {
		const supportsGrid =
			window.CSS && window.CSS.supports && CSS.supports('grid-area: actions')

		this.setState(prevState => ({
			...prevState,
			supportsGrid: !!supportsGrid,
			measurementsTaken: true,
		}))
	}

	render() {
		const { children, style } = this.props
		const { measurementsTaken, supportsGrid } = this.state

		if (measurementsTaken) {
			const Wrapper = supportsGrid ? React.Fragment : FlexLayout
			const props = supportsGrid ? {} : { style }
			return <Wrapper {...props}>{children}</Wrapper>
		}

		return null
	}
}

export default FallbackFlexLayout
