import { getHumanDate } from 'utils/date'

const padDigit = time => time.toString().padStart(2, '0')

export const getTime = date => {
  return date
    ? {
        h: padDigit(date.getHours()),
        m: padDigit(date.getMinutes()),
      }
    : null
}

export const formatTime = (timeObj, separator = ':') =>
  `${timeObj.h}${separator}${timeObj.m}`

export const getDateTimeFromDay = (day, lang) => {
  const { date, start, end } = day

  let timeStartObj
  if (start) {
    const [h, m] = start.split(':')
    timeStartObj = { h, m }
  }

  let timeEndObj
  if (end) {
    const [h, m] = end.split(':')
    timeEndObj = { h, m }
  }

  const timeSeparator = lang === 'lv' ? '.' : ':'

  return {
    date: {
      from: getHumanDate(date, lang),
      to: getHumanDate(date, lang),
    },
    time: {
      from: timeStartObj && formatTime(timeStartObj, timeSeparator),
      to: timeEndObj && formatTime(timeEndObj, timeSeparator),
    },
  }
}
