import React from 'react'

class IntersectionNode extends React.Component {
	state = {
		isIntersecting: false,
	}

	componentDidMount() {
		const { observer } = this.props
		observer.observe(this)
	}

	componentWillUnmount() {
		const { observer } = this.props
		observer.unobserve(this)
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.isIntersecting && !this.state.isIntersecting) {
			const { observer } = this.props
			observer.unobserve(this)
			return true
		}
		return false
	}

	render() {
		const { children } = this.props
		const { isIntersecting } = this.state
		return <div>{isIntersecting && children}</div>
	}
}

export default IntersectionNode
