import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Link } from 'react-router-dom'
import { ExternalLinkIcon, LocationIcon } from 'core/icons'
import { Transl } from 'core/translations'
import { getDateTimeFromDay } from 'pages/Pasakumi/utils'
import { ErrorMessage, Loader, ExternalLink } from 'elements'
import AddToCalendar from 'react-add-to-calendar-recurring';
import { replaceEntities } from 'utils/entities'
import {
	StyledAsideLists,
	Subtitle,
	IndustryList,
	SupportList,
	Text,
} from './styled'
import './styles.css'

const INSPIRE_ASIDE_QUERY = gql`
	query InspireAsideQuery($id: Int!) {
		inspireAside(id: $id) {
			industries {
				id
				slug
				name
			}
			supportPosts {
				id
				slug
				title
				link
			}
		}
	}
`

const InspireAside = ({ postId }) => (
	<Query query={INSPIRE_ASIDE_QUERY} variables={{ id: postId }}>
		{({ loading, error, data }) => {
			if (loading) return <Loader />
			if (error) return <ErrorMessage message={error.message} />

			const {
				inspireAside: { industries, supportPosts },
			} = data

			return (
				<>
					{industries && industries.length > 0 ? (
						<>
							<Subtitle>
								<Transl>Nozare</Transl>
							</Subtitle>
							<IndustryList>
								{industries.map(({ slug, name }) => (
									<li key={slug}>
										<strong>{name}</strong>
									</li>
								))}
							</IndustryList>
						</>
					) : null}

					{supportPosts && supportPosts.length > 0 ? (
						<>
							<Subtitle>
								<Transl>Atbalsts</Transl>
							</Subtitle>
							<SupportList>
								{supportPosts.map(({ slug, link, title }) => (
									<li key={slug}>
										<Link to={link}>{replaceEntities(title)}</Link>
									</li>
								))}
							</SupportList>
						</>
					) : null}
				</>
			)
		}}
	</Query>
)

const buildDateTime = (dateTimeList, lang) => {
	const isMultiday = dateTimeList.length > 1
	if (isMultiday) {
		return (
			<>
				<Subtitle>
					<Transl>Datums</Transl>
					{` / `}
					<Transl>Laiks</Transl>
				</Subtitle>
				{dateTimeList.map((day, i) => {
					const dateTimeObj = getDateTimeFromDay(day, lang)

					return (
						<Text key={i}>
							{dateTimeObj.date.from}
							{dateTimeObj.time ? (
								dateTimeObj.time.from && dateTimeObj.time.to ? (
									` / ${dateTimeObj.time.from} – ${dateTimeObj.time.to}`
								) : dateTimeObj.time.from ? (
									<>
										{' / '}
										<Transl>No plkst.</Transl> {dateTimeObj.time.from}
									</>
								) : (
									'–'
								)
							) : null}
						</Text>
					)
				})}
			</>
		)
	} else {
		const day = dateTimeList[0]
		const dateTimeObj = getDateTimeFromDay(day, lang)

		return (
			<>
				<Subtitle>
					<Transl>Datums</Transl>
				</Subtitle>
				<Text>{dateTimeObj.date.from}</Text>

				{dateTimeObj.time && (
					<>
						<Subtitle>
							<Transl>Laiks</Transl>
						</Subtitle>
						{dateTimeObj.time.from && dateTimeObj.time.to ? (
							<Text>{`${dateTimeObj.time.from} – ${dateTimeObj.time.to}`}</Text>
						) : dateTimeObj.time.from ? (
							<Text>
								<Transl>No plkst.</Transl> {dateTimeObj.time.from}
							</Text>
						) : (
							'–'
						)}
					</>
				)}
			</>
		)
	}
}

const getMapsLink = ({ lat, lng, address }) => {
	if (address) {
		return `https://www.google.com/maps/search/?api=1&query=${encodeURI(
			address
		)}`
	}
	return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
}

const EventAside = props => {
	const { post, lang } = props
	const { acf } = post
	const { date_time, venue, location, event_homepage, seo_title } = acf

	let firstDate = acf.date_time[0].date
	let startTime = acf.date_time[0].start
	let endTime = acf.date_time[0].end

	// Build calendar event
	let event = {
		title: replaceEntities(seo_title),
		description: replaceEntities(acf.seo_description),
		location: acf.location.address,
		startTime: new Date(firstDate + (startTime ? 'T' + startTime : 'T00:01')).toISOString(),
		endTime: new Date(firstDate + (endTime ? 'T' + endTime : 'T23:59')).toISOString(),
	};

	let calendars = [
		{ outlook: 'Outlook' },
		{ outlookcom: 'Outlook.com' },
		{ apple: 'Apple Calendar' },
		{ google: 'Google' }
	];

	return (
		<>
			{date_time ? buildDateTime(date_time, lang) : null}

			{venue && (
				<>
					<Subtitle>
						<Transl>Norises vieta</Transl>
					</Subtitle>
					{location ? (
						<ExternalLink to={getMapsLink(location)}>
							<Text>
								{venue}
								<LocationIcon />
							</Text>
						</ExternalLink>
					) : (
						<Text>{venue}</Text>
					)}
				</>
			)}

			{event_homepage && (
				<>
					<Subtitle>
						<Transl>Uzzini vairāk</Transl>
					</Subtitle>
					<ExternalLink to={event_homepage}>
						<Transl>Doties uz mājaslapu</Transl>
						<ExternalLinkIcon />
					</ExternalLink>
				</>
			)}

			<Transl id="Pievienot kalendāram">{translation => (
				<AddToCalendar listItems={calendars} event={event} buttonLabel={translation} tabindex="0" />
			)}</Transl>
		</>
	)
}

const getStyleTypeForPostType = postType => {
	switch (postType) {
		case 'event':
			return 'blue'
		default:
			return 'orange'
	}
}

const AsideLists = props => {
	const { postId, post, postType, lang } = props
	return (
		<StyledAsideLists styleType={getStyleTypeForPostType(postType)}>
			{postType === 'inspire' && <InspireAside postId={postId} />}
			{postType === 'event' && <EventAside post={post} lang={lang} />}
		</StyledAsideLists>
	)
}

export default AsideLists
