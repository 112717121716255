import styled, { css } from 'styled-components'
import { isPrintMedia } from 'core/theme'

export const Wrapper = styled.div``

export const RatioEnforcer = styled.div`
  position: relative;

  ${({ ratio }) => {
    return css`
      padding-top: ${ratio * 100}%;
    `
  }};
`

export const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${isPrintMedia`
    img {
      display: block;

      /* Image page break quirks */
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  `};
`
