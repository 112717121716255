import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { toValuesArray, toObjectsArray } from 'utils/tags'
import { Loader, ErrorMessage } from 'elements'

const TAGS_QUERY = gql`
	query TagsQuery($lang: String!, $taxonomy: String!) {
		tags(lang: $lang, taxonomy: $taxonomy) {
			id
			count
			name
			slug
		}
	}
`

// FIXME: Atdalīt acf un content laukus lai nav iekļauts pamatpieprasījumā?

const POSTS_QUERY = gql`
	query PostsQuery(
		$lang: String!
		$base: String!
		$tags: TagsInput
		$offset: Int
		$limit: Int
		$exclude: [Int]
	) {
		posts(
			lang: $lang
			base: $base
			tags: $tags
			offset: $offset
			limit: $limit
			exclude: $exclude
		) {
			edges {
				id
				slug
				url
				date
				title
				excerpt
				content
				featuredMedia {
					altText
					sizes {
						sizeId
						url
						width
					}
				}
				acf
				... on Post {
					tagIds
				}
			}
			pageInfo {
				hasNextPage
				totalPages
			}
			totalRecords
		}
	}
`

const PostsQuery = ({
	activeLang,
	queryParams,
	children,
	filterTags = [],
	variables = {},
}) => {
	if (!children || typeof children !== 'function') {
		throw new Error('PostsQuery children props must be of type function.')
	}

	// FIXME: Variables refaktēšana... exclude

	return (
		<Query
			query={TAGS_QUERY}
			variables={{ lang: activeLang, taxonomy: queryParams.taxonomy }}
		>
			{({ loading, error, data }) => {
				if (loading) return <Loader />
				if (error) return <ErrorMessage message={error.message} />

				const { tags } = data
				const filterTagsObjArr = toObjectsArray(filterTags, tags, 'slug')
				const tagsQueryVar = toValuesArray(filterTagsObjArr, 'id')

				return (
					<Query
						query={POSTS_QUERY}
						variables={{
							lang: activeLang,
							base: queryParams.postType,
							offset: 0,
							limit: variables.limit || 10,
							tags: {
								taxonomy: queryParams.taxonomy,
								ids: [...tagsQueryVar],
							},
							exclude: variables.exclude,
						}}
					>
						{({ loading, error, data, fetchMore }) => {
							if (loading) return <Loader />
							if (error) return <ErrorMessage message={error.message} />

							const { posts = {} } = data
							const { edges = [], pageInfo, totalRecords } = posts

							return children({
								loading,
								data: {
									posts: [...edges],
									tags: [...tags],
								},
								fetchMore: () =>
									fetchMore({
										variables: {
											offset: edges.length,
										},
										updateQuery: (prev, { fetchMoreResult }) => {
											if (!fetchMoreResult) return prev
											return {
												posts: {
													__typename: prev.posts.__typename,
													edges: [
														...prev.posts.edges,
														...fetchMoreResult.posts.edges,
													],
													pageInfo: {
														...fetchMoreResult.posts.pageInfo,
													},
													totalRecords: fetchMoreResult.posts.totalRecords,
												},
											}
										},
									}),
								pageInfo,
								totalRecords,
							})
						}}
					</Query>
				)
			}}
		</Query>
	)
}

export default PostsQuery
