import styled from 'styled-components'
import { rem } from 'polished'
import { color } from 'core/theme'

export const StyledHeading = styled.p`
  color: ${color.onBackground};
  font-size: ${rem(36)};
  font-weight: 600;
  margin-top: 0.75em;
  margin-bottom: 1.75em;

  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 5;

  &::after {
    content: '';
    background: ${color.gradient.orange};
    height: ${rem(2)};
    margin-top: 0.75rem;
  }
`
