import styled, { css } from 'styled-components'

export const StyledIcon = styled.span`
  display: flex;
  font-size: 1rem;
  pointer-events: none;

  svg {
    width: 1em;
    height: 1em;
  }

  ${({ medium }) =>
    medium &&
    css`
      font-size: 1.5rem;
    `};

  ${({ large }) =>
    large &&
    css`
      font-size: 2em;
    `};
`
