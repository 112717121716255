import styled, { css } from 'styled-components'
import { rem, em } from 'polished'
import { color, plainList } from 'core/theme'
import { breakpoint } from 'core/layout'

export const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column-reverse;
  border-top: ${rem(2)} solid ${color.onBackground};
  margin-top: 1.5rem;
  padding: 1.5rem 0;

  ${breakpoint.tablet`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 3rem;
    padding: 2.8rem 0 3rem;
  `};
`

export const LogoWrapper = styled.div`
  flex: 1 0 50%;
  margin-top: 1rem;

  small {
    color: ${color.onSurface};
    display: block;
    font-size: ${em(13)};
    margin-top: 1em;
  }

  ${breakpoint.tablet`
    margin-top: 0;
  `};
`

export const SecondaryNav = styled.nav`
  ${breakpoint.tablet`
    display: flex;
    flex: 1 0 50%;
    justify-content: flex-end;
  `};
`

export const List = styled.ul`
  ${plainList};

  ${({ columnCount }) =>
    columnCount &&
    css`
      column-count: ${columnCount};
      column-gap: 1.5rem;
    `};

  margin: 0 0.75rem;

  &:first-of-type {
    margin-bottom: 1rem;
  }

  a {
    color: ${color.onSurface};
    display: inline-block;
    font-size: ${em(13)};
    outline-offset: -${rem(4)};
    padding: 0.5em;
    text-decoration: none;

    &:hover {
      color: ${color.text.default};
      text-decoration: underline;
    }
  }

  ${breakpoint.tablet`
    &:first-of-type {
      margin-bottom: 0;
    }
  `};
`
