import React, { useRef } from 'react'
import { AspectRatio } from 'core/components'
import { Transl } from 'core/translations'
import { siteWidth } from 'core/layout'
import { HiddenInPrint } from 'core/theme'
import { MailIcon } from 'core/icons'
import { toImageSrc } from 'utils/media'
import { ResponsiveImg, ToggleArrowButton } from 'elements'
import {
	StyledCard,
	Title,
	Text,
	ContentWrapper,
	MediaWrapper,
	Actions,
	ContactButton,
} from './styled'

const filterSubfield = (key, subfields, filter) => {
	if (filter && filter.includes(key) && subfields[key]) {
		return true
	} else if (!filter && subfields[key]) {
		return true
	}
	return false
}

// GA Events
const contactInfoClick = (label, type) => {
	window.gtag('event', 'contacts_' + type + '_click', {
		event_category: 'Contacts ' + type + ' click',
		event_label: label
	});
};

const PersonCard = props => {
	const {
		index,
		slug,
		title,
		image,
		acf,
		showFields,
		formUrl,
		history,
		isActive,
		onActivate,
		isTogglable = true,
		isOnSurface = true,
		tabIndex,
	} = props
	const { scope, institution, phone_number, email, mailigen_form } = acf

	const cardRef = useRef(null)

	let styleProps = {
		order: (index || 0) + 1,
	}

	let togglableProps = {}
	if (isTogglable) {
		togglableProps = {
			...togglableProps,
			isActive,
			onClick: () => {
				onActivate && onActivate(index, slug, cardRef.current)
			},
		}
	}

	return (
		<StyledCard
			ref={cardRef}
			style={styleProps}
			tabIndex={tabIndex}
			isOnSurface={isOnSurface}
			{...togglableProps}
		>
			{/* NOTE: Pieejamības uzlabošanai satura bloks ir pirmais,
        lai aktualizētu saistošo informāciju. Vizuāli bloki tiek
        mainīti vietām izmantojot Flexbox order. */}
			<ContentWrapper style={{ paddingBottom: '1em' }}>
				<Title>{title}</Title>

				{filterSubfield('scope', acf, showFields) && <Text>{scope}</Text>}
				{filterSubfield('institution', acf, showFields) && (
					<Text>{institution}</Text>
				)}
				{filterSubfield('email', acf, showFields) && (
					<a href={`mailto: ${email}`} onClick={() => {contactInfoClick(email, 'email')}}>{email}</a>
				)}
				{filterSubfield('phone_number', acf, showFields) && (
					<a href={`tel: ${phone_number.replace(' ', '-')}`} onClick={() => {contactInfoClick(phone_number, 'phone')}}>{phone_number}</a>
				)}

				{showFields &&
				showFields.includes('contact_form') &&
				formUrl &&
				mailigen_form ? (
					<ContactButton
						primary
						onClick={() => {
							history &&
								history.push({
									pathname: formUrl,
									state: {
										form: mailigen_form,
										person: { ...props },
									},
								})
						}}
					>
						<MailIcon medium />
						<Transl>Saziņas forma</Transl>
					</ContactButton>
				) : null}

				{isTogglable && (
					<Actions>
						<HiddenInPrint>
							<ToggleArrowButton isActive={isActive} />
						</HiddenInPrint>
					</Actions>
				)}
			</ContentWrapper>

			<MediaWrapper>
				<AspectRatio ratio="1:1">
					{image && (
						<ResponsiveImg
							sizes={`(min-width: ${siteWidth}px) 411px, (min-width: 917px) ${(1 /
								3) *
								100}vw, (min-width: 633px) 50vw, 100vw`}
							{...image}
						/>
					)}
				</AspectRatio>
			</MediaWrapper>
		</StyledCard>
	)
}

PersonCard.minWidth = 268

PersonCard.mapPostToCard = ({
	id,
	slug,
	title,
	content,
	featuredMedia,
	acf,
}) => ({
	id,
	slug,
	title,
	content,
	image: toImageSrc(featuredMedia) || null,
	acf,
})

export default PersonCard
