import React from 'react'
import { isSameMonth } from 'date-fns'
import { Link } from 'react-router-dom'
import { Transl } from 'core/translations'
import { replaceEntities } from 'utils/entities'
import { getFormUrl } from 'utils/router'
import { getHumanDate } from 'utils/date'
import { Metadata, Button, Tag, TagsGroup } from 'elements'
import { getDateTimeFromDay } from 'pages/Pasakumi/utils'
import { Headline } from './components'
import {
	StyledEventEntry,
	DateTag,
	ContentWrapper,
	ApplyWrapper,
} from './styled'

const buildEventTime = (dateTimeList, lang) => {
	const isMultiday = dateTimeList.length > 1
	if (isMultiday) {
		return null
	}

	const day = dateTimeList[0]
	const dateTimeObj = getDateTimeFromDay(day, lang)

	if (dateTimeObj.time.from && dateTimeObj.time.to) {
		return (
			<>
				<time dateTime={dateTimeObj.time.from}>{dateTimeObj.time.from}</time>
				{` – `}
				<time dateTime={dateTimeObj.time.to}>{dateTimeObj.time.to}</time>
			</>
		)
	}

	if (dateTimeObj.time.from) {
		return (
			<>
				<Transl>No plkst.</Transl>
				{` `}
				<time dateTime={dateTimeObj.time.from}>{dateTimeObj.time.from}</time>
			</>
		)
	}

	return <>{` – `}</>
}

const buildEventDate = (dateTimeList, lang) => {
	const isMultiday = dateTimeList.length > 1

	const startDay = dateTimeList[0]
	const startDateObj = startDay.date ? new Date(startDay.date) : null
	const startMonth = getHumanDate(startDay.date, lang, { customFormat: 'MMM' })

	if (isMultiday) {
		const endDay = dateTimeList[dateTimeList.length - 1]
		const endDateObj = endDay.date ? new Date(endDay.date) : null
		const endMonth = getHumanDate(endDay.date, lang, { customFormat: 'MMM' })
		const isSameAsStart = isSameMonth(startDateObj, endDateObj)
		return (
			<>
				<p>{`${startDateObj.getDate()}. - ${endDateObj.getDate()}.`}</p>
				<p>{isSameAsStart ? startMonth : `${startMonth} / ${endMonth}`}</p>
			</>
		)
	} else {
		return (
			<>
				<p>{`${startDateObj.getDate()}.`}</p>
				<p>{startMonth}</p>
			</>
		)
	}
}

const EventEntry = props => {
	const {
		title,
		tags,
		url,
		acf,
		isHighlighted,
		urlParams,
		lang,
		history,
	} = props

	const { apply_button, date_time } = acf

	const navigateProps = {
		pathname: url,
	}

	let eventDate
	try {
		eventDate = buildEventDate(date_time, lang)
	} catch (error) {}

	let eventTime
	try {
		eventTime = buildEventTime(date_time, lang)
	} catch (error) {}

	return (
		<StyledEventEntry
			isHighlighted={isHighlighted}
		>
			<DateTag>{eventDate}</DateTag>

			<ContentWrapper
				// onClick={event => {
				// 	const nodeName = event.target.nodeName
				// 	if (nodeName !== 'A' && nodeName !== 'BUTTON') {
				// 		navigateTo()
				// 	}
				// }}
			>
				<div>
					<TagsGroup>
						{tags &&
							tags
								.filter(tag => !!tag)
								.map(({ name, slug }) => (
									<Tag key={slug} urlParams={urlParams} slug={slug}>
										{name}
									</Tag>
								))}
					</TagsGroup>
					<Headline level={2}>
						<Link to={navigateProps}>{replaceEntities(title)}</Link>
					</Headline>
					{eventTime && <Metadata>{eventTime}</Metadata>}
				</div>
				<div>
					<ApplyWrapper>
						{apply_button && (
							<Button
								primary
								onClick={() => {
									history.push({
										pathname: getFormUrl(lang, url, 'event'),
									})
								}}
							>
								<Transl>Pieteikties</Transl>
							</Button>
						)}
					</ApplyWrapper>
				</div>
			</ContentWrapper>
		</StyledEventEntry>
	)
}

export default EventEntry
