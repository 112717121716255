import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Link } from 'react-router-dom'
import { getHumanDate } from 'utils/date'
import { replaceEntities } from 'utils/entities'
import { mapDefaultPostUrl } from 'utils/router'
import { Transl } from 'core/translations'
import { Metadata } from 'elements'
import { Wrapper } from './styled'

const POSTS_QUERY = gql`
	query PostsQuery($lang: String!, $limit: Int, $exclude: [Int]) {
		posts(lang: $lang, limit: $limit, exclude: $exclude) {
			edges {
				id
				slug
				url
				date
				title
			}
		}
	}
`

const NewestPostsAside = ({ activeLang, isDefaultLang, pageUrl, exclude }) => {
	return (
		<Wrapper>
			<Query
				query={POSTS_QUERY}
				variables={{
					lang: activeLang,
					limit: 5,
					exclude: [exclude],
				}}
			>
				{({ loading, error, data }) => {
					if (loading || error) return null

					const { posts = {} } = data
					const { edges = [] } = posts

					return (
						<>
							<p>
								<Transl>Jaunākās ziņas</Transl>
							</p>
							<ul>
								{edges.map(({ slug, url, title, date }) => (
									<li key={slug}>
										<Link
											to={mapDefaultPostUrl(
												'post',
												activeLang,
												isDefaultLang,
												pageUrl,
												url
											)}
										>
											<p>{replaceEntities(title)}</p>
											<Metadata>
												<time dateTime={date}>
													{getHumanDate(date, activeLang)}
												</time>
											</Metadata>
										</Link>
									</li>
								))}
							</ul>
						</>
					)
				}}
			</Query>
		</Wrapper>
	)
}

export default NewestPostsAside
