import React from 'react'
import { replaceEntities } from 'utils/entities'
import { StyledTag } from './styled'

const Tag = ({ urlParams, slug, children }) => {
	const { lang, page } = urlParams
	const composedUrl = lang ? `/${lang}/${page}` : `/${page}`
	return (
		<StyledTag
			to={{
				pathname: composedUrl,
				search: `?tags[]=${slug}`,
				state: {
					wasFilterExpanded: true,
				},
			}}
		>
			{replaceEntities(children)}
		</StyledTag>
	)
}

export default Tag
