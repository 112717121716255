import React from 'react'
import { Helmet } from 'react-helmet'
import { ApolloConsumer } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import { getActiveLangStateFromProps } from 'utils/router'
import { ThemeProvider, HiddenInPrint, VisibleOnlyInPrint } from 'core/theme'
import { SiteContainer, HiddenAbove } from 'core/layout'
import { TranslationsProvider } from 'core/translations'
import { Logo, Footer } from 'elements'
import { TopAppBar, MobileNavDrawer, MobileSearchDrawer, Router, SurveyModal } from 'modules'

import { BannerContainer } from 'modules/TopAppBar/styled'
import { PrintHeader } from './styled'

class App extends React.Component {
	state = {
		activeLang: 'lv',
	}

	_mobileNavRef = React.createRef()

	static getDerivedStateFromProps(props, state) {
		return getActiveLangStateFromProps(props, state)
	}

	_switchLanguage = () => {
		const { location, languages } = this.props

		const switchLang = languages.find(
			lang => lang.slug !== this.state.activeLang
		)

		this.setState(prevState => ({
			...prevState,
			activeLang: switchLang.slug,
			needRedirection: {
				to: switchLang.frontPageUrl,
				from: location.pathname,
			},
		}))
	}

	_toggleMenu = () => {
		const mobileNavProxy = this._mobileNavRef.current
		mobileNavProxy.setState(prevState => ({
			...prevState,
			isOpen: !prevState.isOpen,
		}))
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.needRedirection) {
			this.setState(prevState => ({
				...prevState,
				needRedirection: false,
			}))
		}
	}

	render() {
		const { location, history } = this.props
		const { activeLang, needRedirection } = this.state

		if (needRedirection) {
			const { to, from } = needRedirection
			if (to !== location.pathname && to !== from) {
				return (
					<Redirect
						to={{
							pathname: to,
							state: {
								referrer: from,
							},
						}}
					/>
				)
			}
		}

		const { menus, languages, root } = this.props
		const primaryNav = menus.find(menu => menu.slug === `main-${activeLang}`)
		const secondaryNav = menus.find(
			menu => menu.slug === `secondary-${activeLang}`
		)
		const activeLangObject = languages.find(lang => lang.slug === activeLang)

		return (
			<ThemeProvider>
				{({ mode, toggleThemeMode, toggleFontSize, setPrintMode }) => (
					<ApolloConsumer>
						{client => (
							<TranslationsProvider lang={activeLang} client={client}>
								<Helmet
									defaultTitle="Labs of Latvia"
									titleTemplate="%s - Labs of Latvia"
								>
									<html lang={activeLang} />
								</Helmet>

								<TopAppBar
									onSwitchLanguage={this._switchLanguage}
									onToggleThemeMode={toggleThemeMode}
									onToggleFontSize={toggleFontSize}
									onToggleMenu={this._toggleMenu}
									navItems={primaryNav && primaryNav.items}
									activeLang={activeLang}
									isDefaultLang={activeLangObject.isDefaultLang}
									frontPageUrl={activeLangObject.frontPageUrl}
									themeMode={mode}
									location={location}
									history={history}
								/>

								<HiddenAbove media="mainBreak">
									<MobileNavDrawer
										ref={this._mobileNavRef}
										onSwitchLanguage={this._switchLanguage}
										onToggleThemeMode={toggleThemeMode}
										onToggleFontSize={toggleFontSize}
										navItems={primaryNav && primaryNav.items}
										location={location}
									/>
									<MobileSearchDrawer />
								</HiddenAbove>

								<VisibleOnlyInPrint>
									<PrintHeader>
										<Logo large />
										<BannerContainer>
											<img src={require(`modules/TopAppBar/assets/liaa-eraf-banner-${activeLang}.svg`)} alt="" />
										</BannerContainer>
									</PrintHeader>
								</VisibleOnlyInPrint>

								<SiteContainer>
									<main>
										<Router activeLangObject={activeLangObject} meta={root} />
									</main>
									<HiddenInPrint>
										<Footer
											primaryNav={primaryNav}
											secondaryNav={secondaryNav}
											frontPageUrl={activeLangObject.frontPageUrl}
										/>
									</HiddenInPrint>
									<SurveyModal activeLang={activeLang} />
								</SiteContainer>
							</TranslationsProvider>
						)}
					</ApolloConsumer>
				)}
			</ThemeProvider>
		)
	}
}

export default App
