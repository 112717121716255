import { css } from 'styled-components'

export const ghostButton = css`
  background-color: transparent;
  border: none;
  font: inherit;
  padding: 0.25em 0.5em;
`

export const plainList = css`
  list-style: none;
  margin: 0;
  padding-left: 0;
`
