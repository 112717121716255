import React from 'react'
import shadesOfGrey from './assets/ultra-light-with-labels'
import pinIcon from './assets/pin.png'
import { StyledMap } from './styled'

const apiUrl = 'https://maps.googleapis.com/maps/api/js?loading=async'
const devMaps = process.env.NODE_ENV !== 'production'

class Map extends React.PureComponent {
	componentDidMount() {
		const { disabled, coordinates } = this.props
		if (!disabled && coordinates) {
			this._loadMap()
		}
	}

	_loadMap() {
		window.initMap = () => {
			this._initMap()
		}

		const { activeLang, apiKey } = this.props

		const script = document.createElement('script')
		script.src = devMaps
			? `${apiUrl}&callback=initMap&language=${activeLang}`
			: `${apiUrl}&callback=initMap&language=${activeLang}&key=${apiKey}`
		script.async = true
		script.defer = true
		document.body.appendChild(script)
	}

	_initMap() {
		const { coordinates, zoom, title } = this.props
		const lat = parseFloat(coordinates.lat)
		const lng = parseFloat(coordinates.lng)

		const center = { lat, lng }
		const options = {
			zoom: zoom || 17,
			center: center,
			styles: shadesOfGrey,
			disableDefaultUI: true,
		}

		const mapElement = document.getElementById('map')
		this._map = new window.google.maps.Map(mapElement, options)
		this._marker = new window.google.maps.Marker({
			position: center,
			map: this._map,
			icon: {
				title,
				url: pinIcon,
				size: new window.google.maps.Size(48, 48),
				origin: new window.google.maps.Point(0, 0),
				anchor: new window.google.maps.Point(24, 24),
				scaledSize: new window.google.maps.Size(24, 24),
			},
		})
	}

	render() {
		const { disabled, className, width, height } = this.props

		return (
			<StyledMap
				className={className}
				width={width ? width : '100%'}
				height={height ? height : '100%'}
			>
				{!disabled && <div id="map" />}
			</StyledMap>
		)
	}
}

export default Map
