import theme from 'styled-theming'
import { transparentize } from 'polished'

const COLOR_BLACK = '#000'
const COLOR_WHITE = '#FFF'

const COLOR_GOLD = '#FFCC00'
const COLOR_ORANGE = '#FF5E3A'
const COLOR_PINK = '#FF2A68'
const COLOR_TURQUOISE = '#4CDCBA'
const COLOR_TURQUOISE_LIGHT = '#55EFCB'
const COLOR_TURQUOISE_DARK = '#28CDA6'
const COLOR_BLUE = '#5BCAFF'

const COLOR_GREY_900 = '#3A3A3A'
const COLOR_GREY_800 = '#484848'
const COLOR_GREY_600 = '#767676'
const COLOR_GREY_300 = '#DFDFDF'
const COLOR_GREY_100 = '#F1F4F8'

const COLOR_CONTRAST_LINK = '#FFFF00'
const COLOR_CONTRAST_BUTTON = '#1AEBFF'

const color = {
  black: theme('mode', {
    default: COLOR_BLACK,
    contrast: COLOR_CONTRAST_LINK,
  }),
  white: theme('mode', {
    default: COLOR_WHITE,
    contrast: COLOR_BLACK,
  }),
  blackOnly: COLOR_BLACK,
  whiteOnly: COLOR_WHITE,
  greyOnly: COLOR_GREY_900,

  printBlack: COLOR_BLACK,
  printGrey: COLOR_GREY_600,
  printLightGrey: COLOR_GREY_300,
  printPrimary: COLOR_TURQUOISE_DARK,

  primary: {
    default: theme('mode', {
      default: COLOR_TURQUOISE,
      contrast: COLOR_CONTRAST_LINK,
    }),
    variant: {
      light: theme('mode', {
        default: COLOR_TURQUOISE_LIGHT,
        contrast: COLOR_CONTRAST_LINK,
      }),
      dark: theme('mode', {
        default: COLOR_TURQUOISE_DARK,
        contrast: COLOR_CONTRAST_LINK,
      }),
      decorative: theme('mode', {
        default: COLOR_TURQUOISE_LIGHT,
        contrast: COLOR_GREY_600,
      }),
    },
  },

  secondary: {
    default: theme('mode', {
      default: COLOR_PINK,
      contrast: COLOR_CONTRAST_BUTTON,
    }),
  },

  tertiary: {
    default: theme('mode', {
      default: COLOR_GOLD,
      contrast: 'transparent',
    }),
  },

  text: {
    default: theme('mode', {
      default: COLOR_GREY_800,
      contrast: COLOR_CONTRAST_LINK,
    }),
    subtle: theme('mode', {
      default: COLOR_GREY_600,
      contrast: COLOR_CONTRAST_LINK,
    }),
    dark: theme('mode', {
      default: COLOR_GREY_900,
      contrast: transparentize(0.92, COLOR_WHITE),
    }),
    label: theme('mode', {
      default: COLOR_GREY_600,
      contrast: COLOR_CONTRAST_LINK,
    }),
    variant: {
      label: theme('mode', {
        default: COLOR_GREY_600,
        contrast: COLOR_CONTRAST_BUTTON,
      }),
      labelAlt: theme('mode', {
        default: COLOR_GREY_800,
        contrast: COLOR_CONTRAST_LINK,
      }),
    },
  },

  link: theme('mode', {
    default: COLOR_TURQUOISE_DARK,
    contrast: COLOR_WHITE,
  }),

  category: {
    first: theme('mode', {
      default: COLOR_GOLD,
      contrast: COLOR_CONTRAST_LINK,
    }),
    second: theme('mode', {
      default: COLOR_PINK,
      contrast: COLOR_CONTRAST_LINK,
    }),
    third: theme('mode', {
      default: COLOR_BLUE,
      contrast: COLOR_CONTRAST_LINK,
    }),
    emergency: theme('mode', {
      default: COLOR_TURQUOISE_LIGHT,
      contrast: COLOR_CONTRAST_BUTTON,
    }),
    fallback: theme('mode', {
      default: COLOR_GREY_600,
      contrast: COLOR_CONTRAST_LINK,
    }),
  },

  contrast: {
    background: COLOR_BLACK,
    text: COLOR_WHITE,
    link: COLOR_CONTRAST_LINK,
    selected: COLOR_CONTRAST_BUTTON,
  },

  background: theme('mode', {
    default: COLOR_GREY_100,
    contrast: COLOR_BLACK,
  }),
  border: COLOR_GREY_300,
  outline: theme('mode', {
    default: COLOR_GOLD,
    contrast: COLOR_CONTRAST_LINK,
  }),
  surface: theme('mode', {
    default: COLOR_WHITE,
    contrast: COLOR_GREY_900,
  }),
  error: '',

  onPrimary: COLOR_WHITE,
  onSecondary: COLOR_WHITE,
  onBackground: theme('mode', {
    default: COLOR_GREY_900,
    contrast: COLOR_CONTRAST_LINK,
  }),
  // onSurface: COLOR_GREY_800,
  onSurface: theme('mode', {
    default: COLOR_GREY_800,
    contrast: COLOR_CONTRAST_LINK,
  }),
  onError: COLOR_WHITE,
  onMedia: COLOR_WHITE,

  gradient: {
    orange: theme('mode', {
      default: `linear-gradient(135deg, ${COLOR_GOLD} 0%, ${COLOR_ORANGE} 100%)`,
      contrast: COLOR_GREY_600,
    }),
    primary: theme('mode', {
      default: `linear-gradient(135deg, ${COLOR_ORANGE} 0%, ${COLOR_PINK} 100%)`,
      contrast: COLOR_GREY_600,
    }),
    blue: theme('mode', {
      default: `linear-gradient(40.28deg, ${COLOR_BLUE} 0%, ${COLOR_TURQUOISE_LIGHT} 100%)`,
      contrast: COLOR_GREY_600,
    }),
    blueTransparent: theme('mode', {
      default: `linear-gradient(40.28deg, ${transparentize(
        0.9,
        COLOR_BLUE
      )} 0%, ${transparentize(0.9, COLOR_TURQUOISE_LIGHT)} 100%)`,
      contrast: COLOR_GREY_600,
    }),
    purple: theme('mode', {
      default: `linear-gradient(50.68deg, ${COLOR_PINK} 0%, #A161BE 47%, ${COLOR_BLUE} 100%)`,
      contrast: COLOR_GREY_600,
    }),
  },
}

export default color
