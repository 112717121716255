import React, { useContext } from 'react'
import { ProgressRing } from 'elements'
import { ThemeContext } from 'core/theme'
import { color } from 'core/theme'

const Loader = props => {
	const theme = useContext(ThemeContext)
	const themedColor = color.primary.default({ theme })
	return <ProgressRing radius={16} stroke={3} color={themedColor} />
}

export default Loader
