import React, { useState, useRef, useLayoutEffect } from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { matchPath } from 'react-router'
import { CloseIcon } from 'core/icons'
import { useDebounce } from 'core/hooks'
import { Transl } from 'core/translations'
import { VisuallyHidden } from 'core/components'
import {
	StyledSearchContainer,
	InputWrapper,
	CloseButton,
	SearchInput,
} from './styled'
import { ResultsList } from './components'

const SEARCH_QUERY = gql`
	query SearchQuery($lang: String!, $search: String!) {
		search(lang: $lang, search: $search) {
			edges {
				id
				type
				title
				excerpt
				url
			}
			totalRecords
		}
	}
`

const SearchContainer = ({
	activeLang,
	isDefaultLang,
	location,
	history,
	onClose,
}) => {
	const q =
		location.search &&
		location.search.length > 3 &&
		decodeURI(location.search.substr(3))
	const [query, setQuery] = useState(q || '')
	const debouncedQuery = useDebounce(query, 500)
	const [showResults, setShowResults] = useState(false)

	let inputRef = useRef(null)

	useLayoutEffect(() => {
		inputRef.current.focus()
	}, [])

	const matchSearchPage = matchPath(location.pathname, {
		path: '/:lang?/meklet',
		exact: true,
		strict: false,
	})
	const preventResultsList = matchSearchPage && matchSearchPage.isExact

	const onMoreResults = () => {
		if (debouncedQuery.length > 2) {
			history.push({
				pathname: '/meklet',
				search: `q=${encodeURI(query)}`,
			})
			setShowResults(false)
		}
	}

	const updateQuery = event => {
		setQuery(event.target.value)

		if (preventResultsList) {
		} else {
			setShowResults(true)
		}
	}

	const onKeyDown = event => {
		if (event.keyCode === 13 /* Enter */) {
			onMoreResults()
		}
	}

	return (
		<StyledSearchContainer>
			<InputWrapper>
				<VisuallyHidden>
					<label htmlFor="site-search">
						<Transl>Meklētājs</Transl>
					</label>
				</VisuallyHidden>
				<Transl id="Meklē informāciju lapā">
					{translation => (
						<SearchInput
							ref={inputRef}
							id="site-search"
							name="q"
							autoComplete="off"
							autoCorrect="off"
							autoCapitalize="none"
							results="false"
							spellCheck="false"
							aria-label={translation}
							defaultValue={query}
							onInput={updateQuery}
							onKeyDown={onKeyDown}
							notEmpty={query.length}
						/>
					)}
				</Transl>

				{showResults && debouncedQuery.length > 2 && (
					<Query
						query={SEARCH_QUERY}
						variables={{ lang: activeLang, search: debouncedQuery }}
						skip={!query || query.length === 0}
					>
						{({ loading, error, data }) => {
							if (loading || error) return null

							// Skip fix
							if (!data) {
								return null
							}

							const { search = {} } = data
							const { edges = [] } = search

							return (
								<ResultsList
									results={edges}
									onMore={onMoreResults}
									activeLang={activeLang}
									isDefaultLang={isDefaultLang}
								/>
							)
						}}
					</Query>
				)}
			</InputWrapper>

			<CloseButton secondary onClick={onClose}>
				<CloseIcon medium />
			</CloseButton>
		</StyledSearchContainer>
	)
}

export default SearchContainer
