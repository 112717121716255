import styled, { css } from 'styled-components'
import { em, rem } from 'polished'
import { color, easing } from 'core/theme'
import { breakpoint } from 'core/layout'

// FIXME: Miglaino bilžu problēma (filter?, transform?),
// ja apstiprinās, varam dzēst pilnībā.

export const MediaWrapper = styled.div`
  background-color: ${color.border};
  position: relative;

  > * {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0), 0 1px 2px 0 rgba(0, 0, 0, 0),
      0 0 3px 0 rgba(0, 0, 0, 0);

    will-change: box-shadow;
    transition: box-shadow 600ms ${easing.inOut};

    overflow: hidden;

    /* Noslēpj img scale 1px rounding lēkāšanu */
    > * {
      margin: -1px;
    }
  }

  img {
    width: 100%;

    /* transform: scale(1);
    filter: brightness(1);
    will-change: transform, filter;
    transition-property: filter;
    transition-duration: 600ms;
    transition-timing-function: ${easing.inOut}; */

    @supports (object-fit: cover) {
      height: 100%;
      object-fit: cover;
    }
  }

  &&::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: ${color.gradient.primary};

    will-change: transform;
    transition: transform 400ms ${easing.inOut};
  }
`

export const ContentWrapper = styled.div`
  padding: 1em 1.5em 1.5em;

  order: 1;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`

export const TitleLarge = styled.h2`
  color: ${color.text.default};
  font-size: ${em(20)};
  font-weight: 600;
  line-height: ${24 / 20};
  margin: 1.2rem 0 0;

  will-change: color;
  transition: color 200ms ${easing.out};

  > a {
    color: inherit;
    display: inline-block;
    margin: -${em(8, 20)};
    padding: ${em(8, 20)};
    outline-offset: -${em(4, 20)};
    text-decoration: none;
  }
`

export const TitleMedium = styled.h3`
  color: ${color.text.default};
  font-size: ${em(20)};
  font-weight: 600;
  line-height: ${24 / 20};
  margin: 1.2rem 0 0;

  will-change: color;
  transition: color 200ms ${easing.out};

  > a {
    color: inherit;
    display: inline-block;
    margin: -${em(8, 20)};
    padding: ${em(8, 20)};
    outline-offset: -${em(4, 20)};
    text-decoration: none;
  }
`

export const Metadata = styled.small`
  color: ${color.onSurface};
  font-size: ${em(14)};
  line-height: ${20 / 14};
  display: block;
  margin: 0.5rem 0 0.75rem;
`

export const Text = styled.div`
  color: ${color.text.default};
  margin-top: 0;
  margin-bottom: 0.5;

  > p {
    margin: 0;
  }

  > p + p {
    margin-top: 1em;
  }

  display: none;
  ${breakpoint.laptop`
    display: block;
  `};
`

export const Actions = styled.div`
  > button {
    display: flex;
    align-items: center;
    border-radius: ${rem(4)};
    font-size: ${rem(14)};
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    transform: translateX(-0.5rem);

    > span {
      margin-left: 0.5em;
    }
  }

  ${breakpoint.laptop`
    margin-top: 1rem;
  `};
`

// FIXME: Miglaino bilžu problēma (filter?, transform?),
// ja apstiprinās, varam dzēst pilnībā.

const hoverStyle = css`
  ${MediaWrapper} {
    ${({ type }) =>
      type !== 'large' &&
      css`
        &::before {
          transform: translate(0.5rem, -0.5rem);
        }

        > * {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14),
            0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        }
      `};

    /* img {
      transform: scale(1.05);
      filter: brightness(1.1);
    } */
  }

  ${TitleLarge}, ${TitleMedium} {
    color: ${color.black};
  }
`

const blockStyleLarge = css`
  flex-direction: row;
  align-items: center;

  ${MediaWrapper} {
    flex: 0 0 ${(7 / 12) * 100}%;
  }

  ${TitleLarge}, ${TitleMedium} {
    font-size: ${em(28)};
  }

  ${ContentWrapper} {
    flex: 1 1 ${(4 / 12) * 100}%;
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;
  }
`

const smallTextStyle = css`
  ${TitleLarge}, ${TitleMedium} {
    font-size: ${em(20)};
    margin-top: 0.5em;
  }

  ${Metadata} {
    font-size: ${em(13)};
    line-height: ${20 / 13};
  }
`

const blockStyleMedium = css`
  ${smallTextStyle};

  ${ContentWrapper} {
    padding-left: 0;
    padding-bottom: 0;
  }
`

const blockStyleSmall = css`
  flex-direction: row;
  align-items: center;

  ${smallTextStyle};

  ${Metadata} {
    margin-top: 0;
    margin-top: 0.2em;
    margin-bottom: 0.5em;
  }

  ${MediaWrapper} {
    flex: 0 0 ${rem(176)};
  }

  ${ContentWrapper} {
    flex: 1;
    padding: 0.5em 1em 0.5em 1.5em;
  }
`

const blockStyle = ({ type }) => {
  switch (type) {
    case 'large':
      return blockStyleLarge

    case 'medium':
      return blockStyleMedium

    default:
      return blockStyleSmall
  }
}

export const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;

  &:hover {
    ${hoverStyle};
  }

  &:focus-within {
    ${hoverStyle};
  }

  ${blockStyleMedium};

  ${breakpoint.laptop`
    ${blockStyle};
  `};
`
