export const sendPageView = ({ title, pathname }) => {
  /* TODO: Iekļaut location.hash un location.search */
  if (
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_TRACKING_ID &&
    window.gtag
  ) {
    window.gtag('config', process.env.REACT_APP_TRACKING_ID, {
      page_title: title,
      page_path: pathname,
    })
  }
}
