import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'

const httpEndpoint =
  process.env.GRAPHQL_URL ||
  `${window.location.protocol}//graphql.${window.location.hostname}`

const serverConfig = {
  uri: httpEndpoint
}

const { uri } = serverConfig
const httpLink = createHttpLink({ uri, credentials: 'same-origin' })

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    httpLink,
  ]),
  cache: new InMemoryCache(),
})

export default client
