import styled from 'styled-components'
import { rem } from 'polished'
import { color, isPrintMedia } from 'core/theme'
import { breakpoint } from 'core/layout'

export const Wrapper = styled.div`
  width: ${rem(76)};

  & > svg {
    display: block;
    color: ${color.black};
    width: 100%;

    & > * {
      fill: currentColor;
    }
  }

  ${breakpoint.mainBreak`
    width: ${rem(136)};
  `};

  ${isPrintMedia`
    width: ${rem(192)};
    margin-top: 1rem;

    & > svg {
      color: ${color.printBlack};
    }
  `};
`
