import styled, { css } from 'styled-components'
import { rem, em } from 'polished'
import { Button } from 'elements'
import { color, easing, isPrintMedia, themeMode } from 'core/theme'
import {
  ContentWrapper as BaseContentWrapper,
  MediaWrapper as BaseMediaWrapper,
  Actions as BaseActions,
} from 'modules/ArticleCard/styled'

export const ContentWrapper = styled(BaseContentWrapper)`
  align-items: flex-start;
  padding-bottom: 1em;

  a {
    color: ${color.text.default};
    margin: -0.5em;
    margin-top: -0.25em;
    padding: 0.5em;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    ${({ theme }) =>
      theme.mode === themeMode.contrast &&
      css`
        color: ${color.contrast.link};
      `};

    ${isPrintMedia`
      color: ${color.printBlack};
    `};
  }
`

export const MediaWrapper = styled(BaseMediaWrapper)`
  filter: grayscale(100%);
  will-change: filter;
  transition: filter 200ms ${easing.inOut};

  /* IE11 object-fit fix tikai cilvēku portretiem */
  img {
    height: auto;
  }

  ${isPrintMedia`
    flex: none;
    width: 160px;
    height: 160px;
  `};
`

export const Title = styled.h3`
  color: ${color.black};
  font-size: ${em(20)};
  font-weight: 600;
  line-height: ${24 / 16};
  margin: 0 0 0.2em;

  will-change: color;
  transition: color 200ms ${easing.out};

  ${isPrintMedia`
    color: ${color.printBlack};
  `};
`

export const Text = styled.p`
  color: ${color.text.default};
  margin: 0;
  width: calc(100% - 1.5rem);

  & + & {
    margin-top: 0.25em;
  }

  ${isPrintMedia`
    color: ${color.printGrey};
  `};
`

export const Actions = styled(BaseActions)`
  position: absolute;
  right: 1em;
  bottom: 1em;
`

// FIXME: Miglaino bilžu problēma (filter?, transform?),
// ja apstiprinās, varam dzēst pilnībā.

const hoverStyle = css`
  ${MediaWrapper} {
    filter: grayscale(0%);

    /* img {
      transform: scale(1.05);
      filter: brightness(1.1);
    } */
  }

  ${Title} {
    color: ${color.black};

    ${isPrintMedia`
      color: ${color.printBlack};
    `};
  }

  ${({ isOnSurface }) =>
    isOnSurface &&
    css`
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14),
        0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    `};
`

export const StyledCard = styled.div`
  border-radius: ${rem(4)};
  outline-offset: ${rem(4)};

  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    ${hoverStyle};
  }

  &:focus-within {
    ${hoverStyle};
  }

  ${isPrintMedia`
    background-color: ${color.whiteOnly};
    border: 1px solid ${color.printLightGrey};
    box-shadow: none;

    &,
    > div {
      break-inside: avoid;
      page-break-inside: avoid;
    }

    ${MediaWrapper} {
      border-radius: 0;
    }
  `};

  ${({ isActive }) =>
    isActive &&
    css`
      ${MediaWrapper} {
        filter: grayscale(0%);

        /* img {
          transform: scale(1.05);
        } */
      }

      ${Title} {
        color: ${color.black};
      }

      ${isPrintMedia`
        border: 2px solid ${color.printGrey};

        ${Title} {
          color: ${color.printBlack};
        }
      `};
    `};

  ${({ isOnSurface }) =>
    isOnSurface
      ? css`
          background-color: ${color.surface};

          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07),
            0 3px 4px 0 rgba(0, 0, 0, 0.06), 0 1px 5px 0 rgba(0, 0, 0, 0.1);

          will-change: box-shadow, transform;
          transition: box-shadow 200ms ${easing.out},
            transform 500ms ${easing.inOut};
        `
      : css`
          ${ContentWrapper} {
            padding-left: 0;
            padding-right: 0;
          }
        `};
`

export const ContactButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-top: 0.75em;
  transform: translateX(-0.5em);

  > span {
    margin-right: 0.2em;
  }
`
