import {
  startOfMonth,
  endOfMonth,
  isSameMonth,
  startOfISOWeek,
  endOfISOWeek,
  eachWeekOfInterval,
  eachDayOfInterval,
} from 'date-fns'

export const buildMonthMatrix = (
  { date, month, year },
  convertDate = date => date
) => {
  const dateObj = date ? date : new Date(year, month)

  return eachWeekOfInterval(
    {
      start: startOfMonth(dateObj),
      end: endOfMonth(dateObj),
    },
    {
      weekStartsOn: 1,
    }
  ).map(weekDay =>
    eachDayOfInterval({
      start: startOfISOWeek(weekDay),
      end: endOfISOWeek(weekDay),
    }).map(day =>
      convertDate(day, {
        isSameMonth: isSameMonth(dateObj, day),
      })
    )
  )
}

export const toTitleCase = str => {
  const [firstChar, ...rest] = str
  return `${firstChar.toUpperCase()}${rest.join('')}`
}
