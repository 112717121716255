import React from 'react'
import { Transl } from 'core/translations'

const ErrorMessage = ({ message = '' }) => {
	const messageEl = typeof message === 'string' ? `[${message}]` : message
	return (
		<h2>
			<Transl>Kļūda!</Transl> {messageEl}
		</h2>
	)
}

export default ErrorMessage
