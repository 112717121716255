import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'

// https://css-tricks.com/building-progress-ring-quickly/

const colorsAnim = ({ _color: colors }) => {
	const step = 100 / colors.length
	const offset = step * 0.25
	const keyframesDef = colors.reduce((prevSteps, color, i) => {
		if (i === 0) {
			return `
        0%,
        100% {
          stroke: ${color};
        }
      `
		}
		return `
      ${prevSteps}
      ${step * i - offset}% {
        stroke: ${color};
      }
    `
	}, '')

	return keyframes`
    ${keyframesDef}
  `
}

const dashAnim = ({ _pathLength: pathLength }) => keyframes`
  0% {
    stroke-dasharray: 1, ${pathLength * 2};
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: ${pathLength * 0.9}, ${pathLength * 2};
    stroke-dashoffset: -25;
  }
  100% {
    stroke-dasharray: ${pathLength * 0.9}, ${pathLength * 2};
    stroke-dashoffset: -${pathLength};
  }
`

const fadeInAnim = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const rotateAnim = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const SVG = styled.svg`
	${({ _isIndeterminate, _color }) =>
		_isIndeterminate
			? css`
					opacity: 0;
					will-change: opacity;

					animation: ${rotateAnim} 2s linear infinite,
						${fadeInAnim} 500ms ease-out 1 200ms;
					animation-fill-mode: forwards;
					transform-origin: center;

					circle {
						${_color && Array.isArray(_color)
							? css`
									animation: ${colorsAnim} ${_color.length * 1.5}s ease-in-out
											infinite,
										${dashAnim} 1.5s ease-in-out infinite;
							  `
							: css`
									animation: ${dashAnim} 1.5s ease-in-out infinite;
							  `};
					}
			  `
			: css`
					circle {
						transform: rotate(-90deg);
						transform-origin: center;
					}
			  `};
`

ProgressRing.propTypes = {
	progress: PropTypes.number,
	radius: PropTypes.number,
	stroke: PropTypes.number,
	color: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
}

ProgressRing.defaultProps = {
	progress: -1,
	radius: 24,
	stroke: 4,
	color: 'blue',
}

export default function ProgressRing(props) {
	const { progress, radius, stroke, color } = props

	const isIndeterminate = progress === -1
	const normalizedRadius = radius - stroke * 2
	const circumference = normalizedRadius * Math.PI * 2
	const strokeDashoffset = isIndeterminate
		? circumference - 0.9 * circumference
		: circumference - (progress / 100) * circumference

	let circleRef = React.createRef()
	const [pathLength, setPathLength] = useState(0) // circle.getTotalLength()

	useEffect(() => {
		// Browser support check
		if (circleRef.current.getTotalLength) {
			const pathLength = circleRef.current.getTotalLength()
			setPathLength(pathLength)
		}
	}, [])

	let strokeColor
	if (typeof color === 'string') {
		strokeColor = color
	} else if (Array.isArray(color) && color.length > 0) {
		strokeColor = color[0]
	}

	return (
		<SVG
			_isIndeterminate={isIndeterminate}
			_pathLength={pathLength}
			_color={color}
			style={{
				color: strokeColor,
			}}
			/* SVG props */
			width={radius * 2}
			height={radius * 2}
		>
			<circle
				ref={circleRef}
				/* SVG props */
				stroke="currentColor"
				fill="transparent"
				strokeWidth={stroke}
				strokeDasharray={`${circumference} ${circumference}`}
				style={{ strokeDashoffset }}
				/* strokeLinecap="round" */
				r={normalizedRadius}
				cx={radius}
				cy={radius}
			/>
		</SVG>
	)
}
