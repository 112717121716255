export const dayNames = {
  narrow: ['Pr', 'Ot', 'Tr', 'Ce', 'Pk', 'Se', 'Sv'],
  wide: [
    'Pirmdiena',
    'Otrdiena',
    'Trešdiena',
    'Ceturtdiena',
    'Piektdiena',
    'Sestdiena',
    'Svētdiena',
  ],
}

export const monthNames = [
  'janvāris',
  'februāris',
  'marts',
  'aprīlis',
  'maijs',
  'jūnijs',
  'jūlijs',
  'augusts',
  'septembris',
  'oktobris',
  'novembris',
  'decembris',
]

export const controlsTitle = {
  previous: 'Iepriekšējais mēnesis',
  next: 'Nākamais mēnesis',
}

export const toMonthTitle = ({ year, month }) =>
  `${year}. g. ${monthNames[month]}`
