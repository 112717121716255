import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'elements'

const preStyle = {
	fontFamily: 'monospace',
	whiteSpace: 'pre',
}

class ErrorBoundary extends React.Component {
	static propTypes = {
		scope: PropTypes.string,
	}

	static defaultProps = {
		scope: 'root',
	}

	state = {
		hasError: false,
	}

	static getDerivedStateFromError(error) {
		return { hasError: true }
	}

	componentDidCatch(error, info) {
		// logErrorToMyService(error, info)
	}

	render() {
		const { scope, silent, children } = this.props
		const { hasError } = this.state

		if (hasError) {
			if (silent) {
				return null
			}
			const scopeEl = <span style={preStyle}>{scope}</span>
			return <ErrorMessage message={scopeEl} />
		}

		return children
	}
}

export default ErrorBoundary
