import styled from 'styled-components'
import { em } from 'polished'
import { color, plainList } from 'core/theme'
import { breakpoint } from 'core/layout'

export const StyledList = styled.ul`
  ${plainList};
  display: flex;
  margin-bottom: 1.5rem;

  > li {
    display: flex;
    align-items: center;

    &:last-of-type {
      overflow-x: hidden;
      a {
        text-overflow: ellipsis;
        overflow-x: hidden;
      }
    }
  }

  span {
    color: ${color.text.dark};
    transform: translateX(-${em(6, 14)});
    display: flex;
    align-items: center;
  }

  a {
    color: ${color.text.dark};
    display: inline-block;
    font-size: ${em(14)};
    padding: ${em(8, 14)};
    transform: translateX(-${em(8, 14)});
    white-space: nowrap;

    &:hover {
      color: ${color.text.dark};
    }

    &.active {
      color: ${color.text.dark};
      text-decoration: none;
      pointer-events: none;
    }
  }

  ${breakpoint.tablet`
    > li:not(:first-child) {
      max-width: none;
    }

    a {
      white-space: normal;
      overflow-x: auto;
    }
  `};
`
