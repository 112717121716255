import styled from 'styled-components'
import { em } from 'polished'
import { plainList, color, isPrintMedia } from 'core/theme'

export const StyledTagsGroup = styled.ul`
  ${plainList};

  display: flex;
  flex-wrap: wrap;
  color: ${color.primary.variant.dark};
  font-size: ${em(11)};
  font-weight: 700;

  > li {
    margin-bottom: 0.5em;
    transform: translateX(-0.5em);
  }

  > li + li {
    &::before {
      content: '/';
      display: inline-block;
    }
  }

  ${isPrintMedia`
    > li a {
      color: ${color.printGrey};
    }
  `};
`
