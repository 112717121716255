import styled from 'styled-components'
import { rem } from 'polished'
import { color, plainList } from 'core/theme'

export const Wrapper = styled.div`
  border-left: 1px solid ${color.border};
  margin: 0 3rem 0 2rem;
  padding-left: 3rem;

  > p {
    color: ${color.text.default};
    margin-top: 0;
    font-size: ${rem(28)};
    font-weight: 600;
    padding-bottom: 0.5em;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: ${rem(2)};
      background: ${color.gradient.primary};
    }
  }

  ul {
    ${plainList};
  }

  a {
    display: block;
    opacity: 0.92;
    text-decoration: none;

    p {
      color: ${color.text.variant.labelAlt};
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0;
    }

    &:hover {
      opacity: 1;

      p {
        text-decoration: underline;
      }  

    }
  }
`
