import { getNearestSize } from 'utils/media'
import { stripHTML } from 'utils/entities'

export const getMetaTags = (
  meta,
  { title, excerpt, featuredMedia, acf },
  type
) => {
  const { seo_title, seo_description, seo_keywords, social_images } = acf || {}

  const description = seo_description || stripHTML(excerpt) || meta.description
  let metaTags = [
    {
      name: 'description',
      content: description,
    },
    { property: 'og:site_name', content: meta.name },
    { property: 'og:title', content: seo_title || title },
    {
      property: 'og:description',
      content: description,
    },
    { property: 'og:type', content: type },
    { property: 'og:url', content: window.location.href },
    { property: 'twitter:card', content: 'summary_large_image' },
  ]

  if (seo_keywords) {
    metaTags = [...metaTags, { name: 'keywords', content: seo_keywords }]
  }

  if (social_images && (social_images.facebook || social_images.twitter)) {
    const { facebook, twitter } = social_images

    if (facebook) {
      metaTags = [...metaTags, { property: 'og:image', content: facebook }]
    }
    if (twitter) {
      metaTags = [...metaTags, { property: 'twitter:image', content: twitter }]
    }
  } else {
    const media = featuredMedia && getNearestSize(featuredMedia.sizes, 'full')
    if (media) {
      metaTags = [...metaTags, { property: 'og:image', content: media.url }]
    }
  }

  return metaTags
}
