import styled, { css } from 'styled-components'

export const StyledMap = styled.div`
  &,
  #map {
    min-height: 384px;
  }

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};

  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `};
`
