import React from 'react'
import { Container } from './styled'

const CardsGrid = ({
	children,
	innerRef,
	type,
	minColWidth = 268,
	maxColCount = 3,
}) => {
	return (
		<Container
			minColWidth={minColWidth}
			maxColCount={maxColCount}
			ref={innerRef}
			type={type}
		>
			{children}
		</Container>
	)
}

export default CardsGrid
