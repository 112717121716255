import React from 'react'
import { StyledHeadline } from './styled'

const Headline = ({ level, as, children }) => {
	const levelElPRops = level
		? {
				as: `h${level}`,
		  }
		: {}
	return (
		<StyledHeadline level={level} as={as} {...levelElPRops}>
			{children}
		</StyledHeadline>
	)
}

export default Headline
