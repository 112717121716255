import styled, { css } from 'styled-components'
import { color, plainList, isPrintMedia } from 'core/theme'
import { breakpoint } from 'core/layout'

export const Article = styled.article`
  display: flex;
  flex-direction: column;

  @supports (grid-area: actions) {
    display: grid;
    grid-template-areas:
      'header'
      'media'
      'aside'
      'main'
      'footer'
      'actions';
    grid-template-columns: 1fr;

    &::before {
      content: '';
      background-color: ${color.surface};
      z-index: -1;

      grid-column: 1 / 5;
      grid-row: 1 / 4;

      margin: -1rem;
    }

    ${breakpoint.tablet`
      grid-column-gap: 1.5rem;
      grid-template-columns: 1fr 7fr 3fr 1fr;
      grid-template-rows: auto max-content;

      grid-template-areas:
      '....... header header  .'
      'actions media  aside   .'
      'actions media  .....   .'
      '....... main   main    .'
      '....... footer footer  .';

      &::before {
        grid-column: 1 / 5;
        grid-row: 1 / 3;
        height: calc(100% + 2rem);
      }
    `};

    ${breakpoint.mainBreak`
      &::before {
        margin: 0;
      }
    `};

    ${breakpoint.laptop`
      grid-template-areas:
      '....... header ..... .'
      'actions media  aside .'
      'actions media  ..... .'
      '....... main   ..... .'
      '....... footer ..... .';

      ${({ isDefaultPostType }) =>
        isDefaultPostType &&
        css`
          grid-template-areas:
            '....... header ..... .'
            'actions media  aside aside'
            'actions media  aside aside'
            '....... main   aside aside'
            '....... footer ..... .';
        `};
    `};
  }

  ${isPrintMedia`
    @supports (grid-area: actions) {
      ${({ isDefaultPostType }) =>
        isDefaultPostType
          ? css`
              grid-template-areas:
                'header header'
                'media .'
                'main main';

              figure {
                max-width: 480px;
              }
            `
          : css`
              grid-template-areas:
                'header header'
                'aside media'
                'main main';
            `};
    }

    &::before {
      background-color: ${color.whiteOnly};
    }

    figure {
      min-width: 240pt;
    }
  `};
`

export const Header = styled.header`
  grid-area: header;
  order: 1; /* fallback */

  padding-top: 0.5rem;

  ${breakpoint.tablet`
    margin-top: 2rem;
    padding-top: 0;
  `}

  ${isPrintMedia`
    ul {
      font-size: 1em;
    }

    h1 + small {
      color: ${color.printBlack};
      font-size: 1.5em;
    }
  `};
`

export const Aside = styled.aside`
  grid-area: aside;
  align-self: flex-start;

  > div:empty {
    display: none;
  }

  ${isPrintMedia`
    h4 {
      color: ${color.printGrey};
    }

    p {
      color: ${color.printBlack};
    }
  `};
`

export const Footer = styled.footer`
  grid-area: footer;
  order: 4; /* fallback */

  margin: 2em 0 1.5em;

  &:empty {
    display: none;
  }

  ul {
    ${plainList};

    > li {
      margin: 0.5em 0;
    }

    /* FIXME: Saites elementu pamatstili */
    a {
      color: ${color.primary.variant.dark};
      display: inline-flex;
      align-items: center;
      padding: 0.5em 0;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      > span {
        margin-right: 0.2em;
      }
    }
  }
`

export const ShareActions = styled.div`
  grid-area: actions;

  display: flex;
  justify-content: center;
  flex-direction: column;

  margin-top: 1rem;

  button {
    border-radius: 50%;
    padding: 0.75em;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  /* IE11 fix */
  @supports (grid-area: actions) {
    flex-direction: row;

    button {
      margin: 0 0.75em;
    }
  }

  ${breakpoint.tablet`
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 0;
    margin-bottom: 1rem;

    button {
      margin: 0;
    }
  `};

  ${isPrintMedia`
    display: none;
  `};
`

export const RelatedPostsSection = styled.section`
  margin-top: 1rem;
  margin-bottom: 2.5rem;

  > * > span:empty {
    display: none;
  }

  ${breakpoint.tablet`
    margin-top: 2rem;
    margin-bottom: 3rem;
  `};
`

export const ContentWrapper = styled.div`
  grid-area: main;
  order: 3; /* fallback */

  margin-top: 1.5rem;

  ${breakpoint.tablet`
    margin-top: 0;
  `};

  ${({ useGridFallback }) =>
    useGridFallback &&
    css`
      ${breakpoint.laptop`
      max-width: 70%;
    `};
    `};
`
