import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { color } from 'core/theme'

export const StyledCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  -webkit-appearance: none;

  border: 2px solid ${color.primary.default};
  border-radius: ${rem(2)};
  width: ${rem(18)};
  height: ${rem(18)};
  margin: 0.5em;

  position: relative;

  &:checked {
    background-color: ${color.primary.default};
    overflow: hidden;

    &::after {
      content: '✔';
      color: ${color.surface};
      font: inherit;
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      text-align: center;
      transform: translateY(-0.15rem);
    }
  }

  ${({ disabled }) =>
  disabled &&
  css`
      opacity: 0.5;
    `};
`
