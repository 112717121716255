export const toImageSrc = (media = {}, sizeId = 'medium_large') => {
  const { sizes } = media
  if (sizes && sizes.length) {
    return {
      srcSet: mapSizesToSrcSet(sizes),
      src: getNearestSize(sizes, sizeId).url,
      alt: media.altText,
    }
  }
  return null
}

export const mapSizesToSrcSet = sizes =>
  sizes.map(({ url, width }) => `${url} ${width}w`)

export const getNearestSize = (sizes, sizeId = 'medium_large') => {
  const targetSize = sizes.find(size => size.sizeId === sizeId)
  if (targetSize) {
    return targetSize
  }
  return sizes[sizes.length - 1]
}

export const toMediaSizes = (media = {}) => {
  const { sizes } = media
  if (sizes) {
    const sizesArr = Object.entries(sizes)
      .map(([key, value]) => {
        const [prefix] = key.split('-')
        if (prefix === key) {
          return {
            sizeId: prefix,
            url: sizes[prefix],
            width: sizes[`${prefix}-width`],
            height: sizes[`${prefix}-height`],
          }
        }
        return null
      })
      .filter(v => v !== null)

    return sizesArr
  }
  return null
}

// const getMediaSize = (sizes, key, fallback) => {
//   const details = sizes[key]
//   if (details) {
//     if (typeof details === 'string') {
//       //#region ACF Image
//       // sizes = {
//       //   'thumbnail': 'http://site-name.local/wp-content/uploads/file.jpg',
//       //   'thumbnail-width': 150,
//       //   'thumbnail-height': 150,
//       // }
//       //#endregion ACF Image
//       const {
//         [key]: url,
//         [`${key}-width`]: width,
//         [`${key}-height`]: height,
//       } = sizes
//       return { url, width, height, }
//     } else {
//       //#region REST /wp/v2/media
//       // sizes = {
//       //   "thumbnail": {
//       //     "file": "file.jpg",
//       //     "width": 150,
//       //     "height": 150,
//       //     "mime_type": "image/jpeg",
//       //     "source_url": "http://site-name.local/wp-content/uploads/file.jpg",
//       //   },
//       // }
//       //#endregion REST /wp/v2/media
//       const {
//         source_url: url,
//         width,
//         height,
//       } = details
//       return { url, width, height, }
//     }
//   }
//   return fallback
// }
