import styled from 'styled-components'
import { em, rem } from 'polished'
import { color, plainList, isPrintMedia } from 'core/theme'
import { addToCalendarIcon } from  './assets/calendar-red.png';

export const StyledAsideLists = styled.div`
  padding: 1em 0 1em 2rem;
  position: relative;

  color: ${color.text.default};
  font-size: 1rem;
  line-height: ${24 / 16};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.5rem;
    height: 100%;
    background: ${props => {
      const { styleType } = props
      if (styleType === 'blue') {
        return color.gradient.blue(props)
      }
      return color.gradient.orange(props)
    }};
  }

  > *:first-child {
    margin-top: 0;
  }

  a {
    color: ${color.primary.variant.dark};
    display: block;
    font-weight: 500;
    outline-offset: -${rem(4)};
    padding: 0.5em;
    margin: -0.5em;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    > p {
      color: inherit;
    }

    > span {
      display: inline-flex;
    }
  }

  strong {
    font-weight: 600;
  }

  ${isPrintMedia`
    color: ${color.printBlack};
    font-size: 1.2rem;
    padding-left: 0;

    &::before {
      display: none;
    }

    a {
      color: ${color.printPrimary};
    }
  `};
`

export const Subtitle = styled.p`
  color: ${color.text.subtle};
  font-size: ${em(13)};
  font-weight: 400;
  line-height: ${20 / 13};
  margin-bottom: ${em(2, 13)};
  text-transform: uppercase;

  ${isPrintMedia`
    color: ${color.printBlack};
  `};
`

export const IndustryList = styled.ul`
  ${plainList};

  display: flex;
  flex-wrap: wrap;

  > li + li::before {
    content: '/';
    color: ${color.text.subtle};
    display: inline-block;
    margin: 0 0.25em;

    ${isPrintMedia`
      color: ${color.printGrey};
    `};
  }
`

export const SupportList = styled.ul`
  ${plainList};

  line-height: 1.25;
`

export const Text = styled.p`
  color: ${color.text.default};
  margin: 0;

  > span {
    display: inline-flex;
  }

  ${isPrintMedia`
    color: ${color.printBlack};
  `};
`
