import React, { useState } from 'react'
import {
	format,
	isSameDay,
	isSameYear,
	addMonths,
	endOfMonth,
	startOfMonth,
	differenceInCalendarMonths,
} from 'date-fns'
import localize from './localize'
import { buildMonthMatrix, toTitleCase } from './utils'
import { ReactComponent as ArrowLeftIcon } from './assets/baseline-keyboard_arrow_left-24px.svg'
import { ReactComponent as ArrowRightIcon } from './assets/baseline-keyboard_arrow_right-24px.svg'
import {
	Container,
	TitleGroup,
	Title,
	Controls,
	Button,
	WeekDays,
	Grid,
	Cell,
} from './styled'

const today = new Date()

const Calendar = ({
	activeLang,
	initialDate,
	hasEventAtDay,
	disableDateSelection = false,
	onSelectDate,
	onChangeMonth,
}) => {
	const {
		dayNames,
		monthNames,
		controlsTitle,
		toMonthTitle,
	} = localize.hasOwnProperty(activeLang)
		? localize[activeLang]
		: localize['lv']

	const [calendarDate, setCalendarDate] = useState(initialDate || new Date())
	const [selectedDate, setSelectedDate] = useState(null)

	const month = calendarDate.getMonth()
	const year = calendarDate.getFullYear()

	const sameYear = isSameYear(today, calendarDate)
	const title = sameYear
		? toTitleCase(monthNames[month])
		: toMonthTitle({ year, month })

	const matrix = buildMonthMatrix(
		{ date: calendarDate },
		(day, { isSameMonth }) => ({
			date: day,
			isSameMonth,
			label: format(day, 'd'),
		})
	)

	const getCellKey = id => `${id}-${month}-${year}`

	const setPrevMonth = () => {
		const newCalendarDate = addMonths(calendarDate, -1)
		updateCalendarDate(newCalendarDate)
	}

	const setNextMonth = () => {
		const newCalendarDate = addMonths(calendarDate, +1)
		updateCalendarDate(newCalendarDate)
	}

	const updateCalendarDate = newCalendarDate => {
		onChangeMonth &&
			onChangeMonth({
				start: startOfMonth(newCalendarDate),
				end: endOfMonth(newCalendarDate),
			})
		setCalendarDate(newCalendarDate)
	}

	return (
		<Container>
			<TitleGroup>
				<Title>{title}</Title>

				<Controls>
					<Button title={controlsTitle.previous} onClick={setPrevMonth}>
						<ArrowLeftIcon />
					</Button>
					<Button title={controlsTitle.next} onClick={setNextMonth}>
						<ArrowRightIcon />
					</Button>
				</Controls>
			</TitleGroup>

			<WeekDays>
				{dayNames.narrow.map((name, i) => (
					<abbr key={name} title={dayNames.wide[i]}>
						{name}
					</abbr>
				))}
			</WeekDays>

			<Grid>
				{matrix.map(week =>
					week.map(day => {
						const { date, isSameMonth, label } = day
						const hasEvent = hasEventAtDay && hasEventAtDay(date)
						const isSelected = isSameDay(date, selectedDate)
						const isToday = isSameDay(date, today)

						// FIXME: Šobrīd jauna funkcija katrai dienai
						const selectDateHandler = disableDateSelection
							? () => {}
							: () => {
									if (isSelected) {
										setSelectedDate(null)
										updateCalendarDate(date)
									} else {
										setSelectedDate(date)
										if (!isSameMonth) {
											const diff = differenceInCalendarMonths(
												date,
												calendarDate
											)
											if (diff < 0) setPrevMonth()
											if (diff > 0) setNextMonth()
										}
										onSelectDate && onSelectDate(date)
									}
							  }

						return (
							<Cell
								key={getCellKey(label)}
								hasEvent={hasEvent}
								notSameMonth={!isSameMonth}
								isSelected={isSelected}
								isToday={isToday}
								onClick={selectDateHandler}
							>
								{label}
							</Cell>
						)
					})
				)}
			</Grid>
		</Container>
	)
}

export default Calendar
