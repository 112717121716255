import styled from 'styled-components'
import { rem, em } from 'polished'
import { color } from 'core/theme'
import { breakpoint } from 'core/layout'

export const Form = styled.form`
  margin-top: 1rem;

  ${breakpoint.tablet`
    margin-top: 2rem;
  `};

  > * + * {
    margin-top: 1rem;

    ${breakpoint.tablet`
      margin-top: 1.2rem;
    `};
  }

  label {
    color: ${color.text.default};
    font-size: ${em(14)};

    ${breakpoint.tablet`
      font-size: 1em;
    `};
  }

  label + input,
  label + textarea {
    display: block;
    margin-top: 0.25em;
  }

  textarea {
    resize: none;
  }

  > div > input[type='email'],
  > div > input[type='text'],
  > div > textarea {
    background-color: transparent;
    border: 1px solid ${color.border};
    border-radius: ${rem(2)};
    color: ${color.text.default};
    font-size: ${em(14)};
    padding: 0.5em 0.25em;
    width: 100%;

    &:focus {
      background-color: ${color.surface};
    }

    ${breakpoint.tablet`
      font-size: 1em;
      padding: 0.75em 0.5em;
    `};
  }

  input + label {
    display: inline-block;
    margin: 0.5em 0;
    vertical-align: top;
    transform: translateY(-${rem(2)});
  }

  fieldset {
    border: none;
    margin-left: 0;
    margin-right: 0;
    margin-top: 1rem;
    padding: 0;

    legend {
      color: ${color.text.default};
      display: block;
      font-size: ${em(14)};
      margin-bottom: 0.25em;
    }

    ${breakpoint.tablet`
      margin-top: 1.5rem;

      legend {
        font-size: 1em;
      }
    `};
  }

  fieldset + * {
    margin-top: 1rem;

    ${breakpoint.tablet`
      margin-top: 1.5rem;
    `};
  }

  a {
    color: ${color.primary.default};
    display: inline-flex;
    font-size: ${em(14)};
    align-items: center;
    margin-bottom: 0.5em;
    text-decoration: none;

    > span {
      margin-right: 0.25em;
    }

    &:hover {
      text-decoration: underline;
    }

    ${breakpoint.tablet`
      font-size: 1em;
    `};
  }

  input[type='submit'] {
    margin-top: 1.5rem;

    ${breakpoint.tablet`
      margin-top: 2rem;
    `};
  }
`
