import styled from 'styled-components'
import { breakpoint } from 'core/layout'
import { isPrintMedia } from 'core/theme'

export const FlexLayout = styled.div`
  display: flex;
  flex-direction: column;
  order: 2;

  /* ShareActions */
  > div {
    flex-direction: row;
    margin-top: 0;
  }

  > aside {
    flex: 0 1 auto;
  }

  > figure {
    flex: 1 1 auto;
    margin-top: 1rem;
  }

  ${breakpoint.tablet`
    flex-direction: row-reverse;
    margin-bottom: 1rem;

    /* ShareActions */
    > div {
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      margin-top: 1rem;
    }

    > aside {
      margin: 0 1rem;
    }

    > figure {
      margin-top: 0;
    }
  `};

  ${breakpoint.laptop`
    > aside {
      flex: 1 1 auto;
    }

    > figure {
      flex: 2 1 auto;
      min-width: 480px;
    }
  `};

  ${isPrintMedia`
    > aside {
      margin: 0;

      > div:first-child {
        padding-left: 0;

        &::before {
          display: none;
        }
      }
    }

    > figure {
      min-width: none;
      max-width: 240pt;
    }
  `};
`
