import styled, { css } from 'styled-components'
import { em, rem } from 'polished'
import { color, easing } from 'core/theme'

// FIXME: Miglaino bilžu problēma (filter?, transform?),
// ja apstiprinās, varam dzēst pilnībā.

export const MediaWrapper = styled.div`
  background-color: ${color.border};
  border-top-left-radius: ${rem(4)};
  border-top-right-radius: ${rem(4)};
  overflow: hidden;

  /* Noslēpj img scale 1px rounding lēkāšanu */
  > * {
    margin: -1px;
  }

  img {
    width: 100%;

    /* transform: scale(1);
    filter: brightness(1);
    will-change: transform, filter;
    transition-property: transform, filter;
    transition-duration: 600ms;
    transition-timing-function: ${easing.inOut}; */

    @supports (object-fit: cover) {
      height: 100%;
      object-fit: cover;
    }
  }
`

export const ContentWrapper = styled.div`
  padding: 1em 1.5em 1.5em;

  order: 1;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h2`
  color: ${color.text.default};
  font-size: ${em(20)};
  font-weight: 600;
  line-height: ${24 / 20};
  margin: ${em(4, 20)} 0 ${em(8, 20)};

  will-change: color;
  transition: color 200ms ${easing.out};

  > a {
    color: inherit;
    display: inline-block;
    margin: -${em(8, 20)};
    padding: ${em(8, 20)};
    outline-offset: -${em(4, 20)};
    text-decoration: none;
  }
`

export const Text = styled.p`
  color: ${color.text.default};
  margin-top: 0;
  margin-bottom: 0.5;
`

export const Actions = styled.div`
  color: ${color.primary.default};
  display: flex;
  justify-content: flex-end;
  margin: auto -1em -1em -1em;

  button {
    border-radius: ${rem(4)};
    display: block;
    outline-offset: -${em(4, 20)};
    padding: 1em;
  }
`

// FIXME: Miglaino bilžu problēma (filter?, transform?),
// ja apstiprinās, varam dzēst pilnībā.

const hoverStyle = css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);

  /* ${MediaWrapper} img {
    transform: scale(1.05);
    filter: brightness(1.1);
  } */

  ${Title} {
    color: ${color.black};
  }
`

export const StyledCard = styled.div`
  background-color: ${color.surface};
  border-radius: ${rem(4)};

  display: flex;
  flex-direction: column;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 3px 4px 0 rgba(0, 0, 0, 0.06),
    0 1px 5px 0 rgba(0, 0, 0, 0.1);

  will-change: box-shadow, transform;
  transition: box-shadow 200ms ${easing.out}, transform 500ms ${easing.inOut};

  &:hover {
    ${hoverStyle};
  }

  &:focus-within {
    ${hoverStyle};
  }
`
