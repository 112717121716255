import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { color, isPrintMedia } from 'core/theme'

export const DateTag = styled.div`
  flex: 0 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;

  border: 1px solid ${color.primary.default};
  color: ${color.primary.default};
  font-size: 1rem;
  font-weight: 600;
  line-height: ${24 / 16};
  margin-right: 0.75rem;
  text-align: center;
  text-transform: uppercase;
  width: 5.5rem;
  min-height: 5rem;

  p {
    margin: 0;
  }

  ${isPrintMedia`
    border-color: ${color.border};
    color: ${color.printBlack};
  `};
`

export const ContentWrapper = styled.div`
  flex: 1;

  display: flex;
  min-height: 5rem;
  padding: 0.75rem;

  > div {
    flex: 0;

    &:first-child {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &:last-child {
      flex: 0 0 auto;
    }
  }

  h2 {
    display: inline-block;
    margin-bottom: 0.25em;
    font-weight: 600;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  small {
    margin: 0;
  }
`

export const ApplyWrapper = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  button {
    font-size: ${rem(12)};
    padding: 1rem;
    text-transform: uppercase;
    transform: translate(0.75rem, -0.75rem);
    display: flex;
  }
`

export const StyledEventEntry = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 1rem;

  ${({ isHighlighted }) =>
    isHighlighted
      ? css`
          ${ContentWrapper} {
            background: ${color.gradient.blueTransparent};
          }

          ${DateTag} {
            position: relative;

            &::before {
              content: '';
              display: block;
              width: ${rem(4)};
              background: ${color.gradient.blue};
              position: absolute;
              top: -1px;
              bottom: -1px;
              left: -1px;
            }
          }

          ${isPrintMedia`
            ${ContentWrapper} {
              background: ${color.background};
              border: 2px solid ${color.border};
            }

            ${DateTag} {
              border-width: 2px;

              &::before {
                display: none;
              }
            }
          `};
        `
      : css`
          ${ContentWrapper} {
            border-bottom: 1px solid ${color.border};
          }
        `};
`
