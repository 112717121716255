import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { IntersectionRoot, IntersectionNode } from 'core/layout'
import { Swiper } from 'modules'

const MEDIA_QUERY = gql`
	query MediaQuery($ids: [Int!]!) {
		mediaByIds(ids: $ids) {
			id
			slug
			caption
			altText
			sizes {
				sizeId
				url
				width
			}
			url
		}
	}
`

export default function Gallery(props) {
	const { data, sizes } = props
	const { mediaIds } = data

	if (!mediaIds || mediaIds.length === 0) {
		return null
	}

	return (
		<IntersectionRoot
			render={observer => (
				<>
					<IntersectionNode observer={observer}>
						<Query
							query={MEDIA_QUERY}
							variables={{
								ids: [...mediaIds],
							}}
						>
							{({ error, loading, data }) => {
								if (error || loading) return null

								const { mediaByIds: media } = data

								return (
									<div>
										<Swiper slides={media.reverse()} sizes={sizes} />
									</div>
								)
							}}
						</Query>
					</IntersectionNode>
				</>
			)}
		/>
	)
}
