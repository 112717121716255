import React from 'react'
import { StyledLink } from './styled'

const ExternalLink = ({ to, download, children, title }) => {
	return (
		<StyledLink
			href={to}
			external="true"
			download={download}
			rel="noopener"
			target="_blank"
			title={title}
		>
			{children}
		</StyledLink>
	)
}

export default ExternalLink
