import format from 'date-fns/format'
import compareAsc from 'date-fns/compareAsc'
import en from 'date-fns/locale/en-GB'
import lv from './locale/lv'

const formatDate = (date, formatStr, locale) => {
  return format(new Date(date.replace(' ', 'T')), formatStr, {
    locale: locale === 'lv' ? lv : en,
    awareOfUnicodeTokens: true,
  })
}

export const getHumanDate = (date, lang, options = {}) => {
  const { short, customFormat } = options
  if (customFormat) {
    return formatDate(date, customFormat, lang)
  } else if (short) {
    return formatDate(date, 'dd.MM.yyyy', lang)
  }
  return formatDate(date, 'd. MMMM, yyyy', lang)
}

const getEventStartDate = event => {
  const dateString =
    event.acf &&
    event.acf.date_time &&
    event.acf.date_time.length &&
    event.acf.date_time[0].date
  return dateString && new Date(dateString)
}

export const sortEventsAsc = events => {
  events.sort((a, b) => {
    const dateA = getEventStartDate(a)
    const dateB = getEventStartDate(b)
    if (dateA && dateB) {
      return compareAsc(dateA, dateB)
    }
    return 0
  })
}
