import styled, { css } from 'styled-components'
import { color, easing, isPrintMedia } from 'core/theme'
import { breakpoint } from 'core/layout'

export const Scrim = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.4);

  will-change: opacity;
  opacity: 0;
  transition: opacity 400ms ${easing.standard};
`

export const Container = styled.div`
  background-color: ${color.surface};
  padding: 0.5rem;
  height: 100%;

  will-change: transform, box-shadow;
  transform: translateX(-105%);
  box-shadow: 0 0 0 0 rgba(60, 64, 67, 0), 0 0 0 0 rgba(60, 64, 67, 0);
  transition-property: transform, box-shadow;
  transition-duration: 200ms;
  transition-timing-function: ${easing.standard};

  ${breakpoint.mobile`
    transition-duration: 400ms;
  `};
`

export const StyledDrawer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 101;

  pointer-events: none;

  ${({ isOpen }) =>
    isOpen &&
    css`
      pointer-events: auto;

      ${Scrim} {
        opacity: 1;
      }

      ${Container} {
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
          0 1px 3px 1px rgba(60, 64, 67, 0.15);
        transform: translateX(0);
      }
    `};

  ${isPrintMedia`
    display: none;
  `};
`
