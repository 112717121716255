import styled, { css } from 'styled-components'
import { em, rem, transparentize } from 'polished'
import { NavLink as StyledNavLink } from 'react-router-dom'
import { plainList, color, themeMode } from 'core/theme'

export const Nav = styled.nav`
  margin: 1em ${em(27)};
`

export const List = styled.ul`
  ${plainList};

  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
  justify-content: center;

  ${({ asColumn }) =>
    asColumn &&
    css`
      flex-direction: column;
    `};
`

export const Item = styled.li`
  margin: 0 0.25em;
`

export const Label = styled.span`
  border-radius: ${rem(2)};
  font-size: ${em(12)};
  padding: ${em(6, 12)} ${em(16, 12)} ${em(5, 12)};
  pointer-events: none;
  text-transform: uppercase;
`

export const NavLink = styled(StyledNavLink)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${color.text.label};
  height: ${em(36)};
  text-decoration: none;

  ${({ theme }) =>
    theme.mode === themeMode.contrast
      ? css`
          &:hover {
            ${Label} {
              text-decoration: underline;
            }
          }
        `
      : css`
          &:hover {
            ${Label} {
              background-color: ${props => {
                const labelProps = props.children.props
                return transparentize(
                  labelProps.isOnSurface ? 0.88 : 0.92,
                  color.text.label(props)
                )
              }};
              color: ${color.text.dark};
            }
          }
        `};

  &.active {
    ${Label} {
      background-color: ${color.tertiary.default};
      color: ${color.onPrimary};
    }
  }

  &.focus-visible {
    outline: none;

    ${Label} {
      outline-offset: 2px;
      outline: 2px solid ${color.outline};
    }
  }
`
