import styled from 'styled-components'
import { em } from 'polished'

export const Nav = styled.nav`
  margin: 1em ${em(27)};
`

export const Group = styled.div`
  display: flex;
  align-items: center;
`

export const TopRightPosition = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`
