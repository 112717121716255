import React, {useState} from 'react'
import {StyledModal, StyledButton, StyledCheckbox, StyledInput} from './styled'
import gql from "graphql-tag";
import {Mutation, Query} from "react-apollo";
import {Form} from "../MailigenForm/styled";
import {Transl} from "core/translations";
import {ReactComponent as Close} from './assets/close.svg';


const SURVEY_QUERY = gql`
	query SurveysQuery($lang: String!) {
		surveys(lang: $lang)
	}
`

const SUBMIT_FORM = gql`
	mutation submitSurvey($data: String!) {
		submitSurvey(data: $data)
	}
`

const getLabelEl = ({
						label,
						htmlFor,
						required = false,
						type: El = 'label',
					}) => {
	const props = El === 'label' ? {htmlFor} : {}
	return (
		<El {...props}>
			{required && <sup>{'* '}</sup>}
			{label}
		</El>
	)
}

const SurveyModal = ({activeLang}) => {
	const [show, setshow] = useState(true);
	const hideModal = (id, show = true) => {
		setshow(false)
		sessionStorage.setItem('showSurvey-' + id, false)
	};

	return (
		<Query
			query={SURVEY_QUERY}
			variables={{
				lang: activeLang,
			}}
		>
			{({loading, error, data}) => {
				if (loading || !data) return (<></>)
				var surveyData = data.surveys.data;
				var survey = surveyData.survey[0];

				if (survey == null) {
					return (<></>)
				}

				var image = surveyData.survey.image
				let showSurvey = sessionStorage.getItem('showSurvey-' + survey.pID);

				if (!localStorage.getItem('survey-' + survey.pID) && (showSurvey === null || showSurvey === 'true') && show) {
					return (
						<Mutation mutation={SUBMIT_FORM}>
							{(submitForm, {loading, error, data}) => {
								if (data && data.submitSurvey) {
									localStorage.setItem('survey-' + survey.pID, true)
									return (<></>)
								}

								return (
									<>
										{surveyData ? (
											<StyledModal>
												<div className={'modal-content'}>
													<img className={'modal-image'} src={image} alt="Aptaujas attēls"/>
													<div className={'modal-close'} onClick={() => {
														hideModal(survey.pID)
													}}>
														<Close/>
													</div>
													<div className={'modal-left-content'}>
														<div className={'modal-title'}><Transl>APTAUJA</Transl></div>
														<div className={'modal-description'}>
															{survey.pName}
														</div>

														<Form
															id='survey-form'
															onSubmit={event => {
																event.preventDefault()
																const form = event.target
																const data = new FormData(form)
																const query = new URLSearchParams(data)
																submitForm({
																	variables: {
																		data: query.toString(),
																	}
																})
															}}
														>
															{surveyData.questions.map((qField) => {
																const {qID, question, answers} = qField
																return (
																	<div className={'modal-question-wrap'}
																		 key={'question' + qID}>
																		<div className={'modal-question'}>
																			{question}
																		</div>
																		<input type="hidden"
																			   name={'question[' + qID + '][question]'}
																			   value={qID}/>
																		<input type="hidden" name={'postID'}
																			   value={survey.pID}/>
																		{answers.map((aField) => {
																			const {aID, answer} = aField

																			if (answer.length) {
																				return (
																					<div key={'key' + aID}>
																						<StyledCheckbox
																							name={'question[' + qID + '][answer]'}
																							required
																							value={aID}
																						/>
																						{getLabelEl({
																							htmlFor: 'answer' + aID,
																							label: answer,
																						})}
																					</div>
																				)
																			} else {
																				return (
																					<div key={'key' + aID}>
																						<StyledInput
																							name={'question[' + qID + '][answer]'}
																							maxlength="36"
																							required
																						/>
																						{getLabelEl({
																							htmlFor: 'answer' + aID,
																							label: answer,
																						})}
																					</div>
																				)
																			}

																		})}
																	</div>
																)
															})}
															<StyledButton key={'submit'}>
																<Transl>IESNIEGT ATBILDES</Transl>
															</StyledButton>
														</Form>
													</div>
												</div>
											</StyledModal>

										) : null}
									</>
								)
							}}
						</Mutation>
					)
				} else {
					return (<></>)
				}
			}}
		</Query>
	)
}

export default SurveyModal
