import { matchPath } from 'react-router'
import { toMedialCapitals } from 'utils'

// FIXME: Nav uzskatāma redirektēšanas loģika atšķirīgās situācijās
export const getActiveLangStateFromProps = (props, state) => {
  const { location, languages } = props
  const { activeLang, needRedirection } = state

  const matchLang = languages.find((lang) =>
    matchPath(location.pathname, {
      path: `/${lang.slug}`,
      strict: false,
    })
  )

  if (matchLang && matchLang.slug !== activeLang) {
    return {
      ...state,
      activeLang: matchLang.slug,
    }
  } else if (needRedirection) {
    const defaultLang = languages.find((lang) => lang.isDefaultLang)
    if (defaultLang) {
      return {
        ...state,
        activeLang: defaultLang.slug,
      }
    }
  }

  return null
}

export const getTemplateName = (pageObject, pageOnFront, pageForPosts) => {
  const { id, slug, template } = pageObject

  if (pageOnFront && pageOnFront === id) {
    return 'SakumaLapa'
  }
  if (pageForPosts && pageForPosts === id) {
    return 'ZinuLapa'
  }

  if (template) {
    return toMedialCapitals(`${template}-template`)
  }

  switch (slug) {
    case 'pasakumi':
    case 'events':
      return 'Pasakumi'
    case 'iedvesmas-stasti':
    case 'inspirational-stories':
      return 'IedvesmasStasti'
    case 'atbalsts':
    case 'support':
      return 'Atbalsts'
    case 'tehnologiju-skauti':
    case 'tech-scouts':
      return 'TehnologijuSkauti'
    case 'resursi':
    case 'resources':
      return 'Resursi'
    case 'kontakti':
    case 'contacts':
      return 'Kontakti'
    case 'sikdatnu-politika':
    case 'cookies-policy':
      return 'PrivatumaPolitika'
    case 'vietnes-pieklustamiba':
    case 'site-accessibility':
      return 'VietnesPieklustamiba'
    case 'lapas-karte':
    case 'site-map':
      return 'LapasKarte'
    default:
      return slug && toMedialCapitals(slug)
  }
}

export const getFilterParams = (location) => {
  const { search } = location || {}
  let date = []
  let tags = []
  if (search) {
    const searchParams = new URLSearchParams(search)
    date = searchParams.getAll('date[]')
    tags = searchParams.getAll('tags[]')
  }
  return { date, tags }
}

const formSlugs = {
  event: {
    lv: 'pieteikuma-forma',
    en: 'application-form',
  },
  contact: {
    lv: 'sazinas-forma',
    en: 'contact-form',
  },
}

export const getFormUrl = (lang, url, type) => {
  const slug =
    formSlugs[type] && formSlugs[type][lang]
      ? formSlugs[type][lang]
      : formSlugs.contact.lv

  if (url.endsWith(slug)) {
    return url
  }

  return `${url}/${slug}`
}

export const mapDefaultPostUrl = (
  postType,
  activeLang,
  isDefaultLang,
  pageUrl,
  url
) => {
  const isDefaultPostType = postType === 'post' || postType === 'posts'
  if (isDefaultPostType) {
    let postUrl = `${url}`
    if (!isDefaultLang) {
      postUrl = postUrl.replace(`/${activeLang}`, '')
    }
    return `${pageUrl}${postUrl}`
  }
  return url
}
