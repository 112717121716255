import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { color } from 'core/theme'

export const StyledTag = styled(Link)`
  color: ${color.primary.variant.dark};
  letter-spacing: 0.035em;
  padding: 0.5em;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
`
