import styled from 'styled-components'
import { em, rem } from 'polished'
import { easing } from 'core/theme'
import { breakpoint } from 'core/layout'
import checkmark from './assets/checkmark.png';

export const StyledModal = styled.div`
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #f4f6fa;
    background-color: rgba(218,221,227,0.8);
    z-index: 101;
    visibility: hidden;

    animation: 250ms ease-out 5s 1 fade forwards;

    .modal-image {
      position: absolute;
      max-width: 520px;
      height: 360px;
      right: 0;
      top: 52px;
      display: none;
    }

    .modal-close {
      width: 30px;Q
      height: 30px;
      background-size: cover;
      right: 0;
      position: absolute;
      top: -50px;
    }
    .modal-close:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    .modal-content {
      /* max-width: 680px; */
      max-width: 1000px;
      position: absolute;
      left: 15px;
      right: 15px;

      animation: 600ms ease-out 5s 1 slideIn forwards;
    }

    .modal-left-content {
      background-color: #ffffff;
      padding: 20px;
    }

    .modal-title {
      color: #0c7d63;
      font-size: 14px;
      font-weight: bold;
    }

    .modal-description {
      color: #000000;
      font-size: 12px;
      line-height: 1.29;
      margin: 10px 0 20px;
    }

    .modal-question-wrap {
      margin-bottom: 20px;
    }

    .modal-question {
      font-size: 19px;
      font-weight: bold;
      line-height: 1.21;
      text-align: left;
      margin: 20px 0;
    }

    @keyframes fade {
      0% { opacity: 0; visibility: visible; }
      100% { opacity: 1; visibility: visible; }
    }

    @keyframes slideIn {
      0% { top: -1000px; opacity: 0; }
      100% { top: 85px; opacity: 1; }
    }

    .modal-answer {
      color: #000000;
      line-height: 1.33;
      text-align: left;
      font-size: 12px;
      margin: 22px 0;
      margin-bottom: 15px;
    }

    form {
      margin-top: 0;
    }

    .submit-button {
      color: #ff4879;
    }

    label {
      color: #000000;
      font-size: 18px;
      line-height: 1.33;
      text-align: left;
    }

    ${breakpoint.mobile`
      .modal-content {
        left: 50px;
        right: 50px;
      }
    `};
    ${breakpoint.tablet`
      .modal-close {
        width: 38px;
        height: 38px;
      }
      .modal-image {
        display: block;
        width: 420px;
      }
      .modal-left-content {
        padding: 38px 205px 36px 37px
        max-width: 70%
      }
      .modal-answer{
        font-size: 18px;
      }
      .modal-question {
        font-size: 24px;
      }
      .modal-description{
        font-size: 18px;
      }
      .modal-close {
        top: 0;
      }
    `};
    ${breakpoint.laptop`
      .modal-content {
        position: relative;
        width: 80%;
        margin: 15% auto;
      }
      .modal-left-content {
        padding-right: 260px;
      }

      .modal-image {
        width: 520px;
      }
    `};
`

export const StyledButton = styled.button.attrs({
  type: 'submit',
})`
  border: ${rem(2)} solid #FF2A68;
  border-radius: 1.5rem;
  color: #FF2A68;
  font-size: ${em(14)};
  font-weight: 600;
  margin: 1em 0.5em;
  padding: ${em(12, 13)} ${em(24, 12)} ${em(11, 13)};
  text-transform: uppercase;
  background-color: #ffffff;
  display: block;
  margin: 0 auto;

  will-change: background-color, color;
  transition-property: background-color, color;
  transition-duration: 100ms;
  transition-timing-function: ${easing.out};

  &:hover {
    background-color: #FF2A68;
    color: #ffffff;
  }

  cursor: pointer;

  ${breakpoint.tablet`
    display: inline-block;
  `};
`

export const StyledCheckbox = styled.input.attrs({
  type: 'radio',
})`
  -webkit-appearance: none;

  border: 2px solid #4CDCBA;
  border-radius: ${rem(2)};
  width: 24px;
  height: 24px;
  margin: 0.5em;
  cursor: pointer;
  position: relative;
  margin-right: 10px;

  &:checked {
    background-color: #4CDCBA;
    overflow: hidden;
    transition: 100ms linear;

    &::after {
      content: url(${checkmark});
      color: #ffffff;
      font: inherit;
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      text-align: center;
      transform: translate(0.05rem, 0.15rem);
    }
  }
`

export const StyledInput = styled.input.attrs({
  type: 'text',
})`
  -webkit-appearance: none;

  background-color: transparent;
  border: 2px solid #4CDCBA;
  border-radius: 6px;
  color: rgb(72, 72, 72);
  font: inherit;
  padding: 0.5em 1em;
  width: 100%;

  &:focus {
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(151, 151, 151);
    outline: none;
  }

`
