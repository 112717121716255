import styled, { css } from 'styled-components'
import { breakpoint } from 'core/layout'
import { ghostButton, color, themeMode } from 'core/theme'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  padding: 1.5rem 0 0.5rem;

  ${breakpoint.laptop`
    margin-top: 1rem;
  `};
`

export const StyledButton = styled.button.attrs({
  type: 'button',
})`
  ${ghostButton};

  font-size: 1rem;
  margin: -1em;
  padding: 1em;

  svg {
    color: ${color.text.variant.label};
    width: 1.5em;
    height: 1.5em;
  }

  ${({ theme }) =>
    theme.mode === themeMode.default &&
    css`
      svg {
        opacity: 0.35;
      }

      &:hover {
        svg {
          opacity: 1;
        }
      }
    `};
`
