import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { Button } from 'elements'
import { color } from 'core/theme'
import { breakpoint } from 'core/layout'

export const StyledSearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
`

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;

  ${breakpoint.tablet`
    max-width: ${rem(424)};
    margin-right: 1rem;
  `};
`

export const CloseButton = styled(Button)`
  border-radius: ${rem(4)};
  padding: 0.75em;
`

export const SearchInput = styled.input.attrs({
  type: 'search',
})`
  background-color: transparent;
  border: 2px solid #979797;
  border-radius: 1.5rem;
  color: ${color.text.default};
  font: inherit;
  padding: 0.5em 1em;
  width: 100%;

  /* hide clear button */
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    background: ${color.surface};
  }

  ${({ notEmpty }) =>
    notEmpty &&
    css`
      background-color: ${color.surface};
    `};

  &:focus {
    background-color: ${color.surface};
    border: 2px solid ${color.primary.default};
    outline: none;
  }
`
