import React from 'react'
import { withRouter, matchPath } from 'react-router'
import { Link } from 'react-router-dom'
import { AspectRatio } from 'core/components'
import { Transl } from 'core/translations'
import { siteWidth } from 'core/layout'
import { ResponsiveImg, Tag, TagsGroup, Button } from 'elements'
import { VideoIcon } from 'core/icons'
import { toImageSrc } from 'utils/media'
import { replaceEntities } from 'utils/entities'
import {
	StyledCard,
	MediaWrapper,
	ContentWrapper,
	Title,
	Text,
	Actions,
} from './styled'

const getIconById = id => {
	switch (id) {
		case 'video':
			return <VideoIcon />
		default:
			return null
	}
}

const ActionButton = ({ onClick, children }) => (
	<Transl id="Doties uz rakstu">
		{translation => (
			<Button primary title={translation} onClick={onClick}>
				{children}
			</Button>
		)}
	</Transl>
)

const ArticleCard = ({
	url,
	title: rawTitle,
	tags,
	text,
	image,
	icon,
	history,
}) => {
	const navigateProps = {
		pathname: url,
	}

	const navigateTo = () => {
		history.push(navigateProps)
	}

	const { params: urlParams } = matchPath(url, {
		path: '/:lang?/:page/:post',
		exact: true,
		strict: false,
	})

	const title = replaceEntities(rawTitle)

	return (
		<StyledCard>
			{/* NOTE: Pieejamības uzlabošanai satura bloks ir pirmais,
        lai aktualizētu saistošo informāciju. Vizuāli bloki tiek
        mainīti vietām izmantojot Flexbox order. */}
			<ContentWrapper>
				<TagsGroup>
					{tags &&
						tags
							.filter(tag => !!tag)
							.map(({ name, slug }) => (
								<Tag key={slug} urlParams={urlParams} slug={slug}>
									{name}
								</Tag>
							))}
				</TagsGroup>

				<Title>
					<Link to={navigateProps}>{title}</Link>
				</Title>

				{text && <Text>{text}</Text>}

				{icon && (
					<Actions>
						<ActionButton onClick={navigateTo}>
							{getIconById(icon)}
						</ActionButton>
					</Actions>
				)}
			</ContentWrapper>

			<MediaWrapper onClick={navigateTo}>
				<AspectRatio>
					{image && (
						<ResponsiveImg
							sizes={`(min-width: ${siteWidth}px) 411px, (min-width: 917px) ${(1 /
								3) *
								100}vw, (min-width: 633px) 50vw, 100vw`}
							{...image}
						/>
					)}
				</AspectRatio>
			</MediaWrapper>
		</StyledCard>
	)
}

ArticleCard.minWidth = 268

ArticleCard.mapPostToCard = ({
	id,
	slug,
	url,
	tags,
	title,
	featuredMedia,
}) => ({
	id,
	slug,
	url,
	tags,
	title,
	icon: 'video',
	image: toImageSrc(featuredMedia) || null,
})

export default withRouter(ArticleCard)
