import React from 'react'
import { Transl } from 'core/translations'
import { HiddenAbove, HiddenBellow } from 'core/layout'
import { VisuallyHidden } from 'core/components'
import { MenuIcon } from 'core/icons'
import { ToggleButton, LanguageSwitch, AccessibilityMenus } from 'elements'
import { ReactComponent as SearchIcon } from './assets/baseline-search-24px.svg'
import { Nav, Group } from './styled'

const ActionItems = ({
	onSwitchLanguage,
	onToggleThemeMode,
	onToggleFontSize,
	onFocusSearch,
	onToggleMenu,
}) => {
	return (
		<Nav aria-labelledby="action-items">
			<VisuallyHidden>
				<p id="action-items">
					<Transl>Darbības elementi</Transl>
				</p>
			</VisuallyHidden>

			<Group>
				<Transl id="Meklētājs">
					{translation => (
						<ToggleButton primary title={translation} onClick={onFocusSearch}>
							<SearchIcon />
						</ToggleButton>
					)}
				</Transl>
				<HiddenAbove media="mainBreak">
					<Transl id="Izvēlne">
						{translation => (
							<ToggleButton title={translation} onClick={onToggleMenu}>
								<MenuIcon medium />
							</ToggleButton>
						)}
					</Transl>
				</HiddenAbove>
				<HiddenBellow media="mainBreak">
					<LanguageSwitch onSwitchLanguage={onSwitchLanguage} />
				</HiddenBellow>
			</Group>

			<HiddenBellow media="mainBreak">
				<AccessibilityMenus
					onToggleFontSize={onToggleFontSize}
					onToggleThemeMode={onToggleThemeMode}
				/>
			</HiddenBellow>
		</Nav>
	)
}

export default ActionItems
