import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Query, Mutation } from 'react-apollo'
import {
	Loader,
	ErrorMessage,
	Button,
	Headline,
	Checkbox,
	Radio,
	SubmitButton,
} from 'elements'
import { Transl } from 'core/translations'
import { AttachmentIcon } from 'core/icons'
import { Form } from './styled'

const FORM_QUERY = gql`
	query FormQuery($id: ID!) {
		forms(id: $id) {
			id
			name
			fields {
				id
				label
				type
				options {
					label
					value
				}
				isRequired
			}
		}
	}
`

const SUBMIT_FORM = gql`
	mutation SubmitForm($id: ID!, $data: String!) {
		submitForm(id: $id, data: $data)
	}
`

const getLabelEl = ({
	label,
	htmlFor,
	required = false,
	type: El = 'label',
}) => {
	const props = El === 'label' ? { htmlFor } : {}
	return (
		<El {...props}>
			{required && <sup>{'* '}</sup>}
			{label}
		</El>
	)
}

export default function MailigenForm(props) {
	const { id, type, dataSafetyFile, returnUrl, history } = props

	const [submitDisabled, setSubmitDisabled] = useState(
		dataSafetyFile !== undefined
	)

	const buttonStyle = {
		padding: '0.5rem 0.75rem',
		transform: 'translateX(-0.75rem)',
	}

	const returnButton = (
		<Button
			secondary
			style={buttonStyle}
			onClick={() => {
				history.push({
					pathname: returnUrl,
				})
			}}
		>
			<Transl>Atgriezties</Transl>
		</Button>
	)

	return (
		<Query
			query={FORM_QUERY}
			variables={{
				id,
			}}
		>
			{({ loading, error, data }) => {
				if (loading) return <Loader />
				if (error) return <ErrorMessage message={error.message} />

				const {
					forms: { id, fields },
				} = data
				return (
					<Mutation mutation={SUBMIT_FORM}>
						{(submitForm, { loading, error, data }) => {
							if (loading) return <Loader />
							if (error) return <ErrorMessage message={error.message} />

							if (data && data.submitForm) {
								if (data.submitForm.startsWith('ERROR')) {
									return (
										<>
											<Headline level={2}>
												<Transl>Notikusi kļūda, mēģini vēlreiz</Transl>
												{'! '}
											</Headline>
											{returnButton}
										</>
									)
								}
								return (
									<>
										<Headline level={2}>
											{type === 'event' ? (
												<Transl>Paldies, pieteikums saņemts</Transl>
											) : (
												<Transl>Paldies, ziņa saņemta</Transl>
											)}
											{'! '}
										</Headline>
										{returnButton}
									</>
								)
							}

							return (
								<>
									<Headline level={2}>
										{type === 'event' ? (
											<Transl>Pieteikuma forma</Transl>
										) : (
											<Transl>Saziņas forma</Transl>
										)}
									</Headline>

									<Form
										id={id}
										onSubmit={event => {
											event.preventDefault()
											const form = event.target
											const data = new FormData(form)
											const query = new URLSearchParams(data)
											submitForm({
												variables: {
													id,
													data: query.toString(),
												},
											})
										}}
									>
										{fields.map(field => {
											const { id, label, type, isRequired, options } = field

											if (type === 'text') {
												return (
													<div key={id}>
														{getLabelEl({
															htmlFor: id,
															label,
															required: isRequired,
														})}
														<input
															id={id}
															name={id}
															type={type}
															required={isRequired}
														/>
													</div>
												)
											}

											if (type === 'textarea') {
												return (
													<div key={id}>
														{getLabelEl({
															htmlFor: id,
															label,
															required: isRequired,
														})}
														<textarea
															id={id}
															name={id}
															required={isRequired}
															rows={5}
														/>
													</div>
												)
											}

											if (type === 'email') {
												return (
													<div key={id}>
														{getLabelEl({
															htmlFor: id,
															label,
															required: isRequired,
														})}
														<input
															id={id}
															name={id}
															type={type}
															required={isRequired}
														/>
													</div>
												)
											}

											if (type === 'checkbox' || type === 'radio') {
												return (
													<fieldset key={id}>
														{getLabelEl({
															label,
															type: 'legend',
															required: isRequired,
														})}
														{options.map(
															(
																{ label: optionLabel, value: optionValue },
																i
															) => {
																const key = `${id}_${i + 1}`
																return (
																	<div key={key}>
																		{type === 'checkbox' && (
																			<Checkbox
																				id={key}
																				name={id}
																				value={optionLabel}
																			/>
																		)}
																		{type === 'radio' && (
																			<Radio
																				id={key}
																				name={id}
																				value={optionLabel}
																			/>
																		)}
																		{getLabelEl({
																			htmlFor: key,
																			label: optionLabel,
																		})}
																	</div>
																)
															}
														)}
													</fieldset>
												)
											}

											return null
										})}

										{dataSafetyFile && (
											<div>
												<div>
													<a
														href={dataSafetyFile}
														target="_blank"
														rel="noopener"
													>
														<AttachmentIcon medium />
														<Transl>Datu apstrādes drošības noteikumi</Transl>
													</a>
												</div>
												<div>
													<Checkbox
														id="security-rules"
														onChange={event => {
															setSubmitDisabled(!event.target.checked)
														}}
													/>
													<label htmlFor="security-rules">
														<Transl>
															Esmu iepazinies ar datu apstrādes drošības
															noteikumiem
														</Transl>
													</label>
												</div>
											</div>
										)}

										<Transl id="Pieteikties">
											{translation => (
												<SubmitButton
													value={translation}
													isDisabled={submitDisabled}
												/>
											)}
										</Transl>
									</Form>
								</>
							)
						}}
					</Mutation>
				)
			}}
		</Query>
	)
}
