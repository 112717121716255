import React from 'react'
import throttle from 'lodash.throttle'

class WindowScrollPosition extends React.Component {
	state = {
		x: 0,
		y: 0,
		test: null,
	}

	componentDidMount() {
		const { throttle: wait } = this.props
		this._onScroll = throttle(this._onScroll.bind(this), wait || 100)
		window.addEventListener('scroll', this._onScroll, { passive: true })
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this._onScroll)
	}

	_onScroll() {
		const scrollPosition = {
			x: window.pageXOffset,
			y: window.pageYOffset,
		}

		const { test } = this.props
		if (test) {
			const testResults = test && test(scrollPosition)
			if (testResults !== this.state.test) {
				this.setState(prevState => ({
					...prevState,
					scrollPosition,
					test: testResults,
				}))
			}
		} else {
			this.setState(prevState => ({
				...prevState,
				scrollPosition,
				test: null,
			}))
		}
	}

	render() {
		const { children } = this.props
		return typeof children === 'function' ? children(this.state) : children
	}
}

export default WindowScrollPosition
