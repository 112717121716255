import React from 'react'
import { PrintIcon } from 'core/icons'
import { Transl } from 'core/translations'
import { HiddenInPrint } from 'core/theme'
import { Wrapper, StyledButton } from './styled'

const PrintButton = () => {
	return (
		<HiddenInPrint>
			<Wrapper>
				<Transl id="Drukāt lapu">
					{translation => (
						<StyledButton
							title={translation}
							onClick={() => {
								window.print()
							}}
						>
							<PrintIcon />
						</StyledButton>
					)}
				</Transl>
			</Wrapper>
		</HiddenInPrint>
	)
}

export default PrintButton
