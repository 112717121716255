import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { ThemeContext, GlobalStyle, themeMode, fontSize } from 'core/theme'
import Cookies from 'js-cookie'

const COOKIE_NAME = 'accessibility-preferences'
const COOKIE_EXPIRES = 365 * 2

class ThemeProvider extends React.Component {
	static contextType = ThemeContext

	constructor(props, context) {
		super(props, context)

		let themeContext = {
			...context,
		}

		let cookieValue
		try {
			cookieValue = Cookies.getJSON(COOKIE_NAME)
		} catch (error) {}
		if (cookieValue) {
			const { mode, size } = cookieValue
			if (mode && themeMode[mode]) {
				themeContext = {
					...themeContext,
					mode,
				}
			}
			if (size && fontSize[size]) {
				themeContext = {
					...themeContext,
					size,
				}
			}
		}

		this.state = {
			...themeContext,
			toggleThemeMode: this._toggleThemeMode,
			toggleFontSize: this._toggleFontSize,
		}
	}

	_toggleThemeMode = () => {
		this.setState(
			prevState => ({
				...prevState,
				mode:
					prevState.mode === themeMode.default
						? themeMode.contrast
						: themeMode.default,
			}),
			this._updateThemePrefsCookie
		)
	}

	_toggleFontSize = () => {
		this.setState(
			prevState => ({
				...prevState,
				size:
					prevState.size === fontSize.medium ? fontSize.large : fontSize.medium,
			}),
			this._updateThemePrefsCookie
		)
	}

	_updateThemePrefsCookie = () => {
		const { mode, size } = this.state
		try {
			Cookies.set(COOKIE_NAME, { mode, size }, { expires: COOKIE_EXPIRES })
		} catch (error) {}
	}

	// NOTE: Nevar ieviest 'print' theme mode, jo uzsākot drukāšanas darbus
	// (window.print, window.onbeforeprint), lapa tiek iesaldēta līdz
	// window.onafterprint un state izmaiņas netiek veiktas.

	render() {
		const { children } = this.props
		const themeProps = {
			mode: this.state.mode,
			size: this.state.size,
		}

		return (
			<ThemeContext.Provider value={this.state}>
				<StyledThemeProvider theme={themeProps}>
					<>
						<GlobalStyle {...themeProps} />
						{typeof children === 'function' ? children(this.state) : children}
					</>
				</StyledThemeProvider>
			</ThemeContext.Provider>
		)
	}
}

export default ThemeProvider
