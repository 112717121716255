import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, RatioEnforcer, ContentWrapper } from './styled'

AspectRatio.propTypes = {
	ratio: PropTypes.oneOf(['16:9', '3:2', '4:3', '1:1', '3:4', '2:3']),
	ratioFloat: PropTypes.number,
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	style: PropTypes.object,
}

AspectRatio.defaultProps = {
	ratio: '3:2',
	ratioFloat: undefined,
}

function AspectRatio({ ratio, ratioFloat, className, children, style }) {
	let enforcedRatio = ratioFloat
	if (!enforcedRatio) {
		const [w, h] = ratio.split(':')
		enforcedRatio = h / w
	}
	return (
		<Wrapper className={className} style={style}>
			<RatioEnforcer ratio={enforcedRatio}>
				<ContentWrapper>{children}</ContentWrapper>
			</RatioEnforcer>
		</Wrapper>
	)
}

export default AspectRatio
