import styled, { keyframes } from 'styled-components'
import { transparentize } from 'polished'
import { color, easing } from 'core/theme'
import { breakpoint, siteWidth } from 'core/layout'

const slideIn = keyframes`
  to {
    transform: translateY(0);
  }
`

export const StyledInfoBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 101;

  background-color: ${transparentize(0.08, color.greyOnly)};
  color: ${color.whiteOnly};

  will-change: transform;
  transform: translateY(110%);
  animation: ${slideIn} 500ms ${easing.inOut} 1s;
  animation-fill-mode: forwards;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0.75rem;

  // ${breakpoint.tablet`
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: space-between;
  //   padding: 2rem 1.5rem;
  // `};

  margin: 0 auto;
  max-width: ${siteWidth}px;
`

export const ContentGroup = styled.div`
  strong {
    font-weight: 600;
  }

  a {
    color: ${color.primary.default};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const ChoiceGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    margin-right: 1rem;
  }
`

export const ActionsGroup = styled.div`
  display: flex;
  justify-content: flex-start;

  margin-top: 1rem;

  button {
    margin: 0;
  }
  button:first-child {
    margin-right: 1rem;
  }

  // ${breakpoint.tablet`
  //   display: block;
  //   justify-content: normal;
  //
  //   margin-top: 0;
  //   margin-left: 2rem;
  // `};
`
