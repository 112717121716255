import React from 'react'
import { StyledButton } from './styled'

const SubmitButton = ({ value, onClick, isDisabled = false }) => {
	return (
		<StyledButton value={value} onClick={onClick} isDisabled={isDisabled} />
	)
}

export default SubmitButton
