import React from 'react'
import { StyledButton } from './styled'

const Button = ({ onClick, children, primary, secondary, ...props }) => {
	return (
		<StyledButton
			{...props}
			onClick={onClick}
			primary={primary}
			secondary={secondary}
		>
			{children}
		</StyledButton>
	)
}

export default Button
