import React from 'react'

export const defaultLang = 'lv'
export const availableLangs = ['lv', 'en']

const TranslationsContext = React.createContext({
  activeLang: defaultLang,
  translations: {},
})

export default TranslationsContext
