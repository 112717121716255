import React from 'react'
import { Transl } from 'core/translations'
import { TranslationsContext, availableLangs } from 'core/translations'
import { ToggleButton } from 'elements'

const LanguageSwitch = ({ onSwitchLanguage }) => (
	<TranslationsContext.Consumer>
		{({ activeLang }) => {
			const switchLang = availableLangs.find(lang => lang !== activeLang)
			return (
				<Transl
					id={switchLang === 'en' ? 'Lapa angļu valodā' : 'Site in Latvian'}
				>
					{translation => (
						<ToggleButton
							primary
							title={translation}
							onClick={onSwitchLanguage}
						>
							{switchLang.toUpperCase()}
						</ToggleButton>
					)}
				</Transl>
			)
		}}
	</TranslationsContext.Consumer>
)

export default LanguageSwitch
