import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { Button } from 'elements'
import { color, plainList } from 'core/theme'

export const StyledSwiper = styled.div`
  position: relative;
  margin: 1.5rem 0 0.5rem;
`

export const NavButton = styled(Button)`
  padding: 1rem;

  ${({ reverseIcon }) =>
    reverseIcon &&
    css`
      > span {
        transform: scaleX(-1);
      }
    `};
`

export const Slide = styled.figure`
  margin: 0;

  figcaption {
    margin-bottom: 0.2rem;
  }

  img {
    width: 100%;

    @supports (object-fit: cover) {
      object-fit: cover;
      height: 100%;
    }
  }

  > div {
    overflow: hidden;
  }
`

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NavIndicator = styled.ul`
  ${plainList};

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > li {
    display: block;
    margin: ${rem(4)};
  }
`

export const SlideDot = styled.span`
  display: block;
  width: ${rem(11)};
  height: ${rem(11)};
  border: 1px solid ${color.primary.default};
  border-radius: 50%;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${color.primary.default};
    `};
`
