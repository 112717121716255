import styled, { css } from 'styled-components'
import { color } from 'core/theme'

const printStyle = css`
  html {
    background: ${color.whiteOnly} !important;
    font-size: 9pt;
  }

  body {
    color: ${color.printBlack} !important;
    font-family: Verdana, Arial, Helvetica, sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-weight: bold;
  }

  a[href^='http'] {
    max-width: 100vw;

    i {
      margin-right: 0.25em;
    }

    &::after {
      color: ${color.printGrey};
      /* NOTE: IE11 svarīgi – double-quotes */
      /* prettier-ignore */
      content: " (" attr(href) ") ";
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: top;
      white-space: nowrap;
    }
  }

  li,
  img {
    break-inside: avoid;
    page-break-inside: avoid;
  }
`

export default printStyle

export const isPrintMedia = (...args) =>
  css`
    @media print {
      ${css(...args)};
    }
  `

export const notPrintMedia = (...args) =>
  css`
    @media not print {
      ${css(...args)};
    }
  `

export const HiddenInPrint = styled.div`
  ${isPrintMedia`
    display: none;
  `};
`

export const VisibleOnlyInPrint = styled.div`
  ${notPrintMedia`
    display: none;
  `};
`
