import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const observerDefaultOptions = {
	root: null,
	rootMargin: '128px',
	threshold: 0,
}

class IntersectionRoot extends React.Component {
	static propTypes = {
		render: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this._observablesMap = new Map()
		this._createObserver()
	}

	_createObserver = () => {
		const { options } = this.props
		const observerOptions = {
			...observerDefaultOptions,
			...options,
		}

		this._observer = new IntersectionObserver(
			this._onIntersectionChange,
			observerOptions
		)
		this._observer.USE_MUTATION_OBSERVER = false
		this._observer.POLL_INTERVAL = 100

		this._observerRenderProps = {
			observe: this._observe,
			unobserve: this._unobserve,
		}
	}

	_onIntersectionChange = (entries, observer) => {
		entries.forEach(entry => {
			const { isIntersecting, target } = entry
			const component = this._observablesMap.get(target)
			const state = component.state || {}
			if (state.isIntersecting !== isIntersecting) {
				component.setState(prevState => ({
					...prevState,
					isIntersecting,
				}))
			}
		})
	}

	_observe = component => {
		const node = ReactDOM.findDOMNode(component)
		this._observablesMap.set(node, component)
		if (this._observer) {
			this._observer.observe(node)
		}
	}

	_unobserve = component => {
		const node = ReactDOM.findDOMNode(component)
		this._observablesMap.delete(node)
		if (this._observer) {
			this._observer.unobserve(node)
		}
	}

	render() {
		const { render } = this.props
		return render(this._observerRenderProps)
	}
}

export default IntersectionRoot
