import React from 'react'
import { AspectRatio } from 'core/components'
import { toImageSrc } from 'utils/media'
import { ResponsiveImg } from 'elements'
import { StyledFeaturedMedia } from './styled'

const FeaturedMedia = ({ featuredMedia, children }) => {
	if (featuredMedia) {
		const featuredImageSrc = featuredMedia && toImageSrc(featuredMedia, 'large')
		return (
			<StyledFeaturedMedia>
				<AspectRatio>
					<ResponsiveImg
						alt={featuredMedia.altText}
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'cover',
						}}
						sizes={`66.66vw`}
						{...featuredImageSrc}
					/>
				</AspectRatio>
				{children}
			</StyledFeaturedMedia>
		)
	}
	return null
}

export default FeaturedMedia
