import 'utils/polyfills'
import 'focus-visible'

import React from 'react'
import ReactDOM from 'react-dom'
import gql from 'graphql-tag'
import {ApolloProvider, Query} from 'react-apollo'
import {BrowserRouter, StaticRouter} from 'react-router-dom'
import {ScrollToTop} from 'core/components'
import {SSRContext} from 'core/context'
import {Loader, ErrorMessage} from 'elements'
import {App} from 'modules'
import client from './apollo'
// import registerServiceWorker from './registerServiceWorker'
const ROOT_QUERY = gql`
  query RootQuery {
    root {
      name
      description
      siteUrl
      adminUrl
    }
    languages {
      slug
      rootUrl
      frontPageUrl
      pageOnFront
      pageForPosts
      isDefaultLang
    }
    menus {
      slug
      items {
        title
        url
        objectType
      }
    }
  }
`

const render = (Component, container) =>
  ReactDOM.render(
    <ApolloProvider client={client}>
      <Query query={ROOT_QUERY}>
        {({loading, error, data}) => {
          if (loading) return <Loader/>
          if (error) return <ErrorMessage message={error.message}/>

          const ssrHint = window.ssrHint
          const Router = ssrHint ? StaticRouter : BrowserRouter
          const routerProps = ssrHint
            ? {
              location: ssrHint.path,
              context: {},
            }
            : {}

          return (
            <Router {...routerProps}>
              <ScrollToTop>
                {props => {
                  const propsWithData = {
                    ...props,
                    ...data,
                  }
                  return (
                    <SSRContext.Provider value={{hint: ssrHint, routerProps}}>
                      <Component {...propsWithData} />
                    </SSRContext.Provider>
                  )
                }}
              </ScrollToTop>
            </Router>
          )
        }}
      </Query>
    </ApolloProvider>,
    container
  )

const rootEl = document.getElementById('root')
render(App, rootEl)
// registerServiceWorker() // Disable Service Worker for redirects
