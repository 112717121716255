import styled from 'styled-components'
import { rem, em } from 'polished'
import { color } from 'core/theme'
import { breakpoint } from 'core/layout'

export const StyledBanner = styled.section`
  background-color: ${color.border};
  position: relative;

  /* FIXME: Bez margin no augšējās navigācijas joslas; cits veids? */
  transform: translateY(-1.5rem);
  margin-bottom: -0.5rem;

  > img {
    width: 100%;
  }

  > h1 {
    color: ${color.onMedia};
    font-size: ${em(36)};
    font-weight: 600;
    margin: 0;
    text-align: center;
    text-shadow: 0 2px 5px 0 rgba(129, 129, 129, 0.5);
    width: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  @supports (object-fit: cover) {
    height: ${rem(140)};

    > img {
      height: 100%;
      object-fit: cover;
    }

    ${breakpoint.mainBreak`
      height: ${rem(190)};
    `};
  }
`
